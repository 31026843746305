@charset "UTF-8";
// [data-portal-id] used for Modal context
.thinkwell-content, [data-portal-id] {

  :root{
    --polaris-version-number:'4.7.1'; }

  *,
  *::before,
  *::after{
    box-sizing:border-box; }

  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner{
    border-style:none; }

  @keyframes polaris-SkeletonShimmerAnimation{
    0%{
      opacity:0.45; }
    100%{
      opacity:0.9; } }


  .Polaris-Avatar{
    position:relative;
    display:block;
    overflow:hidden;
    min-width:3.2em;
    max-width:100%;
    background:#47c1bf;
    border-radius:3em;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none; }
    .Polaris-Avatar::after{
      content:'';
      display:block;
      padding-bottom:100%; }

  .Polaris-Avatar--hidden{
    visibility:hidden; }

  .Polaris-Avatar--sizeSmall{
    width:3.2em; }

  .Polaris-Avatar--sizeMedium{
    width:4em; }

  .Polaris-Avatar--sizeLarge{
    width:6em; }

  .Polaris-Avatar--styleOne{
    background:#47c1bf; }

  .Polaris-Avatar--styleTwo{
    background:#de3618; }

  .Polaris-Avatar--styleThree{
    background:#f49342; }

  .Polaris-Avatar--styleFour{
    background:#50b83c; }

  .Polaris-Avatar--styleFive{
    background:#006fbb; }

  .Polaris-Avatar--styleSix{
    background:#9c6ade; }

  .Polaris-Avatar--hasImage{
    background:transparent; }

  .Polaris-Avatar__Image{
    position:absolute;
    top:50%;
    left:50%;
    width:100%;
    height:100%;
    border-radius:3em;
    transform:translate(-50%, -50%);
    object-fit:cover; }

  .Polaris-Avatar__Initials{
    position:absolute;
    top:0;
    right:0;
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:100%;
    padding:0.2em; }

  .Polaris-Avatar__Svg{
    width:4.8em;
    height:auto;
    color:white; }


  .Polaris-Icon{
    display:block;
    height:2em;
    width:2em;
    max-height:100%;
    max-width:100%;
    margin:auto; }

  .Polaris-Icon--hasBackdrop{
    position:relative;
    display:flex;
    align-items:center;
    margin:0.4em; }
    .Polaris-Icon--hasBackdrop::before{
      content:'';
      position:absolute;
      top:-0.4em;
      bottom:-0.4em;
      left:-0.4em;
      right:-0.4em;
      border-radius:50%; }

  .Polaris-Icon--isColored{
    color:white; }

  .Polaris-Icon--colorWhite{
    color:transparent; }
    .Polaris-Icon--colorWhite svg{
      fill:white; }
    .Polaris-Icon--colorWhite img{
      -webkit-filter:brightness(0) saturate(100%) invert(100%);
              filter:brightness(0) saturate(100%) invert(100%); }

  .Polaris-Icon--colorBlack svg{
    fill:black; }

  .Polaris-Icon--colorBlack img{
    -webkit-filter:brightness(0) saturate(100%);
            filter:brightness(0) saturate(100%); }

  .Polaris-Icon--colorSkyLighter svg{
    fill:#f9fafb; }

  .Polaris-Icon--colorSkyLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(159%) hue-rotate(170deg) brightness(99%) contrast(99%);
            filter:brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(159%) hue-rotate(170deg) brightness(99%) contrast(99%); }

  .Polaris-Icon--colorSkyLight svg{
    fill:#f4f6f8; }

  .Polaris-Icon--colorSkyLight img{
    -webkit-filter:brightness(0) saturate(100%) invert(99%) sepia(12%) saturate(467%) hue-rotate(174deg) brightness(99%) contrast(96%);
            filter:brightness(0) saturate(100%) invert(99%) sepia(12%) saturate(467%) hue-rotate(174deg) brightness(99%) contrast(96%); }

  .Polaris-Icon--colorSky svg{
    fill:#dfe3e8; }

  .Polaris-Icon--colorSky img{
    -webkit-filter:brightness(0) saturate(100%) invert(100%) sepia(95%) saturate(336%) hue-rotate(175deg) brightness(97%) contrast(87%);
            filter:brightness(0) saturate(100%) invert(100%) sepia(95%) saturate(336%) hue-rotate(175deg) brightness(97%) contrast(87%); }

  .Polaris-Icon--colorSkyDark svg{
    fill:#c4cdd5; }

  .Polaris-Icon--colorSkyDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(86%) sepia(4%) saturate(502%) hue-rotate(167deg) brightness(96%) contrast(91%);
            filter:brightness(0) saturate(100%) invert(86%) sepia(4%) saturate(502%) hue-rotate(167deg) brightness(96%) contrast(91%); }

  .Polaris-Icon--colorInkLightest svg{
    fill:#919eab; }

  .Polaris-Icon--colorInkLightest img{
    -webkit-filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%);
            filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%); }

  .Polaris-Icon--colorInkLighter svg{
    fill:#637381; }

  .Polaris-Icon--colorInkLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(8%) saturate(825%) hue-rotate(166deg) brightness(95%) contrast(90%);
            filter:brightness(0) saturate(100%) invert(45%) sepia(8%) saturate(825%) hue-rotate(166deg) brightness(95%) contrast(90%); }

  .Polaris-Icon--colorInkLighter::before{
    background-color:#dfe3e8; }

  .Polaris-Icon--colorInkLight svg{
    fill:#454f5b; }

  .Polaris-Icon--colorInkLight img{
    -webkit-filter:brightness(0) saturate(100%) invert(32%) sepia(9%) saturate(1069%) hue-rotate(173deg) brightness(83%) contrast(84%);
            filter:brightness(0) saturate(100%) invert(32%) sepia(9%) saturate(1069%) hue-rotate(173deg) brightness(83%) contrast(84%); }

  .Polaris-Icon--colorInk svg{
    fill:#212b36; }

  .Polaris-Icon--colorInk img{
    -webkit-filter:brightness(0) saturate(100%) invert(10%) sepia(10%) saturate(2259%) hue-rotate(171deg) brightness(99%) contrast(84%);
            filter:brightness(0) saturate(100%) invert(10%) sepia(10%) saturate(2259%) hue-rotate(171deg) brightness(99%) contrast(84%); }

  .Polaris-Icon--colorInk::before{
    background-color:#dfe3e8; }

  .Polaris-Icon--colorBlueLighter svg{
    fill:#ebf5fa; }

  .Polaris-Icon--colorBlueLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(100%) sepia(94%) saturate(686%) hue-rotate(175deg) brightness(103%) contrast(96%);
            filter:brightness(0) saturate(100%) invert(100%) sepia(94%) saturate(686%) hue-rotate(175deg) brightness(103%) contrast(96%); }

  .Polaris-Icon--colorBlueLight svg{
    fill:#b4e1fa; }

  .Polaris-Icon--colorBlueLight img{
    -webkit-filter:brightness(0) saturate(100%) invert(80%) sepia(7%) saturate(1832%) hue-rotate(178deg) brightness(108%) contrast(96%);
            filter:brightness(0) saturate(100%) invert(80%) sepia(7%) saturate(1832%) hue-rotate(178deg) brightness(108%) contrast(96%); }

  .Polaris-Icon--colorBlue svg{
    fill:#006fbb; }

  .Polaris-Icon--colorBlue img{
    -webkit-filter:brightness(0) saturate(100%) invert(32%) sepia(99%) saturate(1186%) hue-rotate(181deg) brightness(91%) contrast(103%);
            filter:brightness(0) saturate(100%) invert(32%) sepia(99%) saturate(1186%) hue-rotate(181deg) brightness(91%) contrast(103%); }

  .Polaris-Icon--colorBlueDark svg{
    fill:#084e8a; }

  .Polaris-Icon--colorBlueDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(22%) sepia(70%) saturate(1308%) hue-rotate(182deg) brightness(94%) contrast(101%);
            filter:brightness(0) saturate(100%) invert(22%) sepia(70%) saturate(1308%) hue-rotate(182deg) brightness(94%) contrast(101%); }

  .Polaris-Icon--colorBlueDark::before{
    background-color:#b4e1fa; }

  .Polaris-Icon--colorBlueDarker svg{
    fill:#001429; }

  .Polaris-Icon--colorBlueDarker img{
    -webkit-filter:brightness(0) saturate(100%) invert(5%) sepia(33%) saturate(5606%) hue-rotate(195deg) brightness(97%) contrast(102%);
            filter:brightness(0) saturate(100%) invert(5%) sepia(33%) saturate(5606%) hue-rotate(195deg) brightness(97%) contrast(102%); }

  .Polaris-Icon--colorIndigoLighter svg{
    fill:#f4f5fa; }

  .Polaris-Icon--colorIndigoLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(100%) sepia(25%) saturate(1090%) hue-rotate(179deg) brightness(100%) contrast(96%);
            filter:brightness(0) saturate(100%) invert(100%) sepia(25%) saturate(1090%) hue-rotate(179deg) brightness(100%) contrast(96%); }

  .Polaris-Icon--colorIndigoLight svg{
    fill:#b3bcf5; }

  .Polaris-Icon--colorIndigoLight img{
    -webkit-filter:brightness(0) saturate(100%) invert(82%) sepia(37%) saturate(4261%) hue-rotate(194deg) brightness(111%) contrast(92%);
            filter:brightness(0) saturate(100%) invert(82%) sepia(37%) saturate(4261%) hue-rotate(194deg) brightness(111%) contrast(92%); }

  .Polaris-Icon--colorIndigo svg{
    fill:#5c6ac4; }

  .Polaris-Icon--colorIndigo img{
    -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
            filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }

  .Polaris-Icon--colorIndigoDark svg{
    fill:#202e78; }

  .Polaris-Icon--colorIndigoDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(17%) sepia(28%) saturate(4409%) hue-rotate(218deg) brightness(87%) contrast(98%);
            filter:brightness(0) saturate(100%) invert(17%) sepia(28%) saturate(4409%) hue-rotate(218deg) brightness(87%) contrast(98%); }

  .Polaris-Icon--colorIndigoDarker svg{
    fill:#000639; }

  .Polaris-Icon--colorIndigoDarker img{
    -webkit-filter:brightness(0) saturate(100%) invert(5%) sepia(81%) saturate(5060%) hue-rotate(229deg) brightness(72%) contrast(111%);
            filter:brightness(0) saturate(100%) invert(5%) sepia(81%) saturate(5060%) hue-rotate(229deg) brightness(72%) contrast(111%); }

  .Polaris-Icon--colorTealLighter svg{
    fill:#e0f5f5; }

  .Polaris-Icon--colorTealLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(87%) sepia(5%) saturate(1124%) hue-rotate(173deg) brightness(114%) contrast(92%);
            filter:brightness(0) saturate(100%) invert(87%) sepia(5%) saturate(1124%) hue-rotate(173deg) brightness(114%) contrast(92%); }

  .Polaris-Icon--colorTealLight svg{
    fill:#b7ecec; }

  .Polaris-Icon--colorTealLight img{
    -webkit-filter:brightness(0) saturate(100%) invert(95%) sepia(12%) saturate(683%) hue-rotate(122deg) brightness(97%) contrast(91%);
            filter:brightness(0) saturate(100%) invert(95%) sepia(12%) saturate(683%) hue-rotate(122deg) brightness(97%) contrast(91%); }

  .Polaris-Icon--colorTeal svg{
    fill:#47c1bf; }

  .Polaris-Icon--colorTeal img{
    -webkit-filter:brightness(0) saturate(100%) invert(72%) sepia(8%) saturate(2838%) hue-rotate(130deg) brightness(92%) contrast(87%);
            filter:brightness(0) saturate(100%) invert(72%) sepia(8%) saturate(2838%) hue-rotate(130deg) brightness(92%) contrast(87%); }

  .Polaris-Icon--colorTeal::before{
    background-color:white; }

  .Polaris-Icon--colorTealDark svg{
    fill:#00848e; }

  .Polaris-Icon--colorTealDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(28%) sepia(83%) saturate(3919%) hue-rotate(168deg) brightness(93%) contrast(101%);
            filter:brightness(0) saturate(100%) invert(28%) sepia(83%) saturate(3919%) hue-rotate(168deg) brightness(93%) contrast(101%); }

  .Polaris-Icon--colorTealDark::before{
    background-color:#b7ecec; }

  .Polaris-Icon--colorTealDarker svg{
    fill:#003135; }

  .Polaris-Icon--colorTealDarker img{
    -webkit-filter:brightness(0) saturate(100%) invert(15%) sepia(23%) saturate(2237%) hue-rotate(141deg) brightness(96%) contrast(104%);
            filter:brightness(0) saturate(100%) invert(15%) sepia(23%) saturate(2237%) hue-rotate(141deg) brightness(96%) contrast(104%); }

  .Polaris-Icon--colorGreenLighter svg{
    fill:#e3f1df; }

  .Polaris-Icon--colorGreenLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(92%) sepia(51%) saturate(187%) hue-rotate(46deg) brightness(108%) contrast(89%);
            filter:brightness(0) saturate(100%) invert(92%) sepia(51%) saturate(187%) hue-rotate(46deg) brightness(108%) contrast(89%); }

  .Polaris-Icon--colorGreen svg{
    fill:#50b83c; }

  .Polaris-Icon--colorGreen img{
    -webkit-filter:brightness(0) saturate(100%) invert(56%) sepia(10%) saturate(2637%) hue-rotate(64deg) brightness(106%) contrast(91%);
            filter:brightness(0) saturate(100%) invert(56%) sepia(10%) saturate(2637%) hue-rotate(64deg) brightness(106%) contrast(91%); }

  .Polaris-Icon--colorGreen::before{
    background-color:#e3f1df; }

  .Polaris-Icon--colorGreenDark svg{
    fill:#108043; }

  .Polaris-Icon--colorGreenDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(18%) sepia(75%) saturate(6649%) hue-rotate(155deg) brightness(97%) contrast(87%);
            filter:brightness(0) saturate(100%) invert(18%) sepia(75%) saturate(6649%) hue-rotate(155deg) brightness(97%) contrast(87%); }

  .Polaris-Icon--colorGreenDark::before{
    background-color:#bbe5b3; }

  .Polaris-Icon--colorYellowLighter svg{
    fill:#fcf1cd; }

  .Polaris-Icon--colorYellowLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(88%) sepia(27%) saturate(234%) hue-rotate(357deg) brightness(103%) contrast(98%);
            filter:brightness(0) saturate(100%) invert(88%) sepia(27%) saturate(234%) hue-rotate(357deg) brightness(103%) contrast(98%); }

  .Polaris-Icon--colorYellow svg{
    fill:#eec200; }

  .Polaris-Icon--colorYellow img{
    -webkit-filter:brightness(0) saturate(100%) invert(65%) sepia(91%) saturate(530%) hue-rotate(5deg) brightness(100%) contrast(100%);
            filter:brightness(0) saturate(100%) invert(65%) sepia(91%) saturate(530%) hue-rotate(5deg) brightness(100%) contrast(100%); }

  .Polaris-Icon--colorYellowDark svg{
    fill:#8a6116; }

  .Polaris-Icon--colorYellowDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(37%) sepia(51%) saturate(709%) hue-rotate(0deg) brightness(93%) contrast(89%);
            filter:brightness(0) saturate(100%) invert(37%) sepia(51%) saturate(709%) hue-rotate(0deg) brightness(93%) contrast(89%); }

  .Polaris-Icon--colorYellowDark::before{
    background-color:#ffea8a; }

  .Polaris-Icon--colorOrange svg{
    fill:#f49342; }

  .Polaris-Icon--colorOrange img{
    -webkit-filter:brightness(0) saturate(100%) invert(54%) sepia(86%) saturate(416%) hue-rotate(340deg) brightness(105%) contrast(91%);
            filter:brightness(0) saturate(100%) invert(54%) sepia(86%) saturate(416%) hue-rotate(340deg) brightness(105%) contrast(91%); }

  .Polaris-Icon--colorOrangeDark svg{
    fill:#c05717; }

  .Polaris-Icon--colorOrangeDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(29%) sepia(94%) saturate(1431%) hue-rotate(5deg) brightness(96%) contrast(82%);
            filter:brightness(0) saturate(100%) invert(29%) sepia(94%) saturate(1431%) hue-rotate(5deg) brightness(96%) contrast(82%); }

  .Polaris-Icon--colorRedLighter svg{
    fill:#fbeae5; }

  .Polaris-Icon--colorRedLighter img{
    -webkit-filter:brightness(0) saturate(100%) invert(89%) sepia(21%) saturate(137%) hue-rotate(324deg) brightness(102%) contrast(97%);
            filter:brightness(0) saturate(100%) invert(89%) sepia(21%) saturate(137%) hue-rotate(324deg) brightness(102%) contrast(97%); }

  .Polaris-Icon--colorRed svg{
    fill:#de3618; }

  .Polaris-Icon--colorRed img{
    -webkit-filter:brightness(0) saturate(100%) invert(28%) sepia(67%) saturate(3622%) hue-rotate(353deg) brightness(89%) contrast(95%);
            filter:brightness(0) saturate(100%) invert(28%) sepia(67%) saturate(3622%) hue-rotate(353deg) brightness(89%) contrast(95%); }

  .Polaris-Icon--colorRedDark svg{
    fill:#bf0711; }

  .Polaris-Icon--colorRedDark img{
    -webkit-filter:brightness(0) saturate(100%) invert(12%) sepia(100%) saturate(5699%) hue-rotate(353deg) brightness(75%) contrast(101%);
            filter:brightness(0) saturate(100%) invert(12%) sepia(100%) saturate(5699%) hue-rotate(353deg) brightness(75%) contrast(101%); }

  .Polaris-Icon--colorRedDark::before{
    background-color:#fead9a; }

  .Polaris-Icon--colorPurple svg{
    fill:#9c6ade; }

  .Polaris-Icon--colorPurple img{
    -webkit-filter:brightness(0) saturate(100%) invert(49%) sepia(77%) saturate(1864%) hue-rotate(229deg) brightness(91%) contrast(91%);
            filter:brightness(0) saturate(100%) invert(49%) sepia(77%) saturate(1864%) hue-rotate(229deg) brightness(91%) contrast(91%); }

  .Polaris-Icon__Svg,
  .Polaris-Icon__Img{
    position:relative;
    display:block;
    width:100%;
    max-width:100%;
    max-height:100%; }

  .Polaris-Icon__Placeholder{
    padding-bottom:100%;
    background:currentColor; }


  .Polaris-VisuallyHidden{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }


  @keyframes Polaris-Spinner--loading{
    to{
      transform:rotate(360deg); } }

  .Polaris-Spinner{
    color:transparent; }

  .Polaris-Spinner--sizeSmall svg {
    animation: Polaris-Spinner--loading .5s linear infinite;
    height:1.4em;
    width:1.4em; }

  .Polaris-Spinner--sizeLarge svg {
    height:4.4em;
    width:4.4em; }

  .Polaris-Spinner--colorWhite{
    -webkit-filter:brightness(0) saturate(100%) invert(100%);
            filter:brightness(0) saturate(100%) invert(100%); }

  .Polaris-Spinner--colorTeal{
    -webkit-filter:brightness(0) saturate(100%) invert(72%) sepia(8%) saturate(2838%) hue-rotate(130deg) brightness(92%) contrast(87%);
            filter:brightness(0) saturate(100%) invert(72%) sepia(8%) saturate(2838%) hue-rotate(130deg) brightness(92%) contrast(87%); }

  .Polaris-Spinner--colorInkLightest{
    -webkit-filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%);
            filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%); }


  .Polaris-Button{
    position:relative;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    min-height:3.6em;
    min-width:3.6em;
    margin:0;
    padding:0.7em 1.6em;
    background:linear-gradient(to bottom, white, #f9fafb);
    border:0.1em solid #c4cdd5;
    box-shadow:0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    line-height:1;
    color:#212b36;
    text-align:center;
    cursor:pointer;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
    text-decoration:none;
    transition-property:background, border, box-shadow;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1);
    -webkit-tap-highlight-color:transparent; }
    .Polaris-Button svg{
      fill:#637381; }
    .Polaris-Button:hover{
      background:linear-gradient(to bottom, #f9fafb, #f4f6f8);
      border-color:#c4cdd5; }
    .Polaris-Button:focus{
      border-color:#5c6ac4;
      outline:0;
      box-shadow:0 0 0 1px #5c6ac4; }
      @media (-ms-high-contrast: active){
        .Polaris-Button:focus{
          outline:2px dotted; } }
    .Polaris-Button:active{
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      border-color:#c4cdd5;
      box-shadow:0 0 0 0 transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2); }
    .Polaris-Button.Polaris-Button--disabled{
      transition:none;
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      color:#919eab; }
      .Polaris-Button.Polaris-Button--disabled svg{
        fill:#919eab; }

  .Polaris-Button__Content{
    font-size:1.5em;
    font-weight:var(--p-button-font-weight, 400);
    line-height:1.6em;
    text-transform:initial;
    letter-spacing:initial;
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
    min-width:1px;
    min-height:1px; }
    @media (min-width: 40em){
      .Polaris-Button__Content{
        font-size:1.4em; } }

  .Polaris-Button--textAlignLeft{
    justify-content:flex-start;
    text-align:left; }

  .Polaris-Button--textAlignCenter{
    justify-content:center;
    text-align:center; }

  .Polaris-Button--textAlignRight{
    justify-content:flex-end;
    text-align:right; }

  .Polaris-Button__Icon{
    transition:color 200ms cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Button__Icon:first-child{
      margin-left:-0.4em; }
    .Polaris-Button__Icon:last-child{
      margin-right:-0.8em;
      margin-left:0.4em; }
    .Polaris-Button__Icon + *:not(.Polaris-Button__Icon){
      margin-left:0.4em; }

  .Polaris-Button__Spinner{
    position:absolute;
    top:50%;
    left:50%;
    margin-top:-0.7em;
    margin-left:-0.7em; }

  .Polaris-Button--primary{
    background:linear-gradient(to bottom, #6371c7, #5563c1);
    border-color:#3f4eae;
    box-shadow:inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
    color:white; }
    .Polaris-Button--primary:hover{
      background:linear-gradient(to bottom, #5c6ac4, #4959bd);
      border-color:#3f4eae;
      color:white;
      text-decoration:none; }
    .Polaris-Button--primary:focus{
      border-color:#202e78;
      box-shadow:inset 0 1px 0 0 #6f7bcb, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 1px #202e78; }
    .Polaris-Button--primary:active{
      background:linear-gradient(to bottom, #3f4eae, #3f4eae);
      border-color:#38469b;
      box-shadow:inset 0 0 0 0 transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 1px 0 #38469b; }
    .Polaris-Button--primary svg{
      fill:white; }
    .Polaris-Button--primary.Polaris-Button--disabled{
      background:linear-gradient(to bottom, #bac0e6, #bac0e6);
      border-color:#a7aedf;
      box-shadow:none;
      color:white; }
      .Polaris-Button--primary.Polaris-Button--disabled svg{
        fill:white; }
    .Polaris-Button--primary.Polaris-Button--pressed{
      background:linear-gradient(to bottom, #3f4eae, #3f4eae);
      border-color:#10173c;
      box-shadow:inset 0 0 0 0 transparent, inset 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 0 3px 0 #10173c; }
      .Polaris-Button--primary.Polaris-Button--pressed:focus, .Polaris-Button--primary.Polaris-Button--pressed:hover{
        transition-duration:100ms;
        background:linear-gradient(to bottom, #3c4ba7, #3c4ba7);
        border-color:#10173c;
        box-shadow:inset 0 0 0 0 transparent, inset 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 0 3px 0 #10173c; }
      .Polaris-Button--primary.Polaris-Button--pressed:active{
        background:linear-gradient(to bottom, #3f4eae, #3f4eae);
        border-color:#10173c;
        box-shadow:inset 0 0 0 0 transparent, inset 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 0 3px 0 #0b0f27; }

  .Polaris-Button--destructive{
    --p-button-color:var(--p-critical-action);
    --p-button-color-hover:var(--p-critical-action-hovered);
    --p-button-color-active:var(--p-critical-action-pressed);
    background:linear-gradient(to bottom, #e6391a, #d53417);
    border-color:#b02b13;
    box-shadow:inset 0 1px 0 0 #e73d1f, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
    color:white; }
    .Polaris-Button--destructive:hover{
      background:linear-gradient(to bottom, #de3618, #c73016);
      border-color:#b02b13;
      color:white;
      text-decoration:none; }
    .Polaris-Button--destructive:focus{
      border-color:#bf0711;
      box-shadow:inset 0 1px 0 0 #e84528, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 1px #bf0711; }
    .Polaris-Button--destructive:active{
      background:linear-gradient(to bottom, #b02b13, #b02b13);
      border-color:#992511;
      box-shadow:inset 0 0 0 0 transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 1px 0 #992511; }
    .Polaris-Button--destructive svg{
      fill:white; }
    .Polaris-Button--destructive.Polaris-Button--disabled{
      background:linear-gradient(to bottom, #f29484, #f29484);
      border-color:#ef816d;
      box-shadow:none;
      color:white; }
      .Polaris-Button--destructive.Polaris-Button--disabled svg{
        fill:white; }
    .Polaris-Button--destructive.Polaris-Button--pressed{
      background:linear-gradient(to bottom, #b02b13, #b02b13);
      border-color:#75040a;
      box-shadow:inset 0 0 0 0 transparent, inset 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 0 3px 0 #75040a; }
      .Polaris-Button--destructive.Polaris-Button--pressed:focus, .Polaris-Button--destructive.Polaris-Button--pressed:hover{
        transition-duration:100ms;
        background:linear-gradient(to bottom, #a72912, #a72912);
        border-color:#75040a;
        box-shadow:inset 0 0 0 0 transparent, inset 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 0 3px 0 #75040a; }
      .Polaris-Button--destructive.Polaris-Button--pressed:active{
        background:linear-gradient(to bottom, #b02b13, #b02b13);
        border-color:#75040a;
        box-shadow:inset 0 0 0 0 transparent, inset 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 0 3px 0 #5d0308; }

  .Polaris-Button--outline{
    background:transparent;
    border-color:rgba(99, 115, 129, 0.4);
    box-shadow:none;
    color:#374047; }
    .Polaris-Button--outline:hover{
      background:rgba(99, 115, 129, 0.05);
      border-color:rgba(99, 115, 129, 0.4); }
    .Polaris-Button--outline:focus{
      border-color:rgba(99, 115, 129, 0.8);
      box-shadow:0 0 0 1px rgba(99, 115, 129, 0.8); }
    .Polaris-Button--outline:active{
      background:rgba(99, 115, 129, 0.1);
      box-shadow:none; }
    .Polaris-Button--outline.Polaris-Button--disabled{
      background:transparent;
      border-color:rgba(99, 115, 129, 0.25);
      box-shadow:none;
      color:#919eab; }
      .Polaris-Button--outline.Polaris-Button--disabled svg{
        fill:#919eab; }

  .Polaris-Button--destructive.Polaris-Button--outline{
    background:transparent;
    border-color:rgba(222, 54, 24, 0.4);
    box-shadow:none;
    color:#82200e; }
    .Polaris-Button--destructive.Polaris-Button--outline:hover{
      background:rgba(222, 54, 24, 0.05);
      border-color:rgba(222, 54, 24, 0.4); }
    .Polaris-Button--destructive.Polaris-Button--outline:focus{
      border-color:rgba(222, 54, 24, 0.8);
      box-shadow:0 0 0 1px rgba(222, 54, 24, 0.8); }
    .Polaris-Button--destructive.Polaris-Button--outline:active{
      background:rgba(222, 54, 24, 0.1);
      box-shadow:none; }
    .Polaris-Button--destructive.Polaris-Button--outline svg{
      fill:#bf0711; }
    .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed{
      background:rgba(191, 7, 17, 0.03);
      border-color:rgba(191, 7, 17, 0.4);
      box-shadow:none;
      color:#5d0308; }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed:hover{
        background:rgba(191, 7, 17, 0.05);
        border-color:rgba(191, 7, 17, 0.4); }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed:focus{
        border-color:rgba(191, 7, 17, 0.8);
        box-shadow:0 0 0 1px rgba(191, 7, 17, 0.8); }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed:active{
        background:rgba(191, 7, 17, 0.1);
        box-shadow:none; }
      .Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed svg{
        fill:#330101; }

  .Polaris-Button--disabled{
    cursor:default;
    pointer-events:none; }

  @keyframes Polaris-Button--loading{
    to{
      transform:rotate(360deg); } }

  .Polaris-Button--loading{
    position:relative;
    transition:border-color 200ms cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Button--loading, .Polaris-Button--loading:hover, .Polaris-Button--loading.Polaris-Button--disabled{
      color:transparent; }

  .Polaris-Button--pressed{
    background:#dfe3e8;
    border-color:#b8c3cd;
    box-shadow:0 0 0 1px transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.1); }
    .Polaris-Button--pressed:hover{
      transition-duration:100ms;
      background:#d9dee4;
      border-color:#b8c3cd;
      box-shadow:0 0 0 1px transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.1); }
    .Polaris-Button--pressed:focus{
      border-color:#5c6ac4;
      box-shadow:0 0 0 1px #5c6ac4, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.1); }
    .Polaris-Button--pressed:active{
      background:#d3d9df;
      border-color:#b8c3cd;
      box-shadow:0 0 0 1px transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.1); }
    @media (-ms-high-contrast: active){
      .Polaris-Button--pressed{
        color:buttonText;
        background:buttonFace; } }

  .Polaris-Button--plain{
    margin:-0.7em -0.8em;
    padding-left:0.8em;
    padding-right:0.8em;
    background:transparent;
    border:0;
    box-shadow:none;
    color:var(--p-interactive-action, #006fbb); }
    .Polaris-Button--plain svg{
      fill:var(--p-interactive-action, #006fbb); }
    .Polaris-Button--plain .Polaris-Button__Content{
      font-weight:400; }
    .Polaris-Button--plain.Polaris-Button--pressed, .Polaris-Button--plain:hover, .Polaris-Button--plain:focus, .Polaris-Button--plain:active{
      background:transparent;
      border:0;
      box-shadow:none; }
    .Polaris-Button--plain.Polaris-Button--pressed{
      color:#084e8a;
      text-decoration:underline; }
      .Polaris-Button--plain.Polaris-Button--pressed svg{
        fill:#084e8a; }
    .Polaris-Button--plain:hover{
      color:var(--p-interactive-action-hovered, #084e8a);
      text-decoration:underline; }
      .Polaris-Button--plain:hover svg{
        fill:var(--p-interactive-action-hovered, #084e8a); }
    .Polaris-Button--plain:focus{
      color:var(--p-interactive-action, #084e8a);
      -webkit-text-decoration:var(--p-override-none, underline);
              text-decoration:var(--p-override-none, underline); }
      .Polaris-Button--plain:focus svg{
        fill:var(--p-interactive-action, #084e8a); }
      .Polaris-Button--plain:focus::after{
        border:none; }
    .Polaris-Button--plain:active{
      color:var(--p-interactive-action-pressed, #084e8a);
      -webkit-text-decoration:var(--p-override-none, underline);
              text-decoration:var(--p-override-none, underline); }
      .Polaris-Button--plain:active svg{
        fill:var(--p-interactive-action-pressed, #084e8a); }
    @media (-ms-high-contrast: active){
      .Polaris-Button--plain.Polaris-Button--pressed, .Polaris-Button--plain:focus{
        outline:none; } }
    @media (-ms-high-contrast: active){
      .Polaris-Button--plain:focus > .Polaris-Button__Content{
        outline:2px dotted; } }
    .Polaris-Button--plain:focus:not(:active) > .Polaris-Button__Content{
      position:relative; }
      .Polaris-Button--plain:focus:not(:active) > .Polaris-Button__Content::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.3em;
        right:-0.3em;
        bottom:-0.3em;
        left:-0.3em;
        display:block;
        border:0.2em solid var(--p-interactive-focus);
        border-radius:calc(var(--p-border-radius-base) + 0.3em);
        pointer-events:none; }
    .Polaris-Button--plain.Polaris-Button--pressed > .Polaris-Button__Content{
      padding:2px 5px;
      margin:-2px -5px;
      background:rgba(99, 115, 129, 0.1);
      border-radius:3px;
      transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Button--plain.Polaris-Button--pressed:hover:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content,
    .Polaris-Button--plain.Polaris-Button--pressed:active:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content{
      padding:2px 5px;
      margin:-2px -5px;
      background:rgba(33, 43, 54, 0.1);
      border-radius:3px;
      transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Button--plain:focus:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content{
      padding:2px 5px;
      margin:-2px -5px;
      background:var(--p-override-none, rgba(33, 43, 54, 0.1));
      border-radius:3px;
      transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Button--plain:active:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content{
      padding:2px 5px;
      margin:-2px -5px;
      background:var(--p-neutral-action, none);
      border-radius:3px;
      transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Button--plain.Polaris-Button--fullWidth{
      margin-left:0;
      margin-right:0; }
    .Polaris-Button--plain.Polaris-Button--destructive{
      color:#bf0711; }
      .Polaris-Button--plain.Polaris-Button--destructive:hover{
        color:#330101; }
    .Polaris-Button--plain.Polaris-Button--disabled{
      background:none; }
    .Polaris-Button--plain.Polaris-Button--sizeSlim{
      margin-top:-0.4em;
      margin-bottom:-0.4em; }
    .Polaris-Button--plain.Polaris-Button--sizeLarge{
      margin:-1.1em -2em; }
    .Polaris-Button--plain.Polaris-Button--iconOnly{
      margin:-0.8em; }
      .Polaris-Button--plain.Polaris-Button--iconOnly svg{
        fill:#637381; }
      .Polaris-Button--plain.Polaris-Button--iconOnly:focus, .Polaris-Button--plain.Polaris-Button--iconOnly:active{
        background:rgba(33, 43, 54, 0.1); }
        .Polaris-Button--plain.Polaris-Button--iconOnly:focus svg, .Polaris-Button--plain.Polaris-Button--iconOnly:active svg{
          fill:#637381; }
      .Polaris-Button--plain.Polaris-Button--iconOnly:hover svg, .Polaris-Button--plain.Polaris-Button--iconOnly:active svg{
        fill:#212b36; }
      .Polaris-Button--plain.Polaris-Button--iconOnly > .Polaris-Button__Content::after{
        display:none; }
      .Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--disabled svg{
        fill:#c4cdd5; }
    .Polaris-Button--plain .Polaris-Button__Icon{
      margin-left:0;
      margin-right:0; }

  .Polaris-Button--sizeSlim{
    min-height:3em;
    padding:0.4em 1.2em; }

  .Polaris-Button--sizeLarge{
    min-height:4.4em;
    min-width:4.4em;
    padding:1.1em 2.4em; }
    .Polaris-Button--sizeLarge .Polaris-Button__Content{
      font-size:1.7em;
      font-weight:var(--p-button-font-weight, 400);
      line-height:2em;
      text-transform:initial;
      letter-spacing:initial; }
      @media (min-width: 40em){
        .Polaris-Button--sizeLarge .Polaris-Button__Content{
          font-size:1.6em; } }

  .Polaris-Button--fullWidth{
    display:flex;
    width:100%; }

  .Polaris-Button--iconOnly{
    padding-left:0.8em;
    padding-right:0.8em; }
    .Polaris-Button--iconOnly.Polaris-Button--sizeLarge{
      padding-left:1.2em;
      padding-right:1.2em; }
    .Polaris-Button--iconOnly .Polaris-Button__Icon:first-child{
      margin-left:0; }
    .Polaris-Button--iconOnly .Polaris-Button__Icon:last-child{
      margin-right:-0.4em; }
    .Polaris-Button--iconOnly .Polaris-Button__Icon:only-child{
      margin-right:0; }

  .Polaris-Button--monochrome.Polaris-Button--outline, .Polaris-Button--monochrome.Polaris-Button--plain{
    color:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline svg, .Polaris-Button--monochrome.Polaris-Button--plain svg{
      fill:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline:hover, .Polaris-Button--monochrome.Polaris-Button--outline:focus, .Polaris-Button--monochrome.Polaris-Button--outline:active, .Polaris-Button--monochrome.Polaris-Button--plain:hover, .Polaris-Button--monochrome.Polaris-Button--plain:focus, .Polaris-Button--monochrome.Polaris-Button--plain:active{
      color:currentColor; }
      .Polaris-Button--monochrome.Polaris-Button--outline:hover svg, .Polaris-Button--monochrome.Polaris-Button--outline:focus svg, .Polaris-Button--monochrome.Polaris-Button--outline:active svg, .Polaris-Button--monochrome.Polaris-Button--plain:hover svg, .Polaris-Button--monochrome.Polaris-Button--plain:focus svg, .Polaris-Button--monochrome.Polaris-Button--plain:active svg{
        fill:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--disabled, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--disabled{
      color:currentColor;
      opacity:0.4; }
      .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--disabled svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--disabled svg{
        fill:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly svg{
      fill:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly:focus svg, .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly:active svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly:focus svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly:active svg{
      fill:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly.Polaris-Button--disabled svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--disabled svg{
      fill:currentColor; }

  .Polaris-Button--monochrome.Polaris-Button--plain .Polaris-Button__Text{
    text-decoration:underline; }

  .Polaris-Button--monochrome.Polaris-Button--outline{
    position:relative;
    border-color:currentColor; }
    .Polaris-Button--monochrome.Polaris-Button--outline::before{
      content:'';
      transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1);
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      background-color:currentColor;
      opacity:0;
      z-index:0; }
    .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--pressed{
      background:transparent;
      border-color:currentColor;
      box-shadow:none; }
      .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--pressed::before{
        opacity:0.05; }
    .Polaris-Button--monochrome.Polaris-Button--outline:hover, .Polaris-Button--monochrome.Polaris-Button--outline:focus, .Polaris-Button--monochrome.Polaris-Button--outline:active{
      background-color:transparent;
      border-color:currentColor; }
      .Polaris-Button--monochrome.Polaris-Button--outline:hover::before, .Polaris-Button--monochrome.Polaris-Button--outline:focus::before, .Polaris-Button--monochrome.Polaris-Button--outline:active::before{
        opacity:0.07; }


  .Polaris-ButtonGroup{
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    margin-top:-0.8em;
    margin-left:-0.8em; }

  .Polaris-ButtonGroup__Item{
    margin-top:0.8em;
    margin-left:0.8em; }

  .Polaris-ButtonGroup__Item--plain:not(:first-child){
    margin-left:1.6em; }

  .Polaris-ButtonGroup__Item--plain:not(:last-child){
    margin-right:0.8em; }

  .Polaris-ButtonGroup--segmented{
    display:flex;
    flex-wrap:nowrap;
    margin-top:0;
    margin-left:0; }
    .Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item{
      position:relative;
      z-index:10;
      margin-top:0;
      margin-left:0; }
      .Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item:not(:first-child){
        margin-left:-0.1em; }
      .Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item > *{
        border-radius:0; }
      .Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item:first-child > *{
        border-top-left-radius:3px;
        border-bottom-left-radius:3px; }
      .Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item:last-child > *{
        border-top-right-radius:3px;
        border-bottom-right-radius:3px; }
    .Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item--focused{
      z-index:20; }

  .Polaris-ButtonGroup--fullWidth .Polaris-ButtonGroup__Item{
    flex:1 1 auto; }
    .Polaris-ButtonGroup--fullWidth .Polaris-ButtonGroup__Item > *{
      display:flex;
      width:100%; }

  .Polaris-ButtonGroup--connectedTop .Polaris-ButtonGroup__Item:first-child > *{
    border-top-left-radius:0; }

  .Polaris-ButtonGroup--connectedTop .Polaris-ButtonGroup__Item:last-child > *{
    border-top-right-radius:0; }


  .Polaris-Scrollable{
    -webkit-overflow-scrolling:touch;
    position:relative; }

  .Polaris-Scrollable--horizontal{
    overflow-x:auto; }

  .Polaris-Scrollable--vertical{
    overflow-y:auto; }

  .Polaris-Scrollable--hasTopShadow{
    box-shadow:inset 0 2em 2em -2em rgba(0, 0, 0, 0.1); }

  .Polaris-Scrollable--hasBottomShadow{
    box-shadow:inset 0 -2em 2em -2em rgba(0, 0, 0, 0.1); }

  .Polaris-Scrollable--hasTopShadow.Polaris-Scrollable--hasBottomShadow{
    box-shadow:inset 0 2em 2em -2em rgba(0, 0, 0, 0.1), inset 0 -2em 2em -2em rgba(0, 0, 0, 0.1); }


  .Polaris-Badge{
    display:inline-flex;
    align-items:center;
    padding:0 0.8em;
    background-color:var(--p-neutral, #dfe3e8);
    border:0.2em solid var(--p-surface-foreground, white);
    border-radius:2em;
    font-size:1.3em;
    line-height:2em;
    color:var(--p-text-on-surface, #454f5b); }
    .Polaris-Badge .Polaris-Badge__Pip{
      color:var(--p-icon-on-surface, #919eab); }

  .Polaris-Badge--sizeSmall{
    font-size:1.2em;
    line-height:1.8em; }

  .Polaris-Badge--statusSuccess{
    background-color:var(--p-success-surface, #bbe5b3);
    color:#414f3e; }
    .Polaris-Badge--statusSuccess .Polaris-Badge__Pip{
      color:var(--p-success-icon, #108043); }
    .Polaris-Badge--statusSuccess .Polaris-Badge__Content{
      color:var(--p-text-on-surface);
      mix-blend-mode:var(--p-luminosity); }

  .Polaris-Badge--statusInfo{
    background-color:var(--p-highlight-surface, #b4e1fa);
    color:#3e4e57; }
    .Polaris-Badge--statusInfo .Polaris-Badge__Pip{
      color:var(--p-highlight-icon, #084e8a); }
    .Polaris-Badge--statusInfo .Polaris-Badge__Content{
      color:var(--p-text-on-surface);
      mix-blend-mode:var(--p-luminosity); }

  .Polaris-Badge--statusAttention{
    background-color:#ffea8a;
    color:#595130; }
    .Polaris-Badge--statusAttention .Polaris-Badge__Pip{
      color:#8a6116; }

  .Polaris-Badge--statusWarning{
    background-color:var(--p-warning-surface, #ffc58b);
    color:#594430; }
    .Polaris-Badge--statusWarning .Polaris-Badge__Pip{
      color:var(--p-warning-icon, #c05717); }
    .Polaris-Badge--statusWarning .Polaris-Badge__Content{
      color:var(--p-text-on-surface);
      mix-blend-mode:var(--p-luminosity); }

  .Polaris-Badge--statusNew{
    background-color:#dfe3e8;
    color:#212b36;
    font-weight:500;
    border:none; }

  .Polaris-Badge__Pip{
    height:1em;
    width:1em;
    margin:0 0.4em 0 -0.3em;
    border:0.2em solid currentColor;
    border-radius:50%; }

  .Polaris-Badge--progressIncomplete .Polaris-Badge__Pip{
    background:transparent; }

  .Polaris-Badge--progressPartiallyComplete .Polaris-Badge__Pip{
    background:linear-gradient(to top, currentColor, currentColor 50%, transparent 50%, transparent); }

  .Polaris-Badge--progressComplete .Polaris-Badge__Pip{
    background:linear-gradient(to top, currentColor, currentColor 50%, currentColor 50%); }


  .Polaris-TextStyle--variationPositive{
    color:#108043; }

  .Polaris-TextStyle--variationNegative{
    color:#bf0711; }

  .Polaris-TextStyle--variationCode{
    position:relative;
    padding:0 0.4em;
    border-radius:3px;
    background-color:#f4f6f8;
    display:inline-block;
    font-size:1.15em;
    box-shadow:inset 0 0 0 1px #dfe3e8; }
    .Polaris-TextStyle--variationCode::after{
      content:'';
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      border:1px solid transparent; }

  .Polaris-TextStyle--variationStrong{
    font-weight:600; }

  .Polaris-TextStyle--variationSubdued{
    color:#637381; }


  .Polaris-ActionList{
    list-style:none;
    margin:0;
    padding:0.8em 0; }

  .Polaris-ActionList__Section--withoutTitle:not(:first-child){
    border-top:0.1em solid #dfe3e8;
    margin-top:0.8em;
    padding-top:0.8em; }

  .Polaris-ActionList__Actions{
    list-style:none;
    margin:0;
    padding:0;
    border-top:0.1em solid #dfe3e8; }

  .Polaris-ActionList > .Polaris-ActionList__Section--withoutTitle .Polaris-ActionList__Actions,
  .Polaris-ActionList__Section:first-child > .Polaris-ActionList__Section--withoutTitle .Polaris-ActionList__Actions{
    border-top:none; }

  .Polaris-ActionList__Title{
    font-size:1.3em;
    font-weight:600;
    line-height:1.6em;
    text-transform:uppercase;
    padding:1.2em 1.6em; }
    @media (min-width: 40em){
      .Polaris-ActionList__Title{
        font-size:1.2em; } }

  .Polaris-ActionList__Item{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    color:inherit;
    text-decoration:none;
    display:block;
    width:100%;
    min-height:4em;
    padding:1em 1.6em;
    text-align:left;
    cursor:pointer;
    border-radius:0; }
    .Polaris-ActionList__Item:focus{
      outline:none; }
    .Polaris-ActionList__Item:visited{
      color:inherit; }
    .Polaris-ActionList__Item.Polaris-ActionList--active{
      background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }
    .Polaris-ActionList__Item:active{
      background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }
      .Polaris-ActionList__Item:active:hover{
        background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
        .Polaris-ActionList__Item:active:hover:focus{
          box-shadow:inset 0.2em 0 0 #5c6ac4;
          background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ActionList__Item:hover{
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
      .Polaris-ActionList__Item:hover:focus{
        box-shadow:inset 0.2em 0 0 #5c6ac4;
        background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ActionList__Item:focus{
      box-shadow:inset 0.2em 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ActionList__Item.Polaris-ActionList--destructive{
      color:#bf0711; }
      .Polaris-ActionList__Item.Polaris-ActionList--destructive svg{
        fill:#bf0711; }
      .Polaris-ActionList__Item.Polaris-ActionList--destructive:active{
        background-image:linear-gradient(rgba(220, 56, 37, 0.03), rgba(220, 56, 37, 0.03)); }
        .Polaris-ActionList__Item.Polaris-ActionList--destructive:active:hover{
          background-image:linear-gradient(rgba(220, 56, 37, 0.03), rgba(220, 56, 37, 0.03)), linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)); }
          .Polaris-ActionList__Item.Polaris-ActionList--destructive:active:hover:focus{
            box-shadow:inset 0.2em 0 0 #de3618;
            background-image:linear-gradient(rgba(220, 56, 37, 0.03), rgba(220, 56, 37, 0.03)), linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)), linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)); }
      .Polaris-ActionList__Item.Polaris-ActionList--destructive:hover{
        background-image:linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)); }
        .Polaris-ActionList__Item.Polaris-ActionList--destructive:hover:focus{
          box-shadow:inset 0.2em 0 0 #de3618;
          background-image:linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)), linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)); }
      .Polaris-ActionList__Item.Polaris-ActionList--destructive:focus{
        box-shadow:inset 0.2em 0 0 #de3618;
        background-image:linear-gradient(rgba(251, 234, 229, 0.4), rgba(251, 234, 229, 0.4)); }
    .Polaris-ActionList__Item.Polaris-ActionList--disabled{
      background-image:linear-gradient(#f9fafb, #f9fafb);
      color:#919eab;
      pointer-events:none; }
      .Polaris-ActionList__Item.Polaris-ActionList--disabled .Polaris-ActionList__Image svg{
        fill:#919eab;
        color:white; }
    .Polaris-ActionList__Item::-moz-focus-inner{
      border:none; }

  .Polaris-ActionList__Content{
    display:flex;
    align-items:center; }

  .Polaris-ActionList__Image{
    display:flex;
    flex:0 0 auto;
    justify-content:center;
    align-items:center;
    height:2em;
    width:2em;
    border-radius:3px;
    margin:-1em 1.6em -1em 0;
    background-size:cover;
    background-position:center center; }
    .Polaris-ActionList__Image svg{
      fill:#454f5b;
      color:white; }

  .Polaris-ActionList__Text{
    min-width:0;
    max-width:100%;
    flex:1 1 auto; }

  .Polaris-ActionList__BadgeWrapper{
    margin-left:1.6em; }


  .Polaris-Popover{
    max-width:calc(100vw - 3.2em);
    margin:0.5em 0.8em 1.6em;
    box-shadow:0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08);
    border-radius:3px;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:left, top;
    width:100%; }

  .Polaris-Popover__PopoverOverlay{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity;
    opacity:0;
    transition:opacity 200ms cubic-bezier(0.36, 0, 1, 1); }

  .Polaris-Popover__PopoverOverlay--entering{
    opacity:1; }

  .Polaris-Popover__PopoverOverlay--open{
    opacity:1; }

  .Polaris-Popover__PopoverOverlay--exiting{
    opacity:0;
    transition-timing-function:cubic-bezier(0, 0, 0.42, 1); }

  .Polaris-Popover--measuring:not(.Polaris-Popover__PopoverOverlay--exiting){
    opacity:0; }

  .Polaris-Popover--fullWidth{
    margin:0;
    margin-top:0.5em; }
    .Polaris-Popover--fullWidth .Polaris-Popover__Content{
      max-width:none; }

  .Polaris-Popover--measuring .Polaris-Popover__Content{
    display:block; }

  .Polaris-Popover--positionedAbove{
    margin:1.6em 0.8em 0.5em; }
    .Polaris-Popover--positionedAbove.Polaris-Popover--fullWidth{
      margin:0 0 0.5em; }

  .Polaris-Popover__Wrapper{
    position:relative;
    overflow:hidden;
    background-color:var(--p-surface-foreground, white);
    border-radius:3px; }
    @media (-ms-high-contrast: active){
      .Polaris-Popover__Wrapper{
        box-shadow:inset 0 0 0 0.1em windowText; } }

  .Polaris-Popover__Content{
    position:relative;
    display:flex;
    flex-direction:column;
    border-radius:3px;
    max-width:40em;
    max-height:29.5em; }
    .Polaris-Popover__Content:focus{
      outline:none; }

  .Polaris-Popover__Content--fullHeight{
    max-height:none; }

  .Polaris-Popover__Pane{
    flex:1 1;
    max-width:100%; }
    .Polaris-Popover__Pane + .Polaris-Popover__Pane{
      border-top:0.1em solid #dfe3e8; }

  .Polaris-Popover__Pane--fixed{
    overflow:visible;
    flex:0 0 auto; }

  .Polaris-Popover__Section{
    padding:1.6em; }
    .Polaris-Popover__Section + .Polaris-Popover__Section{
      border-top:0.1em solid #dfe3e8; }

  .Polaris-Popover__FocusTracker{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }


  .Polaris-PositionedOverlay{
    position:absolute;
    z-index:400; }

  .Polaris-PositionedOverlay--fixed{
    position:fixed; }

  .Polaris-PositionedOverlay--calculating{
    visibility:hidden; }


  .Polaris-Stack{
    margin-top:-1.6em;
    margin-left:-1.6em;
    display:flex;
    flex-wrap:wrap;
    align-items:stretch; }
    .Polaris-Stack > .Polaris-Stack__Item{
      margin-top:1.6em;
      margin-left:1.6em;
      max-width:100%; }

  .Polaris-Stack--noWrap{
    flex-wrap:nowrap; }

  .Polaris-Stack--spacingNone{
    margin-top:0;
    margin-left:0; }
    .Polaris-Stack--spacingNone > .Polaris-Stack__Item{
      margin-top:0;
      margin-left:0;
      max-width:100%; }

  .Polaris-Stack--spacingExtraTight{
    margin-top:-0.4em;
    margin-left:-0.4em; }
    .Polaris-Stack--spacingExtraTight > .Polaris-Stack__Item{
      margin-top:0.4em;
      margin-left:0.4em;
      max-width:100%; }

  .Polaris-Stack--spacingTight{
    margin-top:-0.8em;
    margin-left:-0.8em; }
    .Polaris-Stack--spacingTight > .Polaris-Stack__Item{
      margin-top:0.8em;
      margin-left:0.8em;
      max-width:100%; }

  .Polaris-Stack--spacingLoose{
    margin-top:-2em;
    margin-left:-2em; }
    .Polaris-Stack--spacingLoose > .Polaris-Stack__Item{
      margin-top:2em;
      margin-left:2em;
      max-width:100%; }

  .Polaris-Stack--spacingExtraLoose{
    margin-top:-3.2em;
    margin-left:-3.2em; }
    .Polaris-Stack--spacingExtraLoose > .Polaris-Stack__Item{
      margin-top:3.2em;
      margin-left:3.2em;
      max-width:100%; }

  .Polaris-Stack--distributionLeading{
    justify-content:flex-start; }

  .Polaris-Stack--distributionTrailing{
    justify-content:flex-end; }

  .Polaris-Stack--distributionCenter{
    justify-content:center; }

  .Polaris-Stack--distributionEqualSpacing{
    justify-content:space-between; }

  .Polaris-Stack--distributionFill > .Polaris-Stack__Item{
    flex:1 1 auto; }

  .Polaris-Stack--distributionFillEvenly > .Polaris-Stack__Item{
    flex:1 1 auto; }
    @supports ((min-width: -webkit-fit-content) or (min-width: fit-content)){
      .Polaris-Stack--distributionFillEvenly > .Polaris-Stack__Item{
        flex:1 0;
        min-width:-webkit-fit-content;
        min-width:fit-content; } }

  .Polaris-Stack--alignmentLeading{
    align-items:flex-start; }

  .Polaris-Stack--alignmentTrailing{
    align-items:flex-end; }

  .Polaris-Stack--alignmentCenter{
    align-items:center; }

  .Polaris-Stack--alignmentFill{
    align-items:stretch; }

  .Polaris-Stack--alignmentBaseline{
    align-items:baseline; }

  .Polaris-Stack--vertical{
    flex-direction:column;
    margin-left:0; }
    .Polaris-Stack--vertical > .Polaris-Stack__Item{
      margin-left:0; }

  .Polaris-Stack__Item{
    flex:0 0 auto;
    min-width:0; }

  .Polaris-Stack__Item--fill{
    flex:1 1 auto; }


  .Polaris-Heading{
    font-size:1.7em;
    font-weight:600;
    line-height:2.4em;
    margin:0; }
    @media (min-width: 40em){
      .Polaris-Heading{
        font-size:1.6em; } }


  .Polaris-Card{
    overflow:hidden;
    background-color:var(--p-surface-foreground, white);
    box-shadow:0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15); }
    .Polaris-Card + .Polaris-Card{
      margin-top:2em; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Card{
        border-radius:3px; } }
    @media (min-width: 30.625em){
      .Polaris-Card{
        border-radius:3px; } }
    @media (-ms-high-contrast: active){
      .Polaris-Card{
        box-shadow:inset 0 0 0 0.1em windowText; } }

  .Polaris-Card--subdued{
    background-color:var(--p-surface-foreground-subdued, #f9fafb); }

  .Polaris-Card__Header{
    padding:1.6em 1.6em 0; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Card__Header{
        padding:2em 2em 0; } }
    @media (min-width: 30.625em){
      .Polaris-Card__Header{
        padding:2em 2em 0; } }

  .Polaris-Card__Section{
    padding:1.6em; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Card__Section{
        padding:2em; } }
    @media (min-width: 30.625em){
      .Polaris-Card__Section{
        padding:2em; } }
    .Polaris-Card__Section + .Polaris-Card__Section{
      border-top:0.1em solid #dfe3e8; }

  .Polaris-Card__Section--fullWidth{
    padding:1.6em 0; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Card__Section--fullWidth{
        padding:2em 0; } }
    @media (min-width: 30.625em){
      .Polaris-Card__Section--fullWidth{
        padding:2em 0; } }

  .Polaris-Card__Section--subdued{
    background-color:var(--p-surface-foreground-subdued, #f9fafb); }
    @media (-ms-high-contrast: active){
      .Polaris-Card__Section--subdued{
        background-color:transparent; } }
    .Polaris-Card__Header + .Polaris-Card__Section--subdued{
      border-top:0.1em solid #dfe3e8;
      margin-top:2em; }

  .Polaris-Card__SectionHeader{
    padding-bottom:0.8em; }
    .Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader{
      padding-left:1.6em;
      padding-right:1.6em; }
      @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
        [data-has-navigation] .Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader{
          padding-left:2em;
          padding-right:2em; } }
      @media (min-width: 30.625em){
        .Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader{
          padding-left:2em;
          padding-right:2em; } }

  .Polaris-Card__Subsection + .Polaris-Card__Subsection{
    margin-top:1.6em;
    padding-top:1.6em;
    border-top:0.1em solid #dfe3e8; }

  .Polaris-Card__Footer{
    display:flex;
    justify-content:flex-start;
    padding:0 1.6em 1.6em; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Card__Footer{
        padding:0 2em 2em; } }
    @media (min-width: 30.625em){
      .Polaris-Card__Footer{
        padding:0 2em 2em; } }
    .Polaris-Card__Section--subdued + .Polaris-Card__Footer{
      border-top:0.1em solid #dfe3e8;
      padding:2em; }


  .Polaris-Subheading{
    font-size:1.3em;
    font-weight:600;
    line-height:1.6em;
    text-transform:uppercase;
    margin:0; }
    @media (min-width: 40em){
      .Polaris-Subheading{
        font-size:1.2em; } }


  .Polaris-SettingAction{
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    margin-top:-1.6em;
    margin-left:-1.6em; }

  .Polaris-SettingAction__Setting,
  .Polaris-SettingAction__Action{
    flex:0 0 auto;
    margin-top:1.6em;
    margin-left:1.6em;
    max-width:calc(100% - 1.6em);
    min-width:0; }

  .Polaris-SettingAction__Setting{
    flex:1 0 35em; }


  .Polaris-AccountConnection__TermsOfService{
    margin-top:2em; }

  .Polaris-AccountConnection__Content > * + *{
    margin-top:0.8em; }


  .Polaris-ActionMenu-MenuAction{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    font-size:1.5em;
    font-weight:400;
    line-height:2em;
    text-transform:initial;
    letter-spacing:initial;
    position:relative;
    display:inline-flex;
    align-items:center;
    padding:0 1.2em;
    min-height:3.6em;
    text-decoration:none;
    color:#454f5b; }
    .Polaris-ActionMenu-MenuAction:focus{
      outline:none; }
    @media (min-width: 40em){
      .Polaris-ActionMenu-MenuAction{
        font-size:1.4em; } }
    .Polaris-ActionMenu-MenuAction:hover, .Polaris-ActionMenu-MenuAction:active{
      color:#212b36; }
      .Polaris-ActionMenu-MenuAction:hover .Polaris-ActionMenu-MenuAction__IconWrapper svg, .Polaris-ActionMenu-MenuAction:active .Polaris-ActionMenu-MenuAction__IconWrapper svg{
        fill:#212b36;
        color:white; }
    .Polaris-ActionMenu-MenuAction:focus::after, .Polaris-ActionMenu-MenuAction:active::after{
      background:rgba(33, 43, 54, 0.1); }
    .Polaris-ActionMenu-MenuAction::after{
      content:'';
      position:absolute;
      top:0.4em;
      right:0.4em;
      left:0.4em;
      display:block;
      height:2.8em;
      border-radius:3px;
      background:transparent;
      -webkit-backface-visibility:hidden;
              backface-visibility:hidden;
      will-change:background;
      transition-property:background;
      transition-duration:200ms;
      transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--disabled{
      color:#919eab;
      cursor:default;
      pointer-events:none; }
      .Polaris-ActionMenu-MenuAction.Polaris-ActionMenu-MenuAction--disabled .Polaris-ActionMenu-MenuAction__IconWrapper svg{
        fill:#919eab; }

  .Polaris-ActionMenu-MenuAction__ContentWrapper{
    display:flex;
    align-items:center; }

  .Polaris-ActionMenu-MenuAction__IconWrapper{
    display:inline-block; }
    .Polaris-ActionMenu-MenuAction__IconWrapper svg{
      fill:#637381;
      color:white; }
    .Polaris-ActionMenu-MenuAction__IconWrapper:first-child{
      margin-right:0.4em; }
    .Polaris-ActionMenu-MenuAction__IconWrapper:last-child{
      margin-left:0.4em;
      margin-right:-3px; }
    .Polaris-ActionMenu-MenuAction__IconWrapper.Polaris-ActionMenu-MenuAction--disabled svg{
      fill:#919eab; }


  .Polaris-ActionMenu-MenuGroup__Details{
    margin-top:-1.6em;
    padding:1.6em; }


  .Polaris-ActionMenu-RollupActions__RollupActivator button[type='button']{
    margin:0; }


  .Polaris-ActionMenu:not(.Polaris-ActionMenu--rollup){
    margin-left:-1.2em; }

  .Polaris-ActionMenu__ActionsLayout{
    display:flex;
    flex-wrap:wrap;
    align-items:center; }
    .Polaris-ActionMenu__ActionsLayout > *{
      flex:0 0 auto; }


  .Polaris-OptionList-Checkbox{
    position:relative;
    width:100%; }

  .Polaris-OptionList-Checkbox__Input{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }
    .Polaris-OptionList-Checkbox__Input:focus + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox__Input:active + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop{
      background:#5c6ac4; }
    .Polaris-OptionList-Checkbox__Input:checked ~ .Polaris-OptionList-Checkbox__Icon{
      transform:translate(-50%, -50%) scale(1); }
    .Polaris-OptionList-Checkbox__Input:focus + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox__Input:active + .Polaris-OptionList-Checkbox__Backdrop{
      background:#5c6ac4;
      box-shadow:0 0 0 1px #5c6ac4, 0 0 0 0 transparent; }
      .Polaris-OptionList-Checkbox__Input:focus + .Polaris-OptionList-Checkbox__Backdrop::after, .Polaris-OptionList-Checkbox__Input:active + .Polaris-OptionList-Checkbox__Backdrop::after{
        background:white; }
      @media (-ms-high-contrast: active){
        .Polaris-OptionList-Checkbox__Input:focus + .Polaris-OptionList-Checkbox__Backdrop::after, .Polaris-OptionList-Checkbox__Input:active + .Polaris-OptionList-Checkbox__Backdrop::after{
          border:1px dashed buttonText; } }
    .Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop{
      background:#dfe3e8;
      box-shadow:none; }
      .Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop::after{
        background:#f9fafb; }
      @media (-ms-high-contrast: active){
        .Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop{
          color:grayText; } }
    .Polaris-OptionList-Checkbox__Input:disabled ~ .Polaris-OptionList-Checkbox__Icon svg{
      fill:#919eab; }

  .Polaris-OptionList-Checkbox--active .Polaris-OptionList-Checkbox__Backdrop{
    background:#5c6ac4;
    box-shadow:0 0 0 1px #5c6ac4, 0 0 0 0 transparent; }
    .Polaris-OptionList-Checkbox--active .Polaris-OptionList-Checkbox__Backdrop::after{
      background:white; }
    @media (-ms-high-contrast: active){
      .Polaris-OptionList-Checkbox--active .Polaris-OptionList-Checkbox__Backdrop::after{
        border:1px dashed buttonText; } }

  .Polaris-OptionList-Checkbox__Backdrop{
    background-color:#c4cdd5;
    border:0.1em solid transparent;
    box-shadow:0 0 0 1px transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    transition-property:box-shadow, background-color;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1);
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0; }
    .Polaris-OptionList-Checkbox__Backdrop::after{
      content:'';
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      display:block;
      background:linear-gradient(to bottom, white, #f9fafb);
      border-radius:2px; }
    @media (-ms-high-contrast: active){
      .Polaris-OptionList-Checkbox__Backdrop{
        background:transparent;
        border:2px solid buttonText;
        transition:none;
        color:rgba(223, 227, 232, 0.3); }
        .Polaris-OptionList-Checkbox__Backdrop::after{
          top:-4px;
          right:-4px;
          bottom:-4px;
          left:-4px;
          background:none;
          border-radius:4px; } }

  .Polaris-OptionList-Checkbox__Icon{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%) scale(0);
    transform-origin:50% 50%;
    transition:transform 100ms cubic-bezier(0.36, 0, 1, 1) cubic-bezier(0, 0, 0.42, 1); }
    .Polaris-OptionList-Checkbox__Icon svg{
      fill:#5c6ac4; }


  .Polaris-OptionList-Option{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    color:inherit;
    text-decoration:none;
    width:100%;
    min-height:3.6em;
    text-align:left;
    cursor:pointer; }
    .Polaris-OptionList-Option:focus{
      outline:none; }
    .Polaris-OptionList-Option:visited{
      color:inherit; }
    .Polaris-OptionList-Option:hover{
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }

  .Polaris-OptionList-Option__SingleSelectOption{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    text-align:left; }
    .Polaris-OptionList-Option__SingleSelectOption:focus{
      outline:none; }
    .Polaris-OptionList-Option__SingleSelectOption:not(.Polaris-OptionList-Option--disabled){
      color:inherit; }
    .Polaris-OptionList-Option__SingleSelectOption .Polaris-OptionList-Option__Media{
      padding:0 0.8em 0 0; }

  .Polaris-OptionList-Option__Label,
  .Polaris-OptionList-Option__SingleSelectOption{
    display:flex;
    align-items:flex-start;
    width:100%;
    padding:0.8em 1.6em; }

  .Polaris-OptionList-Option__Checkbox{
    box-sizing:border-box;
    display:flex;
    flex-shrink:0;
    width:1.6em;
    height:1.6em;
    margin-top:0.2em;
    margin-right:0.8em; }

  .Polaris-OptionList-Option--focused{
    box-shadow:inset 0.2em 0 0 #5c6ac4;
    background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-OptionList-Option--focused:hover{
      box-shadow:inset 0.2em 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }

  .Polaris-OptionList-Option--disabled{
    background-image:linear-gradient(#f9fafb, #f9fafb); }
    .Polaris-OptionList-Option--disabled .Polaris-OptionList-Option__Media svg{
      fill:#919eab;
      color:white; }

  .Polaris-OptionList-Option--select{
    background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)); }
    .Polaris-OptionList-Option--select.Polaris-OptionList-Option--focused{
      box-shadow:inset 0.2em 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
      .Polaris-OptionList-Option--select.Polaris-OptionList-Option--focused:hover{
        box-shadow:inset 0.2em 0 0 #5c6ac4;
        background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }

  .Polaris-OptionList-Option--active{
    background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }

  .Polaris-OptionList-Option__Media{
    padding:0 0.8em; }
    .Polaris-OptionList-Option__Media svg{
      fill:#454f5b;
      color:white; }


  .Polaris-OptionList{
    margin:0;
    padding:0;
    list-style:none; }

  .Polaris-OptionList__Options{
    margin:0;
    padding:0;
    list-style:none; }

  .Polaris-OptionList__Title{
    font-size:1.3em;
    font-weight:600;
    line-height:1.6em;
    text-transform:uppercase;
    padding:0.8em 1.6em;
    border-bottom:0.1em solid #dfe3e8; }
    @media (min-width: 40em){
      .Polaris-OptionList__Title{
        font-size:1.2em; } }


  .Polaris-Autocomplete-ComboBox__EmptyState{
    padding:0.8em 1.6em; }


  .Polaris-Label{
    -webkit-tap-highlight-color:transparent; }

  .Polaris-Label--hidden{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }

  .Polaris-Label__Text{
    font-weight:400;
    line-height:2em;
    text-transform:initial;
    letter-spacing:initial;
    display:block;
    flex:1 1 auto;
    color:currentColor;
    -webkit-tap-highlight-color:transparent; }

  .Polaris-InlineError{
    display:flex;
    color:#bf0711;
    fill:#bf0711; }

  .Polaris-InlineError__Icon{
    fill:currentColor;
    margin-left:-0.2em;
    margin-right:0.6em; }


  .Polaris-Labelled--hidden > .Polaris-Labelled__LabelWrapper{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }

  .Polaris-Labelled__LabelWrapper{
    word-wrap:break-word;
    word-break:break-word;
    overflow-wrap:break-word;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    align-items:baseline;
    margin-bottom:0.4em; }

  .Polaris-Labelled__HelpText{
    font-size:1.5em;
    font-weight:400;
    line-height:2em;
    text-transform:initial;
    letter-spacing:initial;
    color:#637381;
    margin-top:0.4em; }
    @media (min-width: 40em){
      .Polaris-Labelled__HelpText{
        font-size:1.4em; } }

  .Polaris-Labelled__Error{
    word-wrap:break-word;
    word-break:break-word;
    overflow-wrap:break-word;
    margin-top:0.4em; }

  .Polaris-Labelled__Action{
    flex:0 0 auto; }


  .Polaris-Connected{
    position:relative;
    display:flex; }

  .Polaris-Connected__Item{
    position:relative;
    z-index:10;
    flex:0 0 auto; }
    .Polaris-Connected__Item:not(:first-child){
      margin-left:-1px; }

  .Polaris-Connected__Item--primary{
    z-index:20;
    flex:1 1 auto; }
    .Polaris-Connected__Item--primary:not(:first-child) *{
      border-top-left-radius:0 !important;
      border-bottom-left-radius:0 !important; }
    .Polaris-Connected__Item--primary:not(:last-child) *{
      border-top-right-radius:0 !important;
      border-bottom-right-radius:0 !important; }

  .Polaris-Connected__Item--connection:first-child *{
    border-top-right-radius:0 !important;
    border-bottom-right-radius:0 !important; }
    .Polaris-Connected__Item--connection:first-child *::after{
      border-top-right-radius:0 !important;
      border-bottom-right-radius:0 !important; }

  .Polaris-Connected__Item--connection:last-child *{
    border-top-left-radius:0 !important;
    border-bottom-left-radius:0 !important; }
    .Polaris-Connected__Item--connection:last-child *::after{
      border-top-left-radius:0 !important;
      border-bottom-left-radius:0 !important; }

  .Polaris-Connected__Item--focused{
    z-index:30; }


  .Polaris-TextField{
    font-weight:400;
    line-height:2.4em;
    text-transform:initial;
    letter-spacing:initial;
    position:relative;
    display:flex;
    align-items:center;
    color:var(--p-text-on-surface, #637381);
    cursor:text; }
    .Polaris-TextField svg{
      fill:var(--p-icon-on-surface, #637381); }

  .Polaris-TextField--multiline{
    padding:0;
    flex-wrap:wrap; }
    .Polaris-TextField--multiline > .Polaris-TextField__Input{
      overflow:auto;
      padding-left:1.2em;
      padding-right:1.2em;
      resize:none; }

  .Polaris-TextField--hasValue{
    color:var(--p-text-on-surface, #637381); }

  .Polaris-TextField--focus > .Polaris-TextField__Input,
  .Polaris-TextField__Input:focus{
    outline:none;
    border:0.1em solid transparent; }
    .Polaris-TextField--focus > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after,
    .Polaris-TextField__Input:focus ~ .Polaris-TextField__Backdrop::after{
      opacity:1; }

  .Polaris-TextField--error .Polaris-TextField__Input::-webkit-input-placeholder{
    color:#9c9798; }

  .Polaris-TextField--error .Polaris-TextField__Input::-ms-input-placeholder{
    color:#9c9798; }

  .Polaris-TextField--error .Polaris-TextField__Input::placeholder{
    color:#9c9798; }

  .Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop{
    background-color:var(--p-critical-surface-subdued, #fbeae5);
    border-color:var(--p-critical-divider, #bf0711); }
    .Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after{
      border-color:var(--p-interactive-focus, #bf0711); }

  .Polaris-TextField--readOnly{
    color:var(--p-text-disabled-on-surface); }

  .Polaris-TextField--readOnly > .Polaris-TextField__Backdrop{
    background-color:var(--p-neutral-action-disabled, #f9fafb); }

  .Polaris-TextField--disabled{
    color:var(--p-text-disabled-on-surface, #919eab);
    cursor:initial; }
    .Polaris-TextField--disabled > .Polaris-TextField__Backdrop{
      background-color:var(--p-neutral-action-disabled, #f9fafb);
      box-shadow:none; }
    .Polaris-TextField--disabled svg{
      fill:var(--p-icon-disabled-on-surface, #919eab); }

  .Polaris-TextField__Input{
    font-weight:400;
    text-transform:initial;
    letter-spacing:initial;
    position:relative;
    z-index:20;
    display:block;
    flex:1 1;
    width:100%;
    min-width:0;
    margin:0;
    background:none;
    font-family:inherit;
    font-size:inherit;
    font-weight:inherit;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    caret-color:var(--p-text-on-surface); }
    .Polaris-TextField--hasValue .Polaris-TextField__Input{
      color:var(--p-text-on-surface, black); }
    .Polaris-TextField__Prefix + .Polaris-TextField__Input{
      padding-left:0; }
    .Polaris-TextField__Input:disabled{
      background:none;
      border:0.1em solid transparent;
      color:currentColor;
      opacity:1;
      -webkit-text-fill-color:currentColor; }
    .Polaris-TextField__Input:invalid{
      box-shadow:none; }
    .Polaris-TextField__Input::-webkit-input-placeholder{
      color:var(--p-text-subdued-on-surface, #919eab); }
    .Polaris-TextField__Input::-ms-input-placeholder{
      color:var(--p-text-subdued-on-surface, #919eab); }
    .Polaris-TextField__Input::placeholder{
      color:var(--p-text-subdued-on-surface, #919eab); }
    .Polaris-TextField__Input[type='number']{
      -webkit-appearance:textfield;
         -moz-appearance:textfield;
              appearance:textfield; }
      .Polaris-TextField__Input[type='number']::-webkit-outer-spin-button, .Polaris-TextField__Input[type='number']::-webkit-inner-spin-button{
        -webkit-appearance:none;
                appearance:none;
        margin:0; }

  .Polaris-TextField__Input--hasClearButton[type='search']::-webkit-search-cancel-button{
    -webkit-appearance:none;
            appearance:none; }

  .Polaris-TextField__Input--suffixed{
    padding-right:0; }

  .Polaris-TextField__Input--alignRight{
    text-align:right; }

  .Polaris-TextField__Input--alignLeft{
    text-align:left; }

  .Polaris-TextField__Input--alignCenter{
    text-align:center; }

  .Polaris-TextField__Backdrop{
    position:absolute;
    z-index:10;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:var(--p-surface-foreground, white);
    border:0.1em solid var(--p-divider-subdued-on-surface, #c4cdd5);
    border-top-color:var(--p-divider-on-surface, #c4cdd5);
    border-radius:var(--p-border-radius-base, 3px);
    box-shadow:var(--p-override-none, inset 0 1px 0 0 rgba(99, 115, 129, 0.05));
    pointer-events:none; }
    .Polaris-TextField__Backdrop::after{
      content:'';
      position:absolute;
      top:var(--p-text-field-focus-ring-offset, -0.2em);
      right:var(--p-text-field-focus-ring-offset, -0.2em);
      bottom:var(--p-text-field-focus-ring-offset, -0.2em);
      left:var(--p-text-field-focus-ring-offset, -0.2em);
      border:var(--p-focus-ring-stroke, 0.2em) solid var(--p-interactive-focus, #5c6ac4);
      border-radius:var(--p-text-field-focus-ring-border-radius, inherit);
      opacity:0;
      transition:var(--p-override-none, opacity 200ms cubic-bezier(0.64, 0, 0.35, 1));
      pointer-events:none; }

  .Polaris-TextField__Prefix,
  .Polaris-TextField__Suffix{
    position:relative;
    z-index:20;
    flex:0 0 auto;
    color:currentColor;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none; }

  .Polaris-TextField__Prefix{
    margin-left:1.2em;
    margin-right:0.8em; }

  .Polaris-TextField__Suffix{
    margin-left:0.25em;
    margin-right:1.2em; }

  .Polaris-TextField__CharacterCount{
    color:#637381;
    z-index:20;
    margin:0 1.2em 0 0.25em;
    pointer-events:none;
    text-align:right; }

  .Polaris-TextField__AlignFieldBottom{
    align-self:flex-end;
    width:100%;
    padding-bottom:0.8em; }

  .Polaris-TextField__ClearButton{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    z-index:20;
    margin:0 1.2em 0 0.25em; }
    .Polaris-TextField__ClearButton:focus{
      outline:none; }
    .Polaris-TextField__ClearButton:hover:enabled svg, .Polaris-TextField__ClearButton:focus:enabled svg{
      fill:#637381; }
    .Polaris-TextField__ClearButton:hover:enabled img, .Polaris-TextField__ClearButton:focus:enabled img{
      -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(8%) saturate(825%) hue-rotate(166deg) brightness(95%) contrast(90%);
              filter:brightness(0) saturate(100%) invert(45%) sepia(8%) saturate(825%) hue-rotate(166deg) brightness(95%) contrast(90%); }
    .Polaris-TextField__ClearButton:disabled{
      cursor:default; }

  .Polaris-TextField__Spinner{
    --p-text-field-spinner-offset-large:calc(var(--p-text-field-spinner-offset) + 0.1em);
    z-index:20;
    display:flex;
    align-self:stretch;
    flex-direction:column;
    width:2.2em;
    margin:var(--p-text-field-spinner-offset-large, 1px);
    color:var(--p-icon-on-surface, #212b36);
    cursor:pointer; }

  .Polaris-TextField__SpinnerIcon{
    height:1.2em;
    width:1.2em; }

  .Polaris-TextField__Resizer{
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    height:0;
    visibility:hidden;
    overflow:hidden; }

  .Polaris-TextField__DummyInput{
    font-size:1.6em;
    font-weight:400;
    line-height:2.4em;
    text-transform:initial;
    letter-spacing:initial;
    padding:0.5em 1.2em;
    border:0.1em solid transparent;
    word-wrap:break-word;
    word-break:break-word;
    overflow-wrap:break-word;
    white-space:pre-wrap; }
    @media (min-width: 40em){
      .Polaris-TextField__DummyInput{
        font-size:1.4em; } }

  .Polaris-TextField__Segment{
    --p-text-field-spinner-border-radius:calc(var(--p-border-radius-base) - var(--p-text-field-spinner-offset));
    display:flex;
    flex:1 1;
    justify-content:center;
    align-items:center;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    background:var(--p-neutral-action, linear-gradient(to bottom, white, #f9fafb));
    border:none;
    border-radius:var(--p-text-field-spinner-border-radius, none);
    border-left:var(--p-override-none, 0.1em solid #c4cdd5);
    transition:background 100ms cubic-bezier(0.64, 0, 0.35, 1), box-shadow 100ms cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-TextField__Segment:focus{
      outline:none; }
    .Polaris-TextField__Segment:hover{
      background:var(--p-neutral-action-hovered); }
    .Polaris-TextField__Segment:active{
      background:var(--p-neutral-action-pressed, linear-gradient(to bottom, #f4f6f8, #f4f6f8));
      box-shadow:var(--p-override-none, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2)); }
    .Polaris-TextField__Segment:not(:first-child){
      margin-top:var(--p-override-none, -1px);
      border-top:var(--p-override-none, 0.1em solid #c4cdd5); }
    .Polaris-TextField__Segment:first-child{
      border-top-right-radius:var(--p-text-field-spinner-border-radius, 3px);
      margin-bottom:var(--p-text-field-spinner-offset); }
    .Polaris-TextField__Segment:last-child{
      border-bottom-right-radius:var(--p-text-field-spinner-border-radius, 3px); }


  .Polaris-Autocomplete__Loading{
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    padding:0.8em 1.6em; }


  [data-lock-scrolling]{
    overflow-y:scroll;
    margin:0; }
    [data-lock-scrolling] [data-lock-scrolling-wrapper]{
      overflow:hidden;
      height:100%; }


  .Polaris-Backdrop{
    position:fixed;
    z-index:518;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display:block;
    background-color:rgba(33, 43, 54, 0.4);
    animation:Polaris-Backdrop__fade--in 200ms 1 forwards;
    opacity:1;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity; }

  .Polaris-Backdrop--transparent{
    background-color:transparent; }

  .Polaris-Backdrop--belowNavigation{
    z-index:515; }

  @keyframes Polaris-Backdrop__fade--in{
    0%{
      opacity:0; }
    100%{
      opacity:1; } }


  .Polaris-Banner{
    position:relative;
    display:flex; }

  .Polaris-Banner--withinContentContainer{
    border-radius:var(--p-border-radius-wide, 3px);
    padding:0.8em 1.2em;
    --p-banner-background:var(--p-surface-background);
    --p-banner-border:var(--p-banner-default-border);
    transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
    transition-delay:100ms;
    border-radius:var(--p-border-radius-wide, inherit);
    box-shadow:var(--p-banner-border, none);
    background-color:var(--p-banner-background, #f4f6f8); }
    @media (-ms-high-contrast: active){
      .Polaris-Banner--withinContentContainer{
        box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1em 0.1em windowText; } }
    .Polaris-Banner--withinContentContainer:focus{
      outline:none;
      box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-divider-on-surface, #637381), inset 0 0 0 3px var(--p-divider-on-surface, #637381), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
      position:relative; }
      .Polaris-Banner--withinContentContainer:focus::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.3em;
        right:-0.3em;
        bottom:-0.3em;
        left:-0.3em;
        display:block;
        border:0.2em solid var(--p-interactive-focus);
        border-radius:calc(var(--p-border-radius-wide) + 0.3em);
        pointer-events:none; }
    .Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess{
      --p-banner-background:var(--p-success-surface-subdued);
      --p-banner-border:var(--p-banner-success-border);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      background-color:var(--p-banner-background, #eff7ed); }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1em 0.1em windowText; } }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess:focus{
        outline:none;
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-success-divider, #50b83c), inset 0 0 0 3px var(--p-success-divider, #50b83c), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
        position:relative; }
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess:focus::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.3em;
          right:-0.3em;
          bottom:-0.3em;
          left:-0.3em;
          display:block;
          border:0.2em solid var(--p-interactive-focus);
          border-radius:calc(var(--p-border-radius-wide) + 0.3em);
          pointer-events:none; }
    .Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo{
      --p-banner-background:var(--p-highlight-surface-subdued);
      --p-banner-border:var(--p-banner-highlight-border);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      background-color:var(--p-banner-background, #eef9f9); }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1em 0.1em windowText; } }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo:focus{
        outline:none;
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-highlight-divider, #47c1bf), inset 0 0 0 3px var(--p-highlight-divider, #47c1bf), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
        position:relative; }
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo:focus::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.3em;
          right:-0.3em;
          bottom:-0.3em;
          left:-0.3em;
          display:block;
          border:0.2em solid var(--p-interactive-focus);
          border-radius:calc(var(--p-border-radius-wide) + 0.3em);
          pointer-events:none; }
    .Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning{
      --p-banner-background:var(--p-warning-surface-subdued);
      --p-banner-border:var(--p-banner-warning-border);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      background-color:var(--p-banner-background, #fdf7e3); }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1em 0.1em windowText; } }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning:focus{
        outline:none;
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-warning-divider, #eec200), inset 0 0 0 3px var(--p-warning-divider, #eec200), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
        position:relative; }
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning:focus::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.3em;
          right:-0.3em;
          bottom:-0.3em;
          left:-0.3em;
          display:block;
          border:0.2em solid var(--p-interactive-focus);
          border-radius:calc(var(--p-border-radius-wide) + 0.3em);
          pointer-events:none; }
    .Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical{
      --p-banner-background:var(--p-critical-surface-subdued);
      --p-banner-border:var(--p-banner-critical-border);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      background-color:var(--p-banner-background, #fdf3f0); }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1em 0.1em windowText; } }
      .Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical:focus{
        outline:none;
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-critical-diviver, #de3618), inset 0 0 0 3px var(--p-critical-diviver, #de3618), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
        position:relative; }
        .Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical:focus::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.3em;
          right:-0.3em;
          bottom:-0.3em;
          left:-0.3em;
          display:block;
          border:0.2em solid var(--p-interactive-focus);
          border-radius:calc(var(--p-border-radius-wide) + 0.3em);
          pointer-events:none; }
    .Polaris-Banner--withinContentContainer + .Polaris-Banner{
      margin-top:0.8em; }
    .Polaris-Banner--withinContentContainer .Polaris-Banner__Ribbon{
      padding-right:1.2em; }
    .Polaris-Banner--withinContentContainer .Polaris-Banner__Actions{
      padding:1.2em 0 0.4em 0; }
    .Polaris-Banner--withinContentContainer .Polaris-Banner__Dismiss{
      right:1.2em;
      top:1.2em;
      position:absolute; }

  .Polaris-Banner--withinPage{
    border-radius:0 0 3px 3px;
    padding:1.6em;
    --p-banner-background:var(--p-surface-background);
    --p-banner-border:var(--p-banner-default-border);
    transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
    transition-delay:100ms;
    border-radius:var(--p-border-radius-wide, inherit);
    box-shadow:var(--p-banner-border, none);
    box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-divider-on-surface, #637381), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
    background-color:var(--p-banner-background, #f4f6f8); }
    @media (-ms-high-contrast: active){
      .Polaris-Banner--withinPage{
        box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1em 0.1em windowText; } }
    .Polaris-Banner--withinPage:focus{
      outline:none;
      box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-divider-on-surface, #637381), inset 0 0 0 3px var(--p-divider-on-surface, #637381), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
      position:relative; }
      .Polaris-Banner--withinPage:focus::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.3em;
        right:-0.3em;
        bottom:-0.3em;
        left:-0.3em;
        display:block;
        border:0.2em solid var(--p-interactive-focus);
        border-radius:calc(var(--p-border-radius-wide) + 0.3em);
        pointer-events:none; }
    .Polaris-Banner--withinPage.Polaris-Banner--statusSuccess{
      --p-banner-background:var(--p-success-surface-subdued);
      --p-banner-border:var(--p-banner-success-border);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-success-divider, #50b83c), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
      background-color:var(--p-banner-background, #eff7ed); }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinPage.Polaris-Banner--statusSuccess{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1em 0.1em windowText; } }
      .Polaris-Banner--withinPage.Polaris-Banner--statusSuccess:focus{
        outline:none;
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-success-divider, #50b83c), inset 0 0 0 3px var(--p-success-divider, #50b83c), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
        position:relative; }
        .Polaris-Banner--withinPage.Polaris-Banner--statusSuccess:focus::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.3em;
          right:-0.3em;
          bottom:-0.3em;
          left:-0.3em;
          display:block;
          border:0.2em solid var(--p-interactive-focus);
          border-radius:calc(var(--p-border-radius-wide) + 0.3em);
          pointer-events:none; }
    .Polaris-Banner--withinPage.Polaris-Banner--statusInfo{
      --p-banner-background:var(--p-highlight-surface-subdued);
      --p-banner-border:var(--p-banner-highlight-border);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-highlight-divider, #47c1bf), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
      background-color:var(--p-banner-background, #eef9f9); }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinPage.Polaris-Banner--statusInfo{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1em 0.1em windowText; } }
      .Polaris-Banner--withinPage.Polaris-Banner--statusInfo:focus{
        outline:none;
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-highlight-divider, #47c1bf), inset 0 0 0 3px var(--p-highlight-divider, #47c1bf), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
        position:relative; }
        .Polaris-Banner--withinPage.Polaris-Banner--statusInfo:focus::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.3em;
          right:-0.3em;
          bottom:-0.3em;
          left:-0.3em;
          display:block;
          border:0.2em solid var(--p-interactive-focus);
          border-radius:calc(var(--p-border-radius-wide) + 0.3em);
          pointer-events:none; }
    .Polaris-Banner--withinPage.Polaris-Banner--statusWarning{
      --p-banner-background:var(--p-warning-surface-subdued);
      --p-banner-border:var(--p-banner-warning-border);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-warning-divider, #eec200), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
      background-color:var(--p-banner-background, #fdf7e3); }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinPage.Polaris-Banner--statusWarning{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1em 0.1em windowText; } }
      .Polaris-Banner--withinPage.Polaris-Banner--statusWarning:focus{
        outline:none;
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-warning-divider, #eec200), inset 0 0 0 3px var(--p-warning-divider, #eec200), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
        position:relative; }
        .Polaris-Banner--withinPage.Polaris-Banner--statusWarning:focus::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.3em;
          right:-0.3em;
          bottom:-0.3em;
          left:-0.3em;
          display:block;
          border:0.2em solid var(--p-interactive-focus);
          border-radius:calc(var(--p-border-radius-wide) + 0.3em);
          pointer-events:none; }
    .Polaris-Banner--withinPage.Polaris-Banner--statusCritical{
      --p-banner-background:var(--p-critical-surface-subdued);
      --p-banner-border:var(--p-banner-critical-border);
      transition:box-shadow 200ms cubic-bezier(0.64, 0, 0.35, 1);
      transition-delay:100ms;
      border-radius:var(--p-border-radius-wide, inherit);
      box-shadow:var(--p-banner-border, none);
      box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-critical-diviver, #de3618), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
      background-color:var(--p-banner-background, #fdf3f0); }
      @media (-ms-high-contrast: active){
        .Polaris-Banner--withinPage.Polaris-Banner--statusCritical{
          box-shadow:inset 0 3px 0 0 windowText, inset 0 0 0.1em 0.1em windowText; } }
      .Polaris-Banner--withinPage.Polaris-Banner--statusCritical:focus{
        outline:none;
        box-shadow:var(--p-banner-border, inset 0 3px 0 0 var(--p-critical-diviver, #de3618), inset 0 0 0 3px var(--p-critical-diviver, #de3618), 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
        position:relative; }
        .Polaris-Banner--withinPage.Polaris-Banner--statusCritical:focus::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.3em;
          right:-0.3em;
          bottom:-0.3em;
          left:-0.3em;
          display:block;
          border:0.2em solid var(--p-interactive-focus);
          border-radius:calc(var(--p-border-radius-wide) + 0.3em);
          pointer-events:none; }
    .Polaris-Banner--withinPage + .Polaris-Banner{
      margin-top:2em; }
    .Polaris-Banner--withinPage .Polaris-Banner__Ribbon{
      padding-right:1.6em; }
    .Polaris-Banner--withinPage .Polaris-Banner__Actions{
      padding-top:1.6em; }
    .Polaris-Banner--withinPage .Polaris-Banner__Dismiss{
      right:1.6em;
      top:2em;
      position:absolute; }

  .Polaris-Banner--hasDismiss{
    padding-right:5.6em; }

  .Polaris-Banner__Heading{
    padding-top:0.2em;
    word-break:break-word; }

  .Polaris-Banner__Content{
    word-wrap:break-word;
    word-break:break-word;
    overflow-wrap:break-word;
    padding:0.4em 0; }

  .Polaris-Banner__Ribbon{
    flex:0 0 3.2em; }

  .Polaris-Banner__PrimaryAction{
    margin-right:0.6em; }

  .Polaris-Banner__SecondaryAction{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    color:inherit;
    text-decoration:none;
    display:inline-block;
    margin:-0.8em -0.6em;
    padding:0.8em 1.2em;
    color:var(--p-text-on-surface, #212b36);
    padding-left:0.6em; }
    .Polaris-Banner__SecondaryAction:focus{
      outline:none; }
    .Polaris-Banner__SecondaryAction:visited{
      color:inherit; }
    .Polaris-Banner__SecondaryAction:hover > .Polaris-Banner__Text::after{
      opacity:0.75; }
    .Polaris-Banner__SecondaryAction:active > .Polaris-Banner__Text::after{
      opacity:0; }
    .Polaris-Banner__SecondaryAction:focus > .Polaris-Banner__Text{
      padding:2px 5px;
      margin:-2px -5px;
      background:rgba(33, 43, 54, 0.1);
      border-radius:3px;
      transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1); }
      @media (-ms-high-contrast: active){
        .Polaris-Banner__SecondaryAction:focus > .Polaris-Banner__Text{
          outline:2px dotted; } }
      .Polaris-Banner__SecondaryAction:focus > .Polaris-Banner__Text::after{
        opacity:0; }

  .Polaris-Banner__Text{
    position:relative; }
    .Polaris-Banner__Text::after, .Polaris-Banner__Text::before{
      content:'';
      position:absolute; }
    .Polaris-Banner__Text::after{
      bottom:0;
      left:0;
      right:0;
      display:block;
      border:0.1em solid currentColor;
      opacity:0.25;
      -webkit-backface-visibility:hidden;
              backface-visibility:hidden;
      will-change:opacity;
      transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1); }


  .Polaris-Breadcrumbs__Breadcrumb{
    font-size:1.5em;
    font-weight:400;
    line-height:2em;
    text-transform:initial;
    letter-spacing:initial;
    color:#637381;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    position:relative;
    display:flex;
    align-items:center;
    min-height:3.6em;
    margin:-0.4em -0.8em -0.4em -0.4em;
    padding:0.8em 0.8em;
    color:#637381;
    text-decoration:none; }
    @media (min-width: 40em){
      .Polaris-Breadcrumbs__Breadcrumb{
        font-size:1.4em; } }
    .Polaris-Breadcrumbs__Breadcrumb:focus{
      outline:none; }
    .Polaris-Breadcrumbs__Breadcrumb:hover, .Polaris-Breadcrumbs__Breadcrumb:active{
      color:#212b36;
      text-decoration:none; }
      .Polaris-Breadcrumbs__Breadcrumb:hover .Polaris-Breadcrumbs__Icon svg, .Polaris-Breadcrumbs__Breadcrumb:active .Polaris-Breadcrumbs__Icon svg{
        fill:#212b36; }
    .Polaris-Breadcrumbs__Breadcrumb:focus{
      outline:none; }
      .Polaris-Breadcrumbs__Breadcrumb:focus::after{
        background:rgba(33, 43, 54, 0.1); }
    .Polaris-Breadcrumbs__Breadcrumb::after{
      content:'';
      position:absolute;
      z-index:10;
      top:0.4em;
      right:0;
      left:0;
      display:block;
      height:2.8em;
      border-radius:3px;
      -webkit-backface-visibility:hidden;
              backface-visibility:hidden;
      will-change:background;
      transition:background 200ms cubic-bezier(0.64, 0, 0.35, 1); }

  .Polaris-Breadcrumbs__Content{
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    position:relative;
    z-index:20; }

  .Polaris-Breadcrumbs__Icon{
    height:2em;
    width:2em;
    margin:-1em 0 -1em -0.8em; }
    .Polaris-Breadcrumbs__Icon svg{
      fill:#637381; }


  .Polaris-TextContainer > *:not(:first-child){
    margin-top:1.6em; }

  .Polaris-TextContainer--spacingTight > *:not(:first-child){
    margin-top:0.8em; }

  .Polaris-TextContainer--spacingLoose > *:not(:first-child){
    margin-top:2em; }


  .Polaris-CalloutCard{
    display:flex;
    align-items:center; }

  .Polaris-CalloutCard__Image{
    display:none;
    flex:0 0 auto;
    width:10em; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-CalloutCard__Image{
        display:block;
        margin-left:2em; } }
    @media (min-width: 30.625em){
      .Polaris-CalloutCard__Image{
        display:block;
        margin-left:2em; } }

  .Polaris-CalloutCard__DismissImage{
    margin-right:2em; }

  .Polaris-CalloutCard__Content{
    flex:1 1 auto; }

  .Polaris-CalloutCard__Title{
    margin-bottom:2em; }

  .Polaris-CalloutCard__Buttons{
    margin-top:2em; }

  .Polaris-CalloutCard__Container{
    position:relative; }

  .Polaris-CalloutCard__Dismiss{
    right:1.6em;
    top:1.6em;
    position:absolute; }


  .Polaris-Caption{
    font-size:1.3em;
    font-weight:400;
    line-height:2em;
    margin:0; }
    @media (min-width: 40em){
      .Polaris-Caption{
        font-size:1.2em;
        line-height:1.6em; } }


  .Polaris-Choice{
    display:inline-flex;
    justify-content:flex-start;
    padding:0.4em 0; }

  .Polaris-Choice--labelHidden{
    padding:0; }
    .Polaris-Choice--labelHidden > .Polaris-Choice__Label{
      position:absolute !important;
      top:0;
      clip:rect(1px, 1px, 1px, 1px) !important;
      overflow:hidden !important;
      height:1px !important;
      width:1px !important;
      padding:0 !important;
      border:0 !important; }
    .Polaris-Choice--labelHidden .Polaris-Choice__Control{
      margin-top:0;
      margin-right:0; }

  .Polaris-Choice--disabled > .Polaris-Choice__Label{
    color:#919eab; }
    @media (-ms-high-contrast: active){
      .Polaris-Choice--disabled > .Polaris-Choice__Label{
        color:grayText; } }

  .Polaris-Choice__Control{
    display:flex;
    flex:0 0 auto;
    align-items:stretch;
    width:1.6em;
    height:1.6em;
    margin-top:0.2em;
    margin-right:0.8em; }
    .Polaris-Choice__Control > *{
      width:100%; }

  .Polaris-Choice__Label{
    font-size:1.5em;
    font-weight:400;
    line-height:2em;
    text-transform:initial;
    letter-spacing:initial;
    -webkit-tap-highlight-color:transparent; }
    @media (min-width: 40em){
      .Polaris-Choice__Label{
        font-size:1.4em; } }

  .Polaris-Choice__Descriptions{
    padding-left:2.4em; }

  .Polaris-Choice__HelpText{
    font-size:1.5em;
    font-weight:400;
    line-height:2em;
    text-transform:initial;
    letter-spacing:initial;
    color:#637381;
    margin-bottom:0.4em; }
    @media (min-width: 40em){
      .Polaris-Choice__HelpText{
        font-size:1.4em; } }


  .Polaris-Checkbox{
    position:relative; }

  .Polaris-Checkbox--error .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop,
  .Polaris-Checkbox--error .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop,
  .Polaris-Checkbox--error .Polaris-Checkbox__Backdrop{
    background:#bf0711;
    box-shadow:0 0 0 1px transparent; }
    .Polaris-Checkbox--error .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop::after,
    .Polaris-Checkbox--error .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop::after,
    .Polaris-Checkbox--error .Polaris-Checkbox__Backdrop::after{
      background:#fbeae5; }

  .Polaris-Checkbox--error .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop,
  .Polaris-Checkbox--error .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop{
    background:#5c6ac4;
    box-shadow:0 0 0 1px #5c6ac4; }
    .Polaris-Checkbox--error .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop::after,
    .Polaris-Checkbox--error .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop::after{
      background:#fbeae5; }

  .Polaris-Checkbox--error .Polaris-Checkbox__Icon svg{
    fill:#bf0711; }

  .Polaris-Checkbox__Input{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }
    .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop{
      background:#5c6ac4; }
    .Polaris-Checkbox__Input:checked ~ .Polaris-Checkbox__Icon, .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate ~ .Polaris-Checkbox__Icon{
      transform:translate(-50%, -50%) scale(1); }
    .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop{
      background:#5c6ac4;
      box-shadow:0 0 0 1px #5c6ac4, 0 0 0 0 transparent; }
      .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop::after, .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop::after{
        background:white; }
      @media (-ms-high-contrast: active){
        .Polaris-Checkbox__Input:focus + .Polaris-Checkbox__Backdrop::after, .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop::after{
          border:1px dashed buttonText; } }
    .Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop{
      background:#dfe3e8;
      box-shadow:none; }
      .Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop::after{
        background:#f9fafb; }
      @media (-ms-high-contrast: active){
        .Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop{
          color:grayText; } }
    .Polaris-Checkbox__Input:disabled ~ .Polaris-Checkbox__Icon svg{
      fill:#919eab; }

  .Polaris-Checkbox__Backdrop{
    background-color:#c4cdd5;
    border:0.1em solid transparent;
    box-shadow:0 0 0 1px transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    transition-property:box-shadow, background-color;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1);
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    pointer-events:none; }
    .Polaris-Checkbox__Backdrop::after{
      content:'';
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      display:block;
      background:linear-gradient(to bottom, white, #f9fafb);
      border-radius:2px; }
    @media (-ms-high-contrast: active){
      .Polaris-Checkbox__Backdrop{
        background:transparent;
        border:2px solid buttonText;
        transition:none;
        color:rgba(223, 227, 232, 0.3); }
        .Polaris-Checkbox__Backdrop::after{
          top:-4px;
          right:-4px;
          bottom:-4px;
          left:-4px;
          background:none;
          border-radius:4px; } }

  .Polaris-Checkbox__Icon{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%) scale(0);
    transform-origin:50% 50%;
    transition:transform 100ms cubic-bezier(0.36, 0, 1, 1) cubic-bezier(0, 0, 0.42, 1);
    pointer-events:none; }
    .Polaris-Checkbox__Icon svg{
      fill:#5c6ac4; }
    @media (-ms-high-contrast: active){
      .Polaris-Checkbox__Icon{
        fill:windowText; } }


  .Polaris-RadioButton{
    position:relative; }

  .Polaris-RadioButton__Input{
    margin:0;
    padding:0;
    opacity:0; }
    .Polaris-RadioButton__Input:focus + .Polaris-RadioButton__Backdrop, .Polaris-RadioButton__Input:active + .Polaris-RadioButton__Backdrop, .Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop{
      background:#5c6ac4; }
    .Polaris-RadioButton__Input:checked ~ .Polaris-RadioButton__Icon{
      transform:translate(-50%, -50%) scale(1); }
    .Polaris-RadioButton__Input:focus + .Polaris-RadioButton__Backdrop, .Polaris-RadioButton__Input:active + .Polaris-RadioButton__Backdrop{
      background:#5c6ac4;
      box-shadow:0 0 0 1px #5c6ac4, 0 0 0 0 transparent; }
      .Polaris-RadioButton__Input:focus + .Polaris-RadioButton__Backdrop::after, .Polaris-RadioButton__Input:active + .Polaris-RadioButton__Backdrop::after{
        background:white; }
      @media (-ms-high-contrast: active){
        .Polaris-RadioButton__Input:focus + .Polaris-RadioButton__Backdrop::after, .Polaris-RadioButton__Input:active + .Polaris-RadioButton__Backdrop::after{
          border:1px dashed buttonText; } }
    .Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop{
      background:#dfe3e8;
      box-shadow:none; }
      .Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop::after{
        background:#f9fafb; }
      @media (-ms-high-contrast: active){
        .Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop{
          color:grayText; } }
    .Polaris-RadioButton__Input:disabled ~ .Polaris-RadioButton__Icon{
      background-color:#c4cdd5; }

  .Polaris-RadioButton__Backdrop{
    background-color:#c4cdd5;
    border:0.1em solid transparent;
    box-shadow:0 0 0 1px transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    transition-property:box-shadow, background-color;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1);
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    border-radius:50%; }
    .Polaris-RadioButton__Backdrop::after{
      content:'';
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      display:block;
      background:linear-gradient(to bottom, white, #f9fafb);
      border-radius:2px; }
    @media (-ms-high-contrast: active){
      .Polaris-RadioButton__Backdrop{
        background:transparent;
        border:2px solid buttonText;
        transition:none;
        color:rgba(223, 227, 232, 0.3); }
        .Polaris-RadioButton__Backdrop::after{
          top:-4px;
          right:-4px;
          bottom:-4px;
          left:-4px;
          background:none;
          border-radius:4px; } }
    .Polaris-RadioButton__Backdrop::after{
      border-radius:50%; }

  .Polaris-RadioButton__Icon{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%) scale(0);
    transform-origin:50% 50%;
    height:50%;
    width:50%;
    background:#5c6ac4;
    border-radius:50%;
    transition:transform 100ms cubic-bezier(0.36, 0, 1, 1) cubic-bezier(0, 0, 0.42, 1); }
    @media (-ms-high-contrast: active){
      .Polaris-RadioButton__Icon{
        background:windowText; } }


  .Polaris-ChoiceList{
    margin:0;
    padding:0;
    border:none; }

  .Polaris-ChoiceList--titleHidden > .Polaris-ChoiceList__Title{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }

  .Polaris-ChoiceList__Choices{
    margin:0;
    padding:0;
    list-style:none; }

  .Polaris-ChoiceList__ChoiceChildren{
    margin-bottom:0.8em;
    padding-left:2.4em; }

  .Polaris-ChoiceList__ChoiceError{
    margin-top:0.4em;
    margin-bottom:0.8em; }

  .Polaris-ChoiceList__Title{
    font-size:1.5em;
    font-weight:400;
    line-height:2em;
    text-transform:initial;
    letter-spacing:initial;
    display:block;
    margin:0 0 0.4em;
    padding:0; }
    @media (min-width: 40em){
      .Polaris-ChoiceList__Title{
        font-size:1.4em; } }


  .Polaris-Collapsible{
    overflow:hidden;
    max-height:0;
    padding-top:0;
    padding-bottom:0;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity, max-height; }

  .Polaris-Collapsible--animating{
    transition-property:opacity, max-height;
    transition-duration:300ms;
    transition-timing-function:cubic-bezier(0, 0, 0.42, 1); }

  .Polaris-Collapsible--open{
    opacity:1; }

  .Polaris-Collapsible--fullyOpen{
    overflow:visible; }


  .Polaris-ColorPicker{
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
    display:flex; }

  .Polaris-ColorPicker__MainColor{
    background-image:linear-gradient(45deg, #dfe3e8 25%, transparent 25%), linear-gradient(-45deg, #dfe3e8 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #dfe3e8 75%), linear-gradient(-45deg, transparent 75%, #dfe3e8 75%);
    background-size:1.6em 1.6em;
    background-position:0 0, 0 0.8em, 0.8em -0.8em, -0.8em 0;
    position:relative;
    overflow:hidden;
    height:16em;
    width:16em;
    border-radius:4px;
    cursor:pointer; }
    .Polaris-ColorPicker__MainColor .Polaris-ColorPicker__Dragger{
      right:0.9em;
      margin:0; }
    .Polaris-ColorPicker__MainColor .Polaris-ColorPicker__ColorLayer{
      box-shadow:inset 0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08);
      border-radius:4px; }
    .Polaris-ColorPicker__MainColor::after, .Polaris-ColorPicker__MainColor::before{
      content:'';
      position:absolute;
      z-index:20;
      top:0;
      left:0;
      display:block;
      height:100%;
      width:100%;
      pointer-events:none;
      border-radius:3px; }
    .Polaris-ColorPicker__MainColor::before{
      background:linear-gradient(to right, white, transparent); }
    .Polaris-ColorPicker__MainColor::after{
      background-image:linear-gradient(to top, black, transparent);
      box-shadow:inset 0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08); }

  .Polaris-ColorPicker__Dragger{
    position:relative;
    z-index:30;
    bottom:0.9em;
    transform:none;
    height:1.8em;
    width:1.8em;
    margin:0 auto;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:transform;
    background:transparent;
    border:3px solid white;
    box-shadow:0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08), inset 0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08);
    border-radius:50%;
    pointer-events:none; }

  .Polaris-ColorPicker__HuePicker,
  .Polaris-ColorPicker__AlphaPicker{
    position:relative;
    overflow:hidden;
    height:16em;
    width:2.4em;
    margin-left:0.8em;
    border-width:3px;
    border-radius:8em; }

  .Polaris-ColorPicker__HuePicker{
    background-image:linear-gradient(to bottom, red 1.8em, yellow, lime, cyan, blue, magenta, red 14.2em);
    box-shadow:inset 0 0 2px 0 rgba(0, 0, 0, 0.5); }

  .Polaris-ColorPicker__AlphaPicker{
    background-image:linear-gradient(45deg, #dfe3e8 25%, transparent 25%), linear-gradient(-45deg, #dfe3e8 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #dfe3e8 75%), linear-gradient(-45deg, transparent 75%, #dfe3e8 75%);
    background-size:1.6em 1.6em;
    background-position:0 0, 0 0.8em, 0.8em -0.8em, -0.8em 0; }
    .Polaris-ColorPicker__AlphaPicker .Polaris-ColorPicker__ColorLayer{
      border-radius:8em; }

  .Polaris-ColorPicker__ColorLayer{
    position:absolute;
    z-index:10;
    top:0;
    left:0;
    height:100%;
    width:100%;
    box-shadow:inset 0 0 2px 0 rgba(0, 0, 0, 0.5);
    pointer-events:none; }

  .Polaris-ColorPicker__Slidable{
    height:100%;
    width:100%;
    cursor:pointer; }


  .Polaris-DataTable{
    position:relative;
    max-width:100vw; }

  .Polaris-DataTable--condensed .Polaris-DataTable__Navigation{
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    padding:1.6em 0.8em; }
    @media (min-width: 48em){
      .Polaris-DataTable--condensed .Polaris-DataTable__Navigation{
        justify-content:flex-end; } }

  .Polaris-DataTable__Navigation{
    display:none; }

  .Polaris-DataTable__Pip{
    height:0.6em;
    width:0.6em;
    background:#919eab;
    border-radius:3px; }
    .Polaris-DataTable__Pip:not(:last-of-type){
      margin-right:0.4em; }

  .Polaris-DataTable__Pip--visible{
    background:#212b36; }

  .Polaris-DataTable__ScrollContainer{
    overflow-x:auto;
    -webkit-overflow-scrolling:touch; }

  .Polaris-DataTable__Table{
    width:100%;
    border-spacing:0; }

  @media (min-width: 48em){
    .Polaris-DataTable__TableRow:hover .Polaris-DataTable__Cell{
      background:#f9fafb; } }

  .Polaris-DataTable__Cell{
    padding:1.6em;
    border-bottom:0.1em solid #f4f6f8;
    white-space:nowrap;
    text-align:left;
    transition:background-color 0.2s ease-in-out; }

  .Polaris-DataTable__Cell--firstColumn{
    font-weight:400;
    color:var(--p-text-on-surface, #212b36);
    text-align:left;
    white-space:normal; }

  .Polaris-DataTable__Cell--numeric{
    text-align:right; }

  .Polaris-DataTable__Cell--truncated{
    white-space:nowrap;
    overflow-x:hidden;
    text-overflow:ellipsis;
    max-width:14.5em; }

  .Polaris-DataTable__Cell--header{
    font-weight:400;
    color:var(--p-text-on-surface, #212b36);
    border-bottom:0.1em solid #c4cdd5;
    border-top:0; }

  .Polaris-DataTable__Cell--sortable{
    padding:0; }

  .Polaris-DataTable__Cell--verticalAlignTop{
    vertical-align:top; }

  .Polaris-DataTable__Cell--verticalAlignBottom{
    vertical-align:bottom; }

  .Polaris-DataTable__Cell--verticalAlignMiddle{
    vertical-align:middle; }

  .Polaris-DataTable__Cell--verticalAlignBaseline{
    vertical-align:baseline; }

  .Polaris-DataTable__Icon{
    display:flex;
    align-self:flex-end;
    opacity:0;
    transition:opacity 200ms cubic-bezier(0.64, 0, 0.35, 1), fill 200ms cubic-bezier(0.64, 0, 0.35, 1); }

  .Polaris-DataTable__Heading{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    position:relative;
    display:inline-flex;
    justify-content:flex-end;
    align-items:baseline;
    transition:color 200ms cubic-bezier(0.64, 0, 0.35, 1);
    padding:1.6em;
    cursor:pointer; }
    .Polaris-DataTable__Heading:focus{
      outline:none; }
    .Polaris-DataTable__Heading:hover, .Polaris-DataTable__Heading:focus{
      color:#5c6ac4; }
      .Polaris-DataTable__Heading:hover svg, .Polaris-DataTable__Heading:focus svg{
        fill:#5c6ac4; }
      .Polaris-DataTable__Heading:hover .Polaris-DataTable__Icon, .Polaris-DataTable__Heading:focus .Polaris-DataTable__Icon{
        opacity:1; }

  .Polaris-DataTable__Heading--left{
    justify-content:flex-start;
    flex-direction:row-reverse; }

  .Polaris-DataTable__Cell--sorted .Polaris-DataTable__Icon{
    opacity:1; }

  .Polaris-DataTable__Cell--total{
    font-weight:600;
    background:#f9fafb;
    border-bottom:0.1em solid #dfe3e8; }

  .Polaris-DataTable--cellTotalFooter{
    border-top:0.1em solid #dfe3e8;
    border-bottom:none; }

  .Polaris-DataTable__Footer{
    padding:1.6em;
    background:#f4f6f8;
    color:#637381;
    text-align:center; }


  .Polaris-DatePicker{
    position:relative; }

  .Polaris-DatePicker__MonthContainer{
    display:flex;
    flex-wrap:wrap;
    margin-top:-1.6em;
    margin-left:-1.6em; }

  .Polaris-DatePicker__Month{
    flex:1 1 auto;
    margin-top:1.6em;
    margin-left:1.6em;
    max-width:calc(100% - 1.6em);
    min-width:23em; }

  .Polaris-DatePicker__Month--current{
    font-weight:700; }

  .Polaris-DatePicker__Week,
  .Polaris-DatePicker__WeekHeadings{
    display:flex; }
    .Polaris-DatePicker__Week + .Polaris-DatePicker__Week,
    .Polaris-DatePicker__WeekHeadings + .Polaris-DatePicker__Week{
      margin-top:-1px; }

  .Polaris-DatePicker__Day{
    display:block;
    flex:1 0;
    width:14.28571%;
    margin:0;
    padding:0.8em;
    background:transparent;
    border:0.1em solid #dfe3e8;
    border-radius:0;
    outline:none;
    font-size:1.2em;
    text-align:center;
    cursor:pointer; }
    .Polaris-DatePicker__Day:hover{
      background:#dfe3e8; }
    .Polaris-DatePicker__Day:focus{
      box-shadow:inset 0 0 0 2px #5c6ac4; }
    .Polaris-DatePicker__Day + .Polaris-DatePicker__Day,
    .Polaris-DatePicker__Day + .Polaris-DatePicker__EmptyDay{
      margin-left:-1px; }

  .Polaris-DatePicker__Day--today{
    font-weight:700; }

  .Polaris-DatePicker__Day--inRange{
    z-index:20;
    background:#b3bcf5;
    border:0.1em solid #9ca6de; }
    .Polaris-DatePicker__Day--inRange:hover{
      background:#9ca8f2; }
    @media screen and (-ms-high-contrast: active){
      .Polaris-DatePicker__Day--inRange{
        background:highlight; } }

  .Polaris-DatePicker__Day--selected{
    z-index:30;
    background:#5c6ac4;
    border:0.1em solid #5c6ac4;
    color:white; }
    .Polaris-DatePicker__Day--selected:hover{
      background:#4959bd; }
    .Polaris-DatePicker__Day--selected:focus{
      box-shadow:inset 0 0 0 2px #202e78; }
    @media screen and (-ms-high-contrast: active){
      .Polaris-DatePicker__Day--selected{
        background:highlight; } }

  .Polaris-DatePicker__Day--disabled{
    background-color:#f9fafb;
    color:#c4cdd5;
    cursor:default; }
    .Polaris-DatePicker__Day--disabled:hover{
      background-color:#f9fafb; }
    .Polaris-DatePicker__Day--disabled:focus{
      box-shadow:none; }

  .Polaris-DatePicker__EmptyDay{
    display:block;
    flex:1 0;
    width:14.28571%;
    margin:0;
    padding:0.8em;
    border:0.1em solid #dfe3e8; }
    .Polaris-DatePicker__EmptyDay + .Polaris-DatePicker__EmptyDay,
    .Polaris-DatePicker__EmptyDay + .Polaris-DatePicker__Day{
      margin-left:-1px; }

  .Polaris-DatePicker__Weekday{
    display:block;
    flex:1 0;
    padding:0.8em;
    background:transparent;
    font-size:1.2em;
    color:#637381;
    text-align:center; }
    .Polaris-DatePicker__Weekday + .Polaris-DatePicker__Weekday{
      margin-left:-1px; }

  .Polaris-DatePicker__Weekday--current{
    font-weight:700;
    color:black; }

  .Polaris-DatePicker__Header{
    position:absolute;
    top:1.6em;
    display:flex;
    justify-content:space-between;
    width:100%; }

  .Polaris-DatePicker__Title{
    flex:1 1 auto;
    margin-top:0.2em;
    padding-bottom:0.4em;
    text-align:center; }


  .Polaris-DescriptionList{
    margin:0;
    padding:0; }
    @media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em){
      [data-has-navigation] .Polaris-DescriptionList{
        display:flex;
        flex-wrap:wrap;
        align-items:flex-start; } }
    @media (min-width: 36.875em){
      .Polaris-DescriptionList{
        display:flex;
        flex-wrap:wrap;
        align-items:flex-start; } }

  .Polaris-DescriptionList__Term{
    font-weight:600;
    padding:1.6em 0 0.8em; }
    @media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em){
      [data-has-navigation] .Polaris-DescriptionList__Term{
        flex:0 1 25%;
        padding:1.6em 1.6em 1.6em 0; }
        .Polaris-DescriptionList__Description + [data-has-navigation] .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description{
          border-top:0.1em solid #dfe3e8; } }
    @media (min-width: 36.875em){
      .Polaris-DescriptionList__Term{
        flex:0 1 25%;
        padding:1.6em 1.6em 1.6em 0; }
        .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description{
          border-top:0.1em solid #dfe3e8; } }

  .Polaris-DescriptionList__Description{
    margin-left:0;
    padding:0 0 1.6em; }
    .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term{
      border-top:0.1em solid #dfe3e8; }
    @media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em){
      [data-has-navigation] .Polaris-DescriptionList__Description{
        flex:1 1 51%;
        padding:1.6em 0; }
        [data-has-navigation] .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description{
          border-top:0.1em solid #dfe3e8; } }
    @media (min-width: 36.875em){
      .Polaris-DescriptionList__Description{
        flex:1 1 51%;
        padding:1.6em 0; }
        .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description{
          border-top:0.1em solid #dfe3e8; } }


  .Polaris-DisplayText{
    margin:0; }

  .Polaris-DisplayText--sizeSmall{
    font-size:1.6em;
    font-weight:400;
    line-height:2.4em; }
    @media (min-width: 40em){
      .Polaris-DisplayText--sizeSmall{
        font-size:2em;
        line-height:2.8em; } }

  .Polaris-DisplayText--sizeMedium{
    font-size:2.1em;
    font-weight:400;
    line-height:2.8em; }
    @media (min-width: 40em){
      .Polaris-DisplayText--sizeMedium{
        font-size:2.6em;
        line-height:3.2em; } }

  .Polaris-DisplayText--sizeLarge{
    font-size:2.4em;
    font-weight:600;
    line-height:2.8em; }
    @media (min-width: 40em){
      .Polaris-DisplayText--sizeLarge{
        font-size:2.8em;
        line-height:3.2em; } }

  .Polaris-DisplayText--sizeExtraLarge{
    font-size:2.7em;
    font-weight:600;
    line-height:3.6em; }
    @media (min-width: 40em){
      .Polaris-DisplayText--sizeExtraLarge{
        font-size:4.2em;
        line-height:4.4em; } }


  .Polaris-DropZone-FileUpload{
    padding:1.5em;
    text-align:center; }

  .Polaris-DropZone-FileUpload__Button{
    position:relative;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    min-height:3.6em;
    min-width:3.6em;
    margin:0;
    padding:0.7em 1.6em;
    background:linear-gradient(to bottom, white, #f9fafb);
    border:0.1em solid #c4cdd5;
    box-shadow:0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    line-height:1;
    color:#212b36;
    text-align:center;
    cursor:pointer;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
    text-decoration:none;
    transition-property:background, border, box-shadow;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1);
    -webkit-tap-highlight-color:transparent; }
    .Polaris-DropZone-FileUpload__Button svg{
      fill:#637381; }
    .Polaris-DropZone-FileUpload__Button:hover{
      background:linear-gradient(to bottom, #f9fafb, #f4f6f8);
      border-color:#c4cdd5; }
    .Polaris-DropZone-FileUpload__Button:focus{
      border-color:#5c6ac4;
      outline:0;
      box-shadow:0 0 0 1px #5c6ac4; }
      @media (-ms-high-contrast: active){
        .Polaris-DropZone-FileUpload__Button:focus{
          outline:2px dotted; } }
    .Polaris-DropZone-FileUpload__Button:active{
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      border-color:#c4cdd5;
      box-shadow:0 0 0 0 transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2); }
    .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--disabled{
      transition:none;
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      color:#919eab;
      cursor:not-allowed;
      box-shadow:none; }
      .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--disabled svg{
        fill:#919eab; }
    .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--focused{
      border-color:#5c6ac4;
      outline:0;
      box-shadow:0 0 0 1px #5c6ac4; }
      @media (-ms-high-contrast: active){
        .Polaris-DropZone-FileUpload__Button.Polaris-DropZone-FileUpload--focused{
          outline:2px dotted; } }
    .Polaris-DropZone-FileUpload__Button .Polaris-DropZone-FileUpload--sizeSlim{
      min-height:3em;
      padding:0.4em 1.2em; }

  .Polaris-DropZone-FileUpload__ActionTitle{
    color:#006fbb;
    text-decoration:none; }
    .Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled){
      cursor:pointer; }
      .Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled):hover, .Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled):active{
        color:#084e8a;
        text-decoration:underline; }

  .Polaris-DropZone-FileUpload__ActionTitle--focused{
    color:#084e8a;
    text-decoration:underline; }

  .Polaris-DropZone-FileUpload__ActionTitle--disabled{
    color:#919eab; }

  .Polaris-DropZone-FileUpload__Image.Polaris-DropZone-FileUpload--sizeExtraLarge{
    width:90px; }

  .Polaris-DropZone-FileUpload__Image.Polaris-DropZone-FileUpload--sizeLarge{
    width:60px; }


  .Polaris-DropZone{
    position:relative;
    display:flex;
    justify-content:center;
    background-color:white;
    border-radius:3px; }
    .Polaris-DropZone::after{
      content:'';
      position:absolute;
      z-index:29;
      top:0;
      right:0;
      bottom:0;
      left:0;
      border:0.2em dashed transparent;
      border-radius:3px;
      pointer-events:none; }

  .Polaris-DropZone--hasOutline{
    padding:0.2em; }
    .Polaris-DropZone--hasOutline::after{
      border-color:#919eab; }
    .Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover{
      cursor:pointer;
      background-color:#f4f5fa; }
    .Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover::after{
      border-color:#5c6ac4; }

  .Polaris-DropZone--focused:not(.Polaris-DropZone--isDisabled){
    background-color:#f4f5fa; }
    .Polaris-DropZone--focused:not(.Polaris-DropZone--isDisabled)::after{
      border:0.2em dashed #5c6ac4; }
      @media (-ms-high-contrast: active){
        .Polaris-DropZone--focused:not(.Polaris-DropZone--isDisabled)::after{
          border-style:solid;
          border-color:highlight; } }

  .Polaris-DropZone--isDragging:not(.Polaris-DropZone--isDisabled){
    background-color:#f4f5fa; }
    .Polaris-DropZone--isDragging:not(.Polaris-DropZone--isDisabled)::after{
      border-color:#5c6ac4; }

  .Polaris-DropZone--isDisabled{
    cursor:not-allowed; }
    .Polaris-DropZone--isDisabled::after{
      border-color:#dfe3e8; }

  .Polaris-DropZone--sizeExtraLarge{
    min-height:20.5em; }

  .Polaris-DropZone--sizeLarge{
    min-height:16em; }

  .Polaris-DropZone--sizeMedium{
    min-height:10em;
    align-items:center; }

  .Polaris-DropZone--sizeSmall{
    padding:0;
    align-items:center;
    min-height:5em; }

  .Polaris-DropZone__Container{
    flex:1 1; }

  .Polaris-DropZone__Overlay{
    position:absolute;
    z-index:30;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:1.5em;
    border:0.2em dashed #5c6ac4;
    border-radius:3px;
    text-align:center;
    color:#5c6ac4;
    background-color:#f4f5fa; }
    .Polaris-DropZone--hasError .Polaris-DropZone__Overlay{
      border-color:#de3618;
      color:#de3618;
      background-color:#fbeae5; }
    .Polaris-DropZone--sizeSmall .Polaris-DropZone__Overlay{
      padding:0; }


  .Polaris-EmptySearchResult__Image{
    width:178px;
    height:158px;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none; }


  .Polaris-EmptyState{
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
    margin:2em auto 0 auto;
    padding:2em 0;
    max-width:99.8em; }

  @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
    [data-has-navigation] .Polaris-EmptyState--imageContained .Polaris-EmptyState__Image{
      position:initial;
      width:100%; } }

  @media (min-width: 46.5em){
    .Polaris-EmptyState--imageContained .Polaris-EmptyState__Image{
      position:initial;
      width:100%; } }

  .Polaris-EmptyState__Section{
    position:relative;
    display:flex;
    flex-direction:column;
    flex:1 1 auto;
    width:100%; }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-EmptyState__Section{
        left:2em;
        flex-direction:row;
        align-items:center; } }
    @media (min-width: 46.5em){
      .Polaris-EmptyState__Section{
        left:2em;
        flex-direction:row;
        align-items:center; } }

  .Polaris-EmptyState__ImageContainer,
  .Polaris-EmptyState__DetailsContainer{
    flex:1 1 auto;
    padding:0;
    margin:0; }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-EmptyState__ImageContainer, [data-has-navigation]
      .Polaris-EmptyState__DetailsContainer{
        flex-basis:50%; } }
    @media (min-width: 46.5em){
      .Polaris-EmptyState__ImageContainer,
      .Polaris-EmptyState__DetailsContainer{
        flex-basis:50%; } }
    @media (max-width: 30.625em), (min-width: 48.0625em) and (max-width: 47.125em){
      [data-has-navigation] .Polaris-EmptyState__ImageContainer, [data-has-navigation]
      .Polaris-EmptyState__DetailsContainer{
        overflow-x:hidden; } }
    @media (max-width: 30.625em){
      .Polaris-EmptyState__ImageContainer,
      .Polaris-EmptyState__DetailsContainer{
        overflow-x:hidden; } }

  .Polaris-EmptyState__Details{
    position:relative;
    z-index:10;
    padding:0 1.6em;
    width:33.6em; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-EmptyState__Details{
        padding:0; } }
    @media (min-width: 30.625em){
      .Polaris-EmptyState__Details{
        padding:0; } }

  .Polaris-EmptyState__Content{
    font-size:1.6em;
    font-weight:400;
    line-height:2.4em;
    color:#637381; }
    @media (min-width: 40em){
      .Polaris-EmptyState__Content{
        font-size:2em;
        line-height:2.8em; } }

  .Polaris-EmptyState__Actions{
    margin-top:1.6em; }

  .Polaris-EmptyState__Image{
    position:relative;
    z-index:0;
    margin-top:-2em;
    margin-left:-70%;
    width:190%;
    max-width:none; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-EmptyState__Image{
        margin-left:0;
        width:100%; } }
    @media (min-width: 30.625em){
      .Polaris-EmptyState__Image{
        margin-left:0;
        width:100%; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-EmptyState__Image{
        margin-top:0;
        margin-left:-70%;
        width:170%; } }
    @media (min-width: 46.5em){
      .Polaris-EmptyState__Image{
        margin-top:0;
        margin-left:-70%;
        width:170%; } }

  .Polaris-EmptyState__FooterContent{
    color:#637381;
    margin-top:1.6em; }


  .Polaris-Truncate{
    display:block;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis; }


  .Polaris-ExceptionList{
    margin:0;
    padding:0;
    list-style:none; }

  .Polaris-ExceptionList__Item{
    position:relative;
    padding-left:2.4em;
    color:#637381; }
    .Polaris-ExceptionList__Item + .Polaris-ExceptionList__Item{
      margin-top:0.4em; }

  .Polaris-ExceptionList__Icon{
    position:absolute;
    top:0;
    left:0;
    display:flex;
    align-items:center;
    justify-content:center;
    min-width:2em;
    height:2em;
    margin-right:0.4em; }
    .Polaris-ExceptionList__Icon svg{
      fill:#919eab; }
    .Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Icon svg{
      fill:#8a6116; }
    .Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Icon svg{
      fill:#bf0711; }

  .Polaris-ExceptionList__Bullet{
    width:0.6em;
    height:0.6em;
    border-radius:100%;
    background-color:#919eab; }
    .Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Bullet{
      background-color:#8a6116; }
    .Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Bullet{
      background-color:#bf0711; }

  .Polaris-ExceptionList__Title + .Polaris-ExceptionList__Description::before{
    content:'–';
    margin:0 0.4em; }

  .Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Title,
  .Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Title{
    font-weight:500; }

  .Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Title{
    color:#8a6116; }

  .Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Title{
    color:#bf0711; }


  .Polaris-Tag{
    display:inline-flex;
    max-width:100%;
    align-items:center;
    min-height:2.8em;
    padding-left:0.8em;
    background-color:#dfe3e8;
    border-radius:3px;
    color:#212b36; }
    .Polaris-Tag.Polaris-Tag--disabled{
      transition:none;
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      color:#919eab; }
      .Polaris-Tag.Polaris-Tag--disabled svg{
        fill:#919eab; }
    .Polaris-Tag .Polaris-Tag__TagText{
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap; }

  .Polaris-Tag__Button{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    display:block;
    height:2.8em;
    width:2.8em;
    margin-left:0.4em;
    border-radius:0 3px 3px 0; }
    .Polaris-Tag__Button svg{
      fill:#637381; }
    .Polaris-Tag__Button:focus{
      outline:none; }
    .Polaris-Tag__Button:hover, .Polaris-Tag__Button:focus, .Polaris-Tag__Button:active{
      background:#c4cdd5; }
      .Polaris-Tag__Button:hover svg, .Polaris-Tag__Button:focus svg, .Polaris-Tag__Button:active svg{
        fill:#212b36; }
    .Polaris-Tag__Button:disabled{
      cursor:default;
      pointer-events:none; }
      .Polaris-Tag__Button:disabled svg{
        fill:#919eab; }
    .Polaris-Tag__Button:active{
      background:rgba(145, 158, 171, 0.6); }


  .Polaris-Sheet{
    position:fixed;
    bottom:0;
    width:100%;
    height:100%;
    background-color:var(--p-surface-foreground, white);
    box-shadow:0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08); }
    @media screen and (-ms-high-contrast: active){
      .Polaris-Sheet{
        border-left:0.1em solid #dfe3e8; } }
    @media (min-width: 48.0625em){
      .Polaris-Sheet{
        right:0;
        width:38em; } }
    .Polaris-Sheet:focus{
      outline:0; }

  .Polaris-Sheet__Container{
    position:fixed;
    z-index:519;
    top:0;
    right:0;
    bottom:0;
    left:0; }
    @media (min-width: 48.0625em){
      .Polaris-Sheet__Container{
        left:auto;
        width:38em; } }

  .Polaris-Sheet__Bottom{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:transform;
    transition:transform 300ms cubic-bezier(0.64, 0, 0.35, 1);
    transform-origin:bottom; }

  .Polaris-Sheet--enterBottom{
    transform:translateY(100%); }

  .Polaris-Sheet--enterBottomActive{
    transform:translateY(0%); }

  .Polaris-Sheet--exitBottom{
    transform:translateY(0%); }

  .Polaris-Sheet--exitBottomActive{
    transform:translateY(100%); }

  .Polaris-Sheet__Right{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:transform;
    transition:transform 300ms cubic-bezier(0.64, 0, 0.35, 1);
    transform-origin:right; }

  .Polaris-Sheet--enterRight{
    transform:translateX(100%); }

  .Polaris-Sheet--enterRightActive{
    transform:translateX(0%); }

  .Polaris-Sheet--exitRight{
    transform:translateX(0%); }

  .Polaris-Sheet--exitRightActive{
    transform:translateX(100%); }


  .Polaris-Filters-ConnectedFilterControl__Item{
    position:relative;
    z-index:10; }

  .Polaris-Filters-ConnectedFilterControl__Item--focused{
    z-index:20; }

  .Polaris-Filters-ConnectedFilterControl__ProxyButtonContainer{
    position:absolute;
    display:flex;
    width:100%;
    height:0;
    visibility:hidden; }
    .Polaris-Filters-ConnectedFilterControl__ProxyButtonContainer > *{
      flex-shrink:0; }

  .Polaris-Filters-ConnectedFilterControl{
    display:flex;
    flex-grow:1; }
    .Polaris-Filters-ConnectedFilterControl .Polaris-Filters-ConnectedFilterControl__CenterContainer{
      flex:1 1 auto;
      min-width:10em; }
    .Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right .Polaris-Filters-ConnectedFilterControl__CenterContainer *{
      border-top-right-radius:0 !important;
      border-bottom-right-radius:0 !important; }

  .Polaris-Filters-ConnectedFilterControl__RightContainer{
    display:flex; }
    .Polaris-Filters-ConnectedFilterControl__RightContainer .Polaris-Filters-ConnectedFilterControl__Item > * > *{
      margin-left:-1px;
      border-radius:0 !important; }
    .Polaris-Filters-ConnectedFilterControl__RightContainer .Polaris-Filters-ConnectedFilterControl__Item{
      flex-shrink:0; }

  .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer *{
    border-top-left-radius:0 !important;
    border-bottom-left-radius:0 !important;
    flex-shrink:0;
    white-space:nowrap; }
    .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer * > div{
      margin-left:-1px; }

  .Polaris-Filters-ConnectedFilterControl__Wrapper{
    display:flex; }

  .Polaris-Filters-ConnectedFilterControl__AuxiliaryContainer{
    flex-grow:0; }


  .Polaris-Filters{
    position:relative; }

  .Polaris-Filters__FiltersContainer{
    position:relative;
    height:100%;
    width:100%;
    display:flex;
    flex-direction:column; }

  .Polaris-Filters__FiltersContainerHeader{
    top:0;
    width:100%;
    padding:1.6em 2em;
    border-bottom:0.1em solid #dfe3e8;
    height:5.6em;
    box-sizing:border-box;
    display:flex;
    align-items:center;
    justify-content:space-between; }

  .Polaris-Filters__FiltersDesktopContainerContent{
    width:100%;
    height:calc(100% - 12.6em); }

  .Polaris-Filters__FiltersMobileContainerContent{
    width:100%;
    height:calc(100% - 5.6em); }

  .Polaris-Filters__FiltersContainerFooter{
    position:absolute;
    bottom:0;
    width:100%;
    padding:1.4em 1.6em;
    border-top:0.1em solid #dfe3e8;
    height:7em;
    box-sizing:border-box;
    display:flex;
    align-items:center;
    justify-content:space-between; }

  .Polaris-Filters__FiltersMobileContainerFooter{
    width:100%;
    padding:1.4em 1.6em;
    height:7em;
    box-sizing:border-box;
    display:flex;
    align-items:center;
    justify-content:space-between; }

  .Polaris-Filters__EmptyFooterState{
    border-top:0.1em solid #dfe3e8;
    padding-top:1.4em;
    width:100%;
    display:flex;
    justify-content:center; }

  .Polaris-Filters__FilterTriggerContainer{
    position:relative; }

  .Polaris-Filters__FilterTrigger{
    width:100%;
    padding:1.4em 2em;
    border:none;
    background:none;
    color:#212b36; }
    .Polaris-Filters__FilterTrigger:hover{
      cursor:pointer;
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-Filters__FilterTrigger:focus{
      outline:none;
      box-shadow:inset 0.2em 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }

  .Polaris-Filters__FilterTriggerTitle{
    font-size:1.5em;
    font-weight:600; }

  .Polaris-Filters__AppliedFilterBadgeContainer{
    padding-top:0.4em;
    display:flex; }
    .Polaris-Filters--open .Polaris-Filters__AppliedFilterBadgeContainer{
      display:none; }

  .Polaris-Filters__FilterTriggerLabelContainer{
    display:flex;
    align-items:center;
    justify-content:space-between; }

  .Polaris-Filters--open::before, .Polaris-Filters--open::after{
    content:'';
    position:relative;
    left:1.6em;
    width:calc(100% - 3.2em);
    height:0.1em;
    background-color:#dfe3e8;
    display:block; }

  .Polaris-Filters--open::before{
    top:0; }

  .Polaris-Filters--open::after{
    bottom:0; }

  .Polaris-Filters--open.Polaris-Filters--first::after{
    content:'';
    bottom:0;
    position:relative;
    left:1.6em;
    width:calc(100% - 3.2em);
    height:0.1em;
    background-color:#dfe3e8;
    display:block; }

  .Polaris-Filters--open.Polaris-Filters--first::before{
    display:none; }

  .Polaris-Filters--open ~ .Polaris-Filters--open::before{
    display:none; }

  .Polaris-Filters--open.Polaris-Filters--last::before{
    content:'';
    top:0;
    position:relative;
    left:1.6em;
    width:calc(100% - 3.2em);
    height:0.1em;
    background-color:#dfe3e8;
    display:block; }

  .Polaris-Filters--open.Polaris-Filters--last::after{
    display:none; }

  .Polaris-Filters--open + .Polaris-Filters--last::before{
    display:none; }

  .Polaris-Filters__FilterNodeContainer{
    padding:0.8em 2em 2em 2em; }

  .Polaris-Filters__SearchIcon{
    fill:currentColor; }

  .Polaris-Filters__Backdrop{
    position:fixed;
    z-index:518;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display:block;
    opacity:0; }

  .Polaris-Filters__TagsContainer{
    display:flex;
    padding-top:0.8em;
    flex-wrap:wrap; }
    .Polaris-Filters__TagsContainer > *{
      margin-right:0.8em;
      margin-bottom:0.8em; }


  .Polaris-FooterHelp{
    display:flex;
    justify-content:center;
    margin:2em 0;
    width:100%; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-FooterHelp{
        margin:2em;
        width:auto; } }
    @media (min-width: 30.625em){
      .Polaris-FooterHelp{
        margin:2em;
        width:auto; } }

  .Polaris-FooterHelp__Content{
    display:inline-flex;
    align-items:center;
    padding:1.6em 2em 1.6em 1.6em;
    border-top:0.1em solid #dfe3e8;
    border-bottom:0.1em solid #dfe3e8;
    width:100%;
    justify-content:center; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-FooterHelp__Content{
        width:auto;
        border:0.1em solid #dfe3e8;
        border-radius:999px; } }
    @media (min-width: 30.625em){
      .Polaris-FooterHelp__Content{
        width:auto;
        border:0.1em solid #dfe3e8;
        border-radius:999px; } }

  .Polaris-FooterHelp__Icon{
    margin-right:0.8em; }

  .Polaris-FooterHelp__Text{
    font-size:1.6em;
    font-weight:400;
    line-height:2.4em;
    text-transform:initial;
    letter-spacing:initial; }
    @media (min-width: 40em){
      .Polaris-FooterHelp__Text{
        font-size:1.4em; } }


  .Polaris-FormLayout{
    margin-top:-1.6em;
    margin-left:-2em; }

  .Polaris-FormLayout__Title{
    margin-bottom:-0.8em;
    padding:1.6em 2em 0; }

  .Polaris-FormLayout__Items{
    display:flex;
    flex-wrap:wrap; }

  .Polaris-FormLayout__HelpText{
    color:#637381;
    padding:0.8em 2em 0; }

  .Polaris-FormLayout__Item{
    flex:1 1 22em;
    margin-top:1.6em;
    margin-left:2em;
    max-width:calc(100% - 2em); }
    .Polaris-FormLayout--grouped .Polaris-FormLayout__Item{
      min-width:22em; }
    .Polaris-FormLayout--condensed .Polaris-FormLayout__Item{
      flex-basis:11em;
      min-width:11em; }


  .Polaris-Frame-Toast{
    font-size:1.6em;
    font-weight:400;
    line-height:2.4em;
    display:inline-flex;
    max-width:50em;
    padding:0.8em 1.6em;
    border-radius:3px;
    background:rgba(0, 0, 0, 0.88);
    box-shadow:0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    color:white;
    margin-bottom:2em; }
    @media (min-width: 40em){
      .Polaris-Frame-Toast{
        font-size:2em;
        line-height:2.8em; } }
    @media (min-width: 40em){
      .Polaris-Frame-Toast{
        padding:1.6em; } }
    @media (-ms-high-contrast: active){
      .Polaris-Frame-Toast{
        border:0.2em solid windowText; } }

  .Polaris-Frame-Toast__Action{
    margin-left:3.2em;
    margin-right:1.6em; }

  .Polaris-Frame-Toast--error{
    background:rgba(191, 7, 17, 0.88); }

  .Polaris-Frame-Toast__CloseButton{
    display:flex;
    align-self:flex-start;
    flex-direction:column;
    justify-content:flex-start;
    margin:-0.8em -1.6em -0.8em 0;
    padding:1.2em 1.6em 0.8em;
    border:none;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    background:transparent;
    fill:white;
    cursor:pointer; }
    .Polaris-Frame-Toast__CloseButton:focus{
      outline:none; }


  :root{
    --toast-translate-y-out:15em;
    --toast-translate-y-in:0; }

  .Polaris-Frame-ToastManager{
    position:fixed;
    z-index:520;
    right:0;
    left:0;
    text-align:center;
    bottom:var(--global-ribbon-height, 0);
    display:flex;
    flex-direction:column;
    align-items:center; }

  .Polaris-Frame-ToastManager__ToastWrapper{
    position:absolute;
    display:inline-flex;
    opacity:0;
    transition:transform 400ms ease, opacity 400ms ease;
    transform:translateY(var(--toast-translate-y-out)); }

  .Polaris-Frame-ToastManager__ToastWrapper--enter,
  .Polaris-Frame-ToastManager__ToastWrapper--exit{
    transform:translateY(var(--toast-translate-y-out));
    opacity:0; }

  .Polaris-Frame-ToastManager--toastWrapperEnterDone{
    transform:translateY(var(--toast-translate-y-in));
    opacity:1; }


  .Polaris-Frame-Loading{
    overflow:hidden;
    height:0.3em;
    background-color:#dfe3e8;
    opacity:1; }

  .Polaris-Frame-Loading__Level{
    width:100%;
    height:100%;
    transform-origin:0;
    background-color:#47c1bf;
    transition:transform 500ms cubic-bezier(0, 0, 0.42, 1); }
    @media screen and (-ms-high-contrast: active){
      .Polaris-Frame-Loading__Level{
        background-color:highlight; } }


  .Polaris-Modal-Dialog__Container{
    position:fixed;
    z-index:519;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display:flex;
    flex-direction:column;
    justify-content:flex-end; }
    @media (min-width: 48.0625em){
      .Polaris-Modal-Dialog__Container{
        justify-content:center; } }

  .Polaris-Modal-Dialog__Modal{
    position:fixed;
    right:0;
    bottom:0;
    left:0;
    display:flex;
    flex-direction:column;
    width:100vw;
    max-height:calc(100vh - 60px);
    background:var(--p-surface-foreground, white);
    box-shadow:0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08); }
    @media (-ms-high-contrast: active){
      .Polaris-Modal-Dialog__Modal{
        border:1px solid windowText; } }
    @media (max-width: 48.0525em){
      .Polaris-Modal-Dialog__Modal{
        bottom:0;
        max-height:100%; } }
    .Polaris-Modal-Dialog__Modal:focus{
      outline:0; }
    @media (min-width: 48.0625em){
      .Polaris-Modal-Dialog__Modal{
        position:relative;
        max-width:62em;
        margin:0 auto;
        border-radius:6px; } }

  @media (min-width: 48.0625em) and (min-height: 660px){
    .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--limitHeight{
      max-height:600px; } }
    @media (min-width: 48.0625em){
      .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge{
        max-width:calc(100vw - 6.4em); } }
    @media (min-width: 65.25em){
      .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge{
        max-width:98em; } }

  .Polaris-Modal-Dialog--animateFadeUp{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:transform, opacity;
    opacity:1;
    transform:translateY(0);
    transition:transform cubic-bezier(0.64, 0, 0.35, 1) 200ms, opacity cubic-bezier(0.64, 0, 0.35, 1) 200ms; }
    .Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--entering, .Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--exiting, .Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--exited{
      opacity:0;
      transform:translateY(20em); }
    .Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--entered{
      opacity:1;
      transform:translateY(0); }


  .Polaris-Modal-Footer{
    display:flex;
    align-self:flex-end;
    align-items:center;
    width:100%;
    min-height:6.4em;
    padding:1.6em;
    border-top:var(--p-border-subdued, 0.1em solid #dfe3e8); }

  .Polaris-Modal-Footer__FooterContent{
    width:100%; }


  .Polaris-Modal-CloseButton{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    margin-left:2em;
    margin-right:-0.8em;
    padding:0.8em; }
    .Polaris-Modal-CloseButton:focus{
      outline:none; }
    .Polaris-Modal-CloseButton:focus, .Polaris-Modal-CloseButton:active{
      border-radius:6px;
      background:rgba(33, 43, 54, 0.1); }

  .Polaris-Modal-CloseButton--withoutTitle{
    position:absolute;
    right:0.8em; }


  .Polaris-Modal-Header{
    display:flex;
    align-items:flex-start;
    flex-shrink:0;
    padding:1.6em 2em;
    border-bottom:var(--p-border-subdued, 0.1em solid #dfe3e8); }

  .Polaris-Modal-Header__Title{
    word-wrap:break-word;
    word-break:break-word;
    overflow-wrap:break-word;
    flex:1 1;
    margin-top:0.4em; }


  .Polaris-Modal-Section{
    flex:0 0 auto;
    padding:2em; }
    .Polaris-Modal-Section:not(:last-of-type){
      border-bottom:0.1em solid #dfe3e8; }
    .Polaris-Modal-Section.Polaris-Modal-Section--subdued{
      background:#f4f6f8; }
    .Polaris-Modal-Section.Polaris-Modal-Section--flush{
      padding:0; }


  .Polaris-Modal__BodyWrapper{
    display:flex;
    flex-grow:1;
    overflow-x:hidden;
    -webkit-overflow-scrolling:touch; }

  .Polaris-Modal__Body{
    width:100%; }

  .Polaris-Modal__IFrame{
    display:block;
    width:62em;
    max-width:100vw;
    border:none; }
    @media (min-width: 48.0625em){
      .Polaris-Modal__IFrame{
        max-width:62em; } }

  .Polaris-Modal__Spinner{
    margin:1.6em;
    text-align:center; }


  .Polaris-Frame-ContextualSaveBar{
    display:flex;
    height:5.6em;
    background-color:white;
    box-shadow:0 2px 4px rgba(0, 0, 0, 0.1); }
    .Polaris-Frame-ContextualSaveBar::after{
      content:'';
      position:absolute;
      bottom:0;
      width:100%;
      border-bottom:1px solid transparent; }

  .Polaris-Frame-ContextualSaveBar__LogoContainer{
    display:none; }
    @media (min-width: 48.0625em){
      .Polaris-Frame-ContextualSaveBar__LogoContainer{
        display:flex;
        flex:0 0 24em;
        align-items:center;
        height:100%;
        padding:0 1.6em;
        border-right:1px solid #ebeef0;
        background-color:#fafbfc; } }

  .Polaris-Frame-ContextualSaveBar__Contents{
    display:flex;
    flex:1 1 auto;
    align-items:center;
    justify-content:space-between;
    min-width:1px;
    max-width:99.8em;
    height:100%;
    margin:0 auto;
    padding:0 1.6em; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Frame-ContextualSaveBar__Contents{
        padding:0 2em; } }
    @media (min-width: 30.625em){
      .Polaris-Frame-ContextualSaveBar__Contents{
        padding:0 2em; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-Frame-ContextualSaveBar__Contents{
        padding:0 3.2em; } }
    @media (min-width: 46.5em){
      .Polaris-Frame-ContextualSaveBar__Contents{
        padding:0 3.2em; } }

  .Polaris-Frame-ContextualSaveBar__Message{
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    font-size:1.7em;
    font-weight:600;
    line-height:2.4em;
    color:#637381; }
    @media (min-width: 40em){
      .Polaris-Frame-ContextualSaveBar__Message{
        font-size:1.6em; } }

  .Polaris-Frame-ContextualSaveBar__ActionContainer{
    flex-shrink:0; }

  .Polaris-Frame-ContextualSaveBar__Action{
    margin-left:0.8em; }


  .Polaris-Frame-CSSAnimation--startFade{
    opacity:0;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity;
    transition:opacity 300ms cubic-bezier(0, 0, 0.42, 1);
    pointer-events:none; }

  .Polaris-Frame-CSSAnimation--endFade{
    opacity:1;
    pointer-events:auto; }


  .Polaris-Frame{
    width:100%;
    min-height:100vh;
    display:flex;
    background-color:var(--p-surface-background, #f4f6f8); }
    @media print{
      .Polaris-Frame{
        background-color:none; } }

  .Polaris-Frame__Navigation{
    position:fixed;
    z-index:516;
    top:0;
    left:0;
    display:none;
    flex:0 0 auto;
    align-items:stretch;
    height:100%;
    outline:none;
    transform:translateX(0%); }
    @media print{
      .Polaris-Frame__Navigation{
        display:none !important; } }
    @media (min-width: 48.0625em){
      .Polaris-Frame__Navigation{
        z-index:1;
        top:5.6em;
        display:flex;
        height:calc(100% - 5.6em); } }
    .Polaris-Frame__Navigation:focus{
      outline:none; }

  .Polaris-Frame__Navigation--enter,
  .Polaris-Frame__Navigation--enterActive,
  .Polaris-Frame__Navigation--exit,
  .Polaris-Frame__Navigation--exitActive{
    display:flex; }

  .Polaris-Frame__Navigation--enter{
    transform:translateX(-100%); }

  .Polaris-Frame__Navigation--enterActive{
    transform:translateX(0%);
    transition:transform 300ms cubic-bezier(0, 0, 0.42, 1); }

  .Polaris-Frame__Navigation--exit{
    transform:translateX(0%); }

  .Polaris-Frame__Navigation--exitActive{
    transform:translateX(-100%);
    transition:transform 300ms cubic-bezier(0, 0, 0.42, 1); }

  .Polaris-Frame__NavigationDismiss{
    position:absolute;
    top:0;
    left:100%;
    width:3.2em;
    height:3.2em;
    margin:1.6em;
    padding:0;
    border:none;
    background:none;
    opacity:0;
    pointer-events:none;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity;
    cursor:pointer;
    transition:opacity 100ms cubic-bezier(0.64, 0, 0.35, 1); }
    @media print{
      .Polaris-Frame__NavigationDismiss{
        display:none !important; } }
    .Polaris-Frame__Navigation--visible .Polaris-Frame__NavigationDismiss{
      pointer-events:all;
      opacity:1; }
    .Polaris-Frame__NavigationDismiss:focus{
      border-radius:3px;
      background-color:rgba(255, 255, 255, 0.16);
      outline:none; }
    @media (min-width: 48.0625em){
      .Polaris-Frame__NavigationDismiss{
        display:none; } }

  .Polaris-Frame__TopBar{
    position:fixed;
    z-index:512;
    top:0;
    left:0;
    width:100%; }
    @media print{
      .Polaris-Frame__TopBar{
        display:none !important; } }

  .Polaris-Frame__ContextualSaveBar{
    position:fixed;
    z-index:513;
    top:0;
    left:0;
    width:100%; }

  .Polaris-Frame__Main{
    flex:1 1;
    display:flex;
    align-items:stretch;
    min-width:0;
    max-width:100%;
    padding-right:0px;
    padding-right:calc(0px + constant(safe-area-inset-right));
    padding-right:calc(0px + env(safe-area-inset-right));
    padding-left:0px;
    padding-left:calc(0px + constant(safe-area-inset-left));
    padding-left:calc(0px + env(safe-area-inset-left));
    padding-bottom:0px;
    padding-bottom:calc(0px + constant(safe-area-inset-bottom));
    padding-bottom:calc(0px + env(safe-area-inset-bottom)); }
    @media (min-width: 48.0625em){
      .Polaris-Frame--hasNav .Polaris-Frame__Main{
        padding-left:24em;
        padding-left:24em;
        padding-left:calc(24em + constant(safe-area-inset-left));
        padding-left:calc(24em + env(safe-area-inset-left)); } }
    @media print and (min-width: 48.0625em){
      .Polaris-Frame--hasNav .Polaris-Frame__Main{
        padding-left:0; } }
    .Polaris-Frame--hasTopBar .Polaris-Frame__Main{
      padding-top:5.6em; }
      @media print{
        .Polaris-Frame--hasTopBar .Polaris-Frame__Main{
          padding-top:0; } }

  .Polaris-Frame__Content{
    padding-bottom:var(--global-ribbon-height, 0);
    flex:1 1;
    min-width:0;
    max-width:100%; }

  .Polaris-Frame__GlobalRibbonContainer{
    position:fixed;
    z-index:510;
    bottom:0;
    width:100%; }
    @media (min-width: 48.0625em){
      .Polaris-Frame--hasNav .Polaris-Frame__GlobalRibbonContainer{
        left:24em;
        left:24em;
        left:calc(24em + constant(safe-area-inset-left));
        left:calc(24em + env(safe-area-inset-left));
        width:calc(100% - 24em); } }

  .Polaris-Frame__LoadingBar{
    position:fixed;
    z-index:514;
    top:0;
    right:0;
    left:0; }
    @media print{
      .Polaris-Frame__LoadingBar{
        display:none !important; } }
    @media (min-width: 48.0625em){
      .Polaris-Frame--hasTopBar .Polaris-Frame__LoadingBar{
        top:5.6em;
        z-index:511; } }

  .Polaris-Frame__Skip{
    position:fixed;
    z-index:517;
    top:1em;
    left:0.8em;
    opacity:0;
    pointer-events:none; }
    .Polaris-Frame__Skip.Polaris-Frame--focused{
      pointer-events:all;
      opacity:1; }

  .Polaris-Frame__SkipAnchor{
    position:relative;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    min-height:3.6em;
    min-width:3.6em;
    margin:0;
    padding:0.7em 1.6em;
    background:linear-gradient(to bottom, white, #f9fafb);
    border:0.1em solid #c4cdd5;
    box-shadow:0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    line-height:1;
    color:#212b36;
    text-align:center;
    cursor:pointer;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
    text-decoration:none;
    transition-property:background, border, box-shadow;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1);
    -webkit-tap-highlight-color:transparent; }
    .Polaris-Frame__SkipAnchor svg{
      fill:#637381; }
    .Polaris-Frame__SkipAnchor:hover{
      background:linear-gradient(to bottom, #f9fafb, #f4f6f8);
      border-color:#c4cdd5; }
    .Polaris-Frame__SkipAnchor:focus{
      border-color:#5c6ac4;
      outline:0;
      box-shadow:0 0 0 1px #5c6ac4; }
      @media (-ms-high-contrast: active){
        .Polaris-Frame__SkipAnchor:focus{
          outline:2px dotted; } }
    .Polaris-Frame__SkipAnchor:active{
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      border-color:#c4cdd5;
      box-shadow:0 0 0 0 transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2); }


  .Polaris-Indicator::before, .Polaris-Indicator::after{
    content:'';
    position:absolute;
    background-color:#47c1bf;
    right:-0.4em;
    top:-0.4em;
    width:1em;
    height:1em;
    border-radius:100%; }

  .Polaris-Indicator--pulseIndicator::before{
    z-index:1;
    animation:Polaris-Indicator--bounce 5s ease infinite; }

  .Polaris-Indicator--pulseIndicator::after{
    right:-0.4em;
    top:-0.4em;
    animation:Polaris-Indicator--pulse 5s ease infinite; }

  @keyframes Polaris-Indicator--bounce{
    from,
    65%,
    85%{
      transform:scale(1); }
    75%{
      transform:scale(0.85); }
    82.5%{
      transform:scale(1.05); } }

  @keyframes Polaris-Indicator--pulse{
    from,
    75%{
      transform:scale(0.85);
      opacity:1; }
    to{
      transform:scale(2.5);
      opacity:0; } }


  .Polaris-KeyboardKey{
    display:inline-block;
    height:2.4em;
    margin:0 0.2em 2px;
    padding:0 0.4em;
    background-color:white;
    box-shadow:0 0 0 1px #c4cdd5, 0 2px 0 0 white, 0 2px 0 1px #c4cdd5;
    border-radius:3px;
    font-family:-apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif;
    font-size:1.2em;
    font-weight:500;
    line-height:2.4em;
    color:#637381;
    text-align:center;
    min-width:2.4em;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none; }


  .Polaris-Layout{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:flex-start;
    margin-top:-2em;
    margin-left:-2em; }

  .Polaris-Layout__Section{
    flex:2 2 48em;
    min-width:51%; }

  .Polaris-Layout__Section--secondary{
    flex:1 1 24em;
    min-width:0; }

  .Polaris-Layout__Section--fullWidth{
    flex:1 1 100%; }

  .Polaris-Layout__Section--oneHalf{
    flex:1 1 45em;
    min-width:0; }

  .Polaris-Layout__Section--oneThird{
    flex:1 1 24em;
    min-width:0; }

  .Polaris-Layout__AnnotatedSection{
    min-width:0;
    flex:1 1 100%; }

  .Polaris-Layout__Section,
  .Polaris-Layout__AnnotatedSection{
    max-width:calc(100% - 2em);
    margin-top:2em;
    margin-left:2em; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Layout__Section + .Polaris-Layout__AnnotatedSection, [data-has-navigation]
      .Polaris-Layout__AnnotatedSection + .Polaris-Layout__AnnotatedSection{
        padding-top:2em;
        border-top:0.1em solid #dfe3e8; } }
    @media (min-width: 30.625em){
      .Polaris-Layout__Section + .Polaris-Layout__AnnotatedSection,
      .Polaris-Layout__AnnotatedSection + .Polaris-Layout__AnnotatedSection{
        padding-top:2em;
        border-top:0.1em solid #dfe3e8; } }

  .Polaris-Layout__AnnotationWrapper{
    display:flex;
    flex-wrap:wrap;
    margin-top:-2em;
    margin-left:-2em; }

  .Polaris-Layout__AnnotationContent{
    flex:2 2 48em; }

  .Polaris-Layout__Annotation{
    flex:1 1 24em;
    padding:1.6em 2em 0; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Layout__Annotation{
        padding:1.6em 0 0; } }
    @media (min-width: 30.625em){
      .Polaris-Layout__Annotation{
        padding:1.6em 0 0; } }
    @media (max-width: 48.0625em) and (min-width: 50em), (min-width: 65em){
      [data-has-navigation] .Polaris-Layout__Annotation{
        padding:2em 2em 2em 0; } }
    @media (min-width: 50em){
      .Polaris-Layout__Annotation{
        padding:2em 2em 2em 0; } }

  .Polaris-Layout__Annotation,
  .Polaris-Layout__AnnotationContent{
    min-width:0;
    max-width:calc(100% - 2em);
    margin-top:2em;
    margin-left:2em; }

  .Polaris-Layout__AnnotationDescription{
    color:#637381; }


  .Polaris-Link{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    display:inline;
    text-align:inherit;
    padding:0;
    background:none;
    border:0;
    font-size:inherit;
    color:var(--p-interactive-action, #006fbb);
    text-decoration:none;
    cursor:pointer; }
    .Polaris-Link:hover{
      color:var(--p-interactive-action-hovered, #084e8a);
      text-decoration:underline; }
    .Polaris-Link:focus{
      -webkit-text-decoration:var(--p-override-none, underline);
              text-decoration:var(--p-override-none, underline);
      outline:var(--p-override-none, rgba(0, 103, 244, 0.247) auto 0.45em); }
    .Polaris-Link:focus:not(:active){
      position:relative; }
      .Polaris-Link:focus:not(:active)::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.3em;
        right:-0.3em;
        bottom:-0.3em;
        left:-0.3em;
        display:block;
        border:0.2em solid var(--p-interactive-focus);
        border-radius:calc(var(--p-border-radius-base) + 0.3em);
        pointer-events:none; }
    .Polaris-Link:active{
      position:relative;
      color:var(--p-interactive-action-pressed, #084e8a);
      -webkit-text-decoration:var(--p-override-none, underline);
              text-decoration:var(--p-override-none, underline); }
      .Polaris-Link:active::before{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:-1;
        top:-0.2em;
        right:-0.5em;
        bottom:-0.2em;
        left:-0.5em;
        display:block;
        border-radius:3px;
        background:var(--p-neutral-action, none); }

  .Polaris-Link__IconLockup{
    display:inline;
    white-space:nowrap; }
    .Polaris-Link__IconLockup svg{
      fill:currentColor; }
    .Polaris-Link__IconLockup::before{
      content:'\2060'; }

  .Polaris-Link__IconLayout{
    display:inline-flex; }
    .Polaris-Link__IconLayout::before{
      content:'\2060'; }

  .Polaris-Link--monochrome{
    color:inherit;
    text-decoration:underline; }
    .Polaris-Link--monochrome:hover, .Polaris-Link--monochrome:focus, .Polaris-Link--monochrome:active{
      color:inherit; }


  .Polaris-List{
    padding-left:2em;
    margin-top:0;
    margin-bottom:0;
    list-style:disc outside none; }
    .Polaris-List + .Polaris-List{
      margin-top:1.6em; }

  .Polaris-List--typeNumber{
    padding-left:3.2em;
    list-style:decimal outside none; }

  .Polaris-List__Item{
    margin-bottom:0.8em; }
    .Polaris-List__Item:last-child{
      margin-bottom:0; }
    .Polaris-List__Item .Polaris-List:first-child{
      margin-top:0.8em; }


  @keyframes Polaris-Navigation__fade--in{
    0%{
      opacity:0; }
    100%{
      opacity:1; } }

  .Polaris-Navigation{
    display:flex;
    flex-direction:column;
    align-items:stretch;
    width:calc(100vw - 6.4em);
    min-width:24em;
    max-width:36em;
    height:100%;
    min-height:100%;
    background-color:var(--p-surface-foreground, #f4f6f8);
    -webkit-overflow-scrolling:touch;
    padding-bottom:0px;
    padding-bottom:calc(0px + constant(safe-area-inset-bottom));
    padding-bottom:calc(0px + env(safe-area-inset-bottom)); }
    .Polaris-Navigation:focus{
      outline:none; }
    @media (min-width: 48.0625em){
      .Polaris-Navigation{
        max-width:24em;
        border-right:var(--p-override-none, 0.1em solid #dfe3e8);
        max-width:24em;
        max-width:calc(24em + constant(safe-area-inset-left));
        max-width:calc(24em + env(safe-area-inset-left)); } }

  .Polaris-Navigation__UserMenu{
    flex:0 0 auto; }

  @media (min-width: 48.0625em){
    .Polaris-Navigation__ContextControl{
      display:none; } }

  .Polaris-Navigation__PrimaryNavigation{
    display:flex;
    overflow:auto;
    flex:1 1 auto;
    flex-direction:column;
    align-items:stretch;
    max-width:100%; }

  .Polaris-Navigation__Item{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    font-size:1.6em;
    font-weight:400;
    line-height:4em;
    display:flex;
    flex-grow:1;
    align-items:flex-start;
    max-width:100%;
    padding:0 0.8em;
    margin:0 0.8em;
    border-radius:var(--p-border-radius-base, 3px);
    color:var(--p-text-on-surface, #212b36);
    text-decoration:none;
    text-align:left; }
    .Polaris-Navigation__Item:focus{
      outline:none; }
    .Polaris-Navigation__Item:hover{
      background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
      background-color:var(--p-surface-hovered);
      color:var(--p-branded-action, #202e78);
      text-decoration:none; }
      .Polaris-Navigation__Item:hover .Polaris-Navigation__Icon svg{
        fill:var(--p-branded-action, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__Item:hover .Polaris-Navigation__Icon img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__Item:focus{
      position:relative;
      background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
      color:var(--p-text-on-surface, #202e78);
      text-decoration:none; }
      .Polaris-Navigation__Item:focus::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.3em;
        right:-0.3em;
        bottom:-0.3em;
        left:-0.3em;
        display:block;
        border:0.2em solid var(--p-interactive-focus);
        border-radius:calc(var(--p-border-radius-base) + 0.3em);
        pointer-events:none; }
      .Polaris-Navigation__Item:focus .Polaris-Navigation__Icon svg{
        fill:var(--p-icon-on-surface, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__Item:focus .Polaris-Navigation__Icon img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__Item:focus:hover{
      color:var(--p-branded-action, #202e78); }
      .Polaris-Navigation__Item:focus:hover .Polaris-Navigation__Icon svg{
        fill:var(--p-branded-action, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__Item:focus:hover .Polaris-Navigation__Icon img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__Item:active, .Polaris-Navigation__Item:active:hover{
      color:var(--p-branded-action-pressed, #202e78); }
      .Polaris-Navigation__Item:active::after, .Polaris-Navigation__Item:active:hover::after{
        border:none; }
      .Polaris-Navigation__Item:active .Polaris-Navigation__Icon svg, .Polaris-Navigation__Item:active:hover .Polaris-Navigation__Icon svg{
        fill:var(--p-branded-action-pressed, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__Item:active .Polaris-Navigation__Icon img, .Polaris-Navigation__Item:active:hover .Polaris-Navigation__Icon img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    @media (min-width: 48.0625em){
      .Polaris-Navigation__Item{
        font-size:1.4em;
        font-weight:500;
        line-height:3.2em; } }
    .Polaris-Navigation__Item::-moz-focus-inner{
      border:0; }

  .Polaris-Navigation__Item--selected{
    font-weight:600;
    color:var(--p-branded-action, #202e78);
    background-color:var(--p-branded-selected, rgba(92, 106, 196, 0.12)); }
    @media (-ms-high-contrast: active){
      .Polaris-Navigation__Item--selected{
        background-color:highlight; } }
    .Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg,
    .Polaris-Navigation__Item--selected:focus .Polaris-Navigation__Icon svg{
      fill:var(--p-branded-action, #5c6ac4);
      color:var(--p-override-transparent, white); }
    .Polaris-Navigation__Item--selected:hover{
      background-color:var(--p-branded-selected-hovered, rgba(92, 106, 196, 0.12));
      color:var(--p-branded-action-hovered, #202e78); }
    .Polaris-Navigation__Item--selected:focus{
      color:var(--p-branded-action, #202e78);
      position:relative; }
      .Polaris-Navigation__Item--selected:focus::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.3em;
        right:-0.3em;
        bottom:-0.3em;
        left:-0.3em;
        display:block;
        border:0.2em solid var(--p-interactive-focus);
        border-radius:calc(var(--p-border-radius-base) + 0.3em);
        pointer-events:none; }
    .Polaris-Navigation__Item--selected:active{
      background-color:var(--p-branded-selected-pressed, rgba(92, 106, 196, 0.12));
      color:var(--p-branded-action-pressed, #202e78); }
      .Polaris-Navigation__Item--selected:active::after{
        border:none; }

  .Polaris-Navigation__Item--disabled{
    color:var(--p-text-disabled-on-surface, #919eab);
    pointer-events:none;
    opacity:var(--p-override-one, 0.6); }
    .Polaris-Navigation__Item--disabled .Polaris-Navigation__Icon svg{
      fill:var(--p-icon-disabled-on-surface, #919eab);
      color:var(--p-override-transparent, white); }

  .Polaris-Navigation__Badge{
    margin-left:0.8em;
    display:inline-flex;
    margin-top:1.2em; }
    @media (min-width: 48.0625em){
      .Polaris-Navigation__Badge{
        margin-top:0.8em; } }

  .Polaris-Navigation__Icon{
    flex-shrink:0;
    align-self:flex-start;
    width:2em;
    height:2em;
    margin-top:1em;
    margin-right:2em;
    margin-bottom:1em; }
    .Polaris-Navigation__Icon svg{
      fill:var(--p-icon-on-surface, #919eab);
      color:var(--p-override-transparent, white); }
    .Polaris-Navigation__Icon img{
      -webkit-filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%);
              filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%); }
    @media (min-width: 48.0625em){
      .Polaris-Navigation__Icon{
        margin-top:0.6em;
        margin-right:1.6em;
        margin-bottom:0.6em; } }
    .Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg,
    .Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon svg,
    .Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon svg,
    .Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon svg,
    .Polaris-Navigation--subNavigationActive:focus .Polaris-Navigation__Icon svg{
      fill:var(--p-branded-action, #5c6ac4);
      color:var(--p-override-transparent, white); }
    .Polaris-Navigation__Item--selected .Polaris-Navigation__Icon img,
    .Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon img,
    .Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon img,
    .Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon img,
    .Polaris-Navigation--subNavigationActive:focus .Polaris-Navigation__Icon img{
      -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
              filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__Icon svg{
      display:block; }

  .Polaris-Navigation__ListItem{
    position:relative;
    display:flex;
    flex-wrap:wrap; }
    .Polaris-Navigation__RollupSection .Polaris-Navigation__ListItem,
    .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__ListItem{
      opacity:0;
      animation:Polaris-Navigation__fade--in 500ms 1 forwards; }
    .Polaris-Navigation__ListItem:nth-child(1){
      animation-delay:0ms; }
    .Polaris-Navigation__ListItem:nth-child(2){
      animation-delay:50ms; }
    .Polaris-Navigation__ListItem:nth-child(3){
      animation-delay:100ms; }
    .Polaris-Navigation__ListItem:nth-child(4){
      animation-delay:150ms; }
    .Polaris-Navigation__ListItem:nth-child(5){
      animation-delay:200ms; }
    .Polaris-Navigation__ListItem:nth-child(6){
      animation-delay:250ms; }
    .Polaris-Navigation__ListItem:nth-child(7){
      animation-delay:300ms; }
    .Polaris-Navigation__ListItem:nth-child(8){
      animation-delay:350ms; }
    .Polaris-Navigation__ListItem:nth-child(9){
      animation-delay:400ms; }
    .Polaris-Navigation__ListItem:nth-child(10){
      animation-delay:450ms; }
    .Polaris-Navigation__ListItem:nth-child(11){
      animation-delay:500ms; }
    .Polaris-Navigation__ListItem:nth-child(12){
      animation-delay:550ms; }

  .Polaris-Navigation__ListItem--hasAction .Polaris-Navigation__Item{
    max-width:calc(100% - 5.6em); }

  .Polaris-Navigation__ItemWrapper{
    display:flex;
    flex-wrap:nowrap;
    width:100%; }

  .Polaris-Navigation__Text{
    flex:1 1 auto;
    margin-top:1em;
    margin-bottom:1em;
    line-height:2em; }
    @media (min-width: 48.0625em){
      .Polaris-Navigation__Text{
        margin-top:0.6em;
        margin-bottom:0.6em; } }

  .Polaris-Navigation__SecondaryAction{
    display:flex;
    align-items:center;
    height:4em;
    margin-right:0.4em;
    padding:0.4em 1.6em;
    border-radius:var(--p-border-radius-base, 3px); }
    .Polaris-Navigation__SecondaryAction svg{
      fill:var(--p-icon-subdued-on-surface, #919eab);
      color:var(--p-override-transparent, white); }
    .Polaris-Navigation__SecondaryAction img{
      -webkit-filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%);
              filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%); }
    @media (min-width: 48.0625em){
      .Polaris-Navigation__SecondaryAction{
        height:3.2em; } }
    .Polaris-Navigation__SecondaryAction:hover{
      background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
      background-color:var(--p-surface-hovered); }
      .Polaris-Navigation__SecondaryAction:hover svg{
        fill:var(--p-branded-action, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__SecondaryAction:hover img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__SecondaryAction:focus{
      background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
      position:relative; }
      .Polaris-Navigation__SecondaryAction:focus svg{
        fill:var(--p-icon-subdued-on-surface, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__SecondaryAction:focus img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
      .Polaris-Navigation__SecondaryAction:focus::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.3em;
        right:-0.3em;
        bottom:-0.3em;
        left:-0.3em;
        display:block;
        border:0.2em solid var(--p-interactive-focus);
        border-radius:calc(var(--p-border-radius-base) + 0.3em);
        pointer-events:none; }
    .Polaris-Navigation__SecondaryAction:focus:hover svg{
      fill:var(--p-branded-action, #5c6ac4);
      color:var(--p-override-transparent, white); }
    .Polaris-Navigation__SecondaryAction:focus:hover img{
      -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
              filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__SecondaryAction:active{
      background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
      background-color:var(--p-surface-pressed); }
      .Polaris-Navigation__SecondaryAction:active svg{
        fill:var(--p-branded-action, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__SecondaryAction:active img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
      .Polaris-Navigation__SecondaryAction:active::after{
        border:none; }
    .Polaris-Navigation__SecondaryAction:focus, .Polaris-Navigation__SecondaryAction:active{
      outline:none; }

  .Polaris-Navigation__SecondaryNavigation{
    flex-basis:100%;
    margin-bottom:0.8em;
    margin-left:4em;
    overflow-x:var(--p-override-visible, hidden); }
    @media (min-width: 48.0625em){
      .Polaris-Navigation__SecondaryNavigation{
        margin-left:3.6em; } }
    .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__List{
      margin:0;
      padding:0;
      list-style:none; }
    .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item{
      font-size:1.5em;
      font-weight:400;
      line-height:4em;
      color:var(--p-text-on-surface, #454f5b); }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:hover{
        color:var(--p-branded-action, #202e78); }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:focus{
        color:var(--p-text-on-surface, #202e78);
        position:relative; }
        .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:focus::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.3em;
          right:-0.3em;
          bottom:-0.3em;
          left:-0.3em;
          display:block;
          border:0.2em solid var(--p-interactive-focus);
          border-radius:calc(var(--p-border-radius-base) + 0.3em);
          pointer-events:none; }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:focus:hover{
        color:var(--p-branded-action, #202e78); }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active, .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active:hover{
        color:var(--p-branded-action-pressed, #202e78); }
        .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active::after, .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active:hover::after{
          border:none; }
      @media (min-width: 48.0625em){
        .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item{
          font-size:1.4em;
          line-height:2.8em; } }
    .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Text{
      margin-top:1em;
      margin-bottom:1em;
      line-height:2em; }
      @media (min-width: 48.0625em){
        .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Text{
          margin-top:0.4em;
          margin-bottom:0.4em; } }
    .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected{
      font-weight:600;
      color:var(--p-branded-action, #202e78); }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:hover{
        color:var(--p-branded-action-hovered, #202e78); }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:focus{
        color:var(--p-branded-action, #202e78);
        position:relative; }
        .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:focus::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.3em;
          right:-0.3em;
          bottom:-0.3em;
          left:-0.3em;
          display:block;
          border:0.2em solid var(--p-interactive-focus);
          border-radius:calc(var(--p-border-radius-base) + 0.3em);
          pointer-events:none; }
      .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:active{
        color:var(--p-branded-action-pressed, #202e78); }
        .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:active::after{
          border:none; }
    .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--disabled{
      font-weight:400;
      color:var(--p-text-disabled-on-surface, #919eab); }

  .Polaris-Navigation__Section{
    margin:0;
    padding:0;
    list-style:none;
    flex:0 0 auto;
    padding:1.6em 0;
    padding-left:0px;
    padding-left:calc(0px + constant(safe-area-inset-left));
    padding-left:calc(0px + env(safe-area-inset-left)); }
    .Polaris-Navigation__Section + .Polaris-Navigation__Section{
      padding-top:0.4em; }

  .Polaris-Navigation__Section--fill{
    flex:1 0 auto; }

  .Polaris-Navigation__Section--withSeparator{
    border-top:var(--p-border-subdued, 0.1em solid #dfe3e8); }

  .Polaris-Navigation__SectionHeading{
    font-size:1.3em;
    font-weight:600;
    line-height:1.6em;
    text-transform:uppercase;
    display:flex;
    align-items:center;
    padding-left:1.6em;
    color:var(--p-text-subdued-on-surface, #637381); }
    @media (min-width: 40em){
      .Polaris-Navigation__SectionHeading{
        font-size:1.2em; } }
    .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action{
      -webkit-appearance:none;
         -moz-appearance:none;
              appearance:none;
      margin:0;
      padding:0;
      background:none;
      border:none;
      font-size:inherit;
      line-height:inherit;
      cursor:pointer;
      display:flex;
      align-items:center;
      margin-right:0.4em;
      padding:0.4em 1.6em;
      border-radius:var(--p-border-radius-base, 3px); }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus{
        outline:none; }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action svg{
        fill:var(--p-icon-subdued-on-surface, #919eab);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action img{
        -webkit-filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%);
                filter:brightness(0) saturate(100%) invert(68%) sepia(18%) saturate(246%) hue-rotate(169deg) brightness(88%) contrast(90%); }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover{
        background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
        background-color:var(--p-surface-hovered); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover svg{
          fill:var(--p-branded-action, #5c6ac4);
          color:var(--p-override-transparent, white); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover img{
          -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                  filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus{
        background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
        position:relative; }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus svg{
          fill:var(--p-icon-subdued-on-surface, #5c6ac4);
          color:var(--p-override-transparent, white); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus img{
          -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                  filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus::after{
          content:var(--p-non-null-content, none);
          position:absolute;
          z-index:1;
          top:-0.3em;
          right:-0.3em;
          bottom:-0.3em;
          left:-0.3em;
          display:block;
          border:0.2em solid var(--p-interactive-focus);
          border-radius:calc(var(--p-border-radius-base) + 0.3em);
          pointer-events:none; }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus:hover svg{
        fill:var(--p-branded-action, #5c6ac4);
        color:var(--p-override-transparent, white); }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus:hover img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active{
        background-image:var(--p-override-none, linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)));
        background-color:var(--p-surface-pressed); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active svg{
          fill:var(--p-branded-action, #5c6ac4);
          color:var(--p-override-transparent, white); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active img{
          -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                  filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
        .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active::after{
          border:none; }
      .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active{
        outline:none; }

  .Polaris-Navigation__RollupToggle{
    font-weight:400;
    color:var(--p-text-on-surface, #212b36);
    color:#637381; }
    .Polaris-Navigation__RollupToggle:hover{
      color:var(--p-branded-action, #5c6ac4); }
      .Polaris-Navigation__RollupToggle:hover svg{
        fill:var(--p-branded-action, #5c6ac4); }
      .Polaris-Navigation__RollupToggle:hover img{
        -webkit-filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%);
                filter:brightness(0) saturate(100%) invert(45%) sepia(17%) saturate(1966%) hue-rotate(194deg) brightness(88%) contrast(84%); }
    .Polaris-Navigation__RollupToggle:focus{
      outline:none;
      position:relative; }
      .Polaris-Navigation__RollupToggle:focus::after{
        content:var(--p-non-null-content, none);
        position:absolute;
        z-index:1;
        top:-0.3em;
        right:-0.3em;
        bottom:-0.3em;
        left:-0.3em;
        display:block;
        border:0.2em solid var(--p-interactive-focus);
        border-radius:calc(var(--p-border-radius-base) + 0.3em);
        pointer-events:none; }

  .Polaris-Navigation__List{
    margin:0;
    padding:0;
    list-style:none; }

  .Polaris-Navigation__Indicator{
    position:relative;
    display:inline-block;
    height:1em;
    width:1em; }


  .Polaris-Navigation-Message{
    padding:1em 1.6em;
    color:#454f5b; }


  .Polaris-Tooltip{
    margin:0.4em 1.6em 1.6em;
    opacity:1;
    box-shadow:0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08);
    border-radius:3px;
    pointer-events:none;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity, left, top;
    transition:opacity 200ms cubic-bezier(0.36, 0, 1, 1) 100ms; }
    @media screen and (-ms-high-contrast: active){
      .Polaris-Tooltip{
        border:0.2em solid windowText; } }

  .Polaris-Tooltip--measuring{
    opacity:0; }

  .Polaris-Tooltip--positionedAbove{
    margin:1.6em 1.6em 0.4em; }

  .Polaris-Tooltip--light .Polaris-Tooltip__Wrapper{
    background:white;
    color:#212b36; }

  .Polaris-Tooltip__Wrapper{
    position:relative;
    display:flex;
    background-color:#212b36;
    border-radius:3px;
    color:white;
    max-height:10em; }

  .Polaris-Tooltip__Content{
    position:relative;
    border-radius:3px;
    max-width:20em;
    max-height:10em; }

  .Polaris-Tooltip__Label{
    padding:0.4em 0.8em;
    word-break:break-word; }


  .Polaris-Pagination{
    display:inline-flex; }
    @media (max-width: 30.625em), (min-width: 48.0625em) and (max-width: 47.125em){
      [data-has-navigation] .Polaris-Pagination:not(.Polaris-Pagination--plain){
        display:flex;
        width:100%; }
        [data-has-navigation] .Polaris-Pagination:not(.Polaris-Pagination--plain) .Polaris-Pagination__Button{
          flex:1 0 auto; } }
    @media (max-width: 30.625em){
      .Polaris-Pagination:not(.Polaris-Pagination--plain){
        display:flex;
        width:100%; }
        .Polaris-Pagination:not(.Polaris-Pagination--plain) .Polaris-Pagination__Button{
          flex:1 0 auto; } }

  .Polaris-Pagination--plain .Polaris-Pagination__Button{
    position:relative;
    margin-top:-0.4em;
    margin-bottom:-0.4em;
    padding-top:0.8em;
    padding-bottom:0.8em;
    background:transparent;
    border:none;
    cursor:pointer;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    box-shadow:none; }
    .Polaris-Pagination--plain .Polaris-Pagination__Button:hover, .Polaris-Pagination--plain .Polaris-Pagination__Button:active{
      background:transparent;
      border:none;
      box-shadow:none; }
      .Polaris-Pagination--plain .Polaris-Pagination__Button:hover svg, .Polaris-Pagination--plain .Polaris-Pagination__Button:active svg{
        fill:#212b36; }
    .Polaris-Pagination--plain .Polaris-Pagination__Button:focus{
      border:none;
      box-shadow:none; }
      .Polaris-Pagination--plain .Polaris-Pagination__Button:focus::after{
        background:rgba(33, 43, 54, 0.1); }
    .Polaris-Pagination--plain .Polaris-Pagination__Button:disabled{
      background:transparent;
      border:none;
      box-shadow:none; }
      .Polaris-Pagination--plain .Polaris-Pagination__Button:disabled svg{
        fill:#c4cdd5; }
    .Polaris-Pagination--plain .Polaris-Pagination__Button::after{
      content:'';
      position:absolute;
      top:0.4em;
      right:0;
      left:0;
      display:block;
      height:2.8em;
      border-radius:3px;
      -webkit-backface-visibility:hidden;
              backface-visibility:hidden;
      will-change:background;
      transition-property:background;
      transition-duration:200ms;
      transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }

  .Polaris-Pagination--plain .Polaris-Pagination__PreviousButton{
    margin-left:-0.8em; }

  .Polaris-Pagination--plain .Polaris-Pagination__NextButton{
    margin-right:-0.8em;
    margin-left:0.2em; }

  .Polaris-Pagination__Button{
    position:relative;
    z-index:10;
    display:inline-block;
    min-width:3.6em;
    margin:0;
    padding:0.7em 0.8em;
    background:linear-gradient(to bottom, white, #f9fafb);
    border:0.1em solid #c4cdd5;
    border-radius:3px;
    line-height:1;
    color:#212b36;
    text-align:center;
    cursor:pointer;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
    text-decoration:none;
    transition-property:color, background, border, box-shadow;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-Pagination__Button svg{
      fill:#637381; }
    .Polaris-Pagination__Button:hover{
      background:linear-gradient(to bottom, #f9fafb, #f4f6f8);
      border-color:#c4cdd5; }
    .Polaris-Pagination__Button:focus{
      z-index:20;
      border-color:#5c6ac4;
      outline:0;
      box-shadow:0 0 0 1px #5c6ac4; }
    .Polaris-Pagination__Button:active{
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      border-color:#c4cdd5;
      box-shadow:0 0 0 0 transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2); }
    .Polaris-Pagination__Button:disabled{
      background:#f4f6f8;
      color:#919eab;
      cursor:default;
      box-shadow:none; }
      .Polaris-Pagination__Button:disabled svg{
        fill:#919eab; }

  .Polaris-Pagination__PreviousButton{
    border-top-right-radius:0;
    border-bottom-right-radius:0; }

  .Polaris-Pagination__NextButton{
    margin-left:-1px;
    border-top-left-radius:0;
    border-bottom-left-radius:0; }

  .Polaris-Pagination__Label{
    padding:0 0.8em;
    display:flex;
    align-items:center;
    justify-content:center; }


  .Polaris-Header-Title{
    word-wrap:break-word;
    word-break:break-word;
    overflow-wrap:break-word; }

  .Polaris-Header-Title__SubTitle{
    margin-top:0.8em; }

  .Polaris-Header-Title--hasThumbnail{
    display:grid;
    grid-gap:1.6em;
    grid-template-columns:auto 1fr; }
    .Polaris-Header-Title--hasThumbnail .Polaris-Header-Title__TitleAndSubtitleWrapper{
      display:flex;
      flex-direction:column;
      justify-content:center; }

  .Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title{
    display:inline;
    margin-right:0.8em; }
    .Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title > *{
      display:inline; }

  .Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title__TitleMetadata{
    margin-top:0.8em;
    display:inline-block; }


  .Polaris-Page-Header{
    padding:2em 2em 0;
    position:relative; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Page-Header{
        padding-left:0;
        padding-right:0; } }
    @media (min-width: 30.625em){
      .Polaris-Page-Header{
        padding-left:0;
        padding-right:0; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-Page-Header{
        padding-top:3.2em; } }
    @media (min-width: 46.5em){
      .Polaris-Page-Header{
        padding-top:3.2em; } }

  .Polaris-Page-Header--separator{
    padding-bottom:1.6em;
    border-bottom:0.1em solid #dfe3e8; }

  .Polaris-Page-Header--titleHidden{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }

  .Polaris-Page-Header__Navigation{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:0.4em; }
    .Polaris-Page-Header--hasActionMenu.Polaris-Page-Header--mobileView .Polaris-Page-Header__Navigation{
      padding-right:3.6em; }

  .Polaris-Page-Header__BreadcrumbWrapper{
    flex:0 1 auto;
    max-width:100%; }

  .Polaris-Page-Header__PaginationWrapper{
    flex:0 0 auto;
    margin-left:auto;
    line-height:1; }

  .Polaris-Page-Header:not(.Polaris-Page-Header--mobileView) .Polaris-Page-Header__MainContent{
    display:flex;
    align-items:center; }

  .Polaris-Page-Header__TitleActionMenuWrapper{
    flex:1 1 auto; }
    .Polaris-Page-Header:not(.Polaris-Page-Header--mobileView) .Polaris-Page-Header__TitleActionMenuWrapper{
      padding-right:2em; }
    .Polaris-Page-Header--hasActionMenu.Polaris-Page-Header--mobileView:not(.Polaris-Page-Header--hasNavigation) .Polaris-Page-Header__TitleActionMenuWrapper{
      padding-right:3.6em; }

  .Polaris-Page-Header__PrimaryActionWrapper{
    flex:0 0 auto; }
    .Polaris-Page-Header--mobileView .Polaris-Page-Header__PrimaryActionWrapper{
      margin-top:1.6em; }

  .Polaris-Page-Header__ActionMenuWrapper{
    margin-top:0.8em; }
    .Polaris-Page-Header--mobileView .Polaris-Page-Header__ActionMenuWrapper{
      position:absolute;
      top:2.9em;
      right:0;
      margin-top:0; }
      @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
        [data-has-navigation] .Polaris-Page-Header--mobileView .Polaris-Page-Header__ActionMenuWrapper{
          right:-0.8em; } }
      @media (min-width: 30.625em){
        .Polaris-Page-Header--mobileView .Polaris-Page-Header__ActionMenuWrapper{
          right:-0.8em; } }
    .Polaris-Page-Header--mobileView.Polaris-Page-Header--hasNavigation .Polaris-Page-Header__ActionMenuWrapper{
      top:1.8em; }

  .Polaris-Page{
    margin:0 auto;
    padding:0;
    max-width:99.8em; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-Page{
        padding:0 2em; } }
    @media (min-width: 30.625em){
      .Polaris-Page{
        padding:0 2em; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-Page{
        padding:0 3.2em; } }
    @media (min-width: 46.5em){
      .Polaris-Page{
        padding:0 3.2em; } }
    .Polaris-Page::after{
      content:'';
      display:table; }

  .Polaris-Page--fullWidth{
    max-width:none; }

  .Polaris-Page--narrowWidth{
    max-width:66.2em; }

  .Polaris-Page__Content{
    margin:2em 0; }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-Page__Content{
        margin-top:2em; } }
    @media (min-width: 46.5em){
      .Polaris-Page__Content{
        margin-top:2em; } }


  .Polaris-PageActions{
    margin:0 auto;
    padding:2em;
    border-top:0.1em solid #dfe3e8; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-PageActions{
        padding:2em 0; } }
    @media (min-width: 30.625em){
      .Polaris-PageActions{
        padding:2em 0; } }


  @keyframes Polaris-ProgressBar--fillup{
    0%{
      width:0; } }

  .Polaris-ProgressBar{
    overflow:hidden;
    width:100%;
    background-color:#dfe3e8;
    border-radius:3px; }
    @media screen and (-ms-high-contrast: active){
      .Polaris-ProgressBar{
        border:1px solid windowText; } }

  .Polaris-ProgressBar--sizeSmall{
    height:0.8em; }

  .Polaris-ProgressBar--sizeMedium{
    height:1.6em; }

  .Polaris-ProgressBar--sizeLarge{
    height:3.2em; }

  .Polaris-ProgressBar__Indicator{
    height:inherit;
    width:0;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:width;
    background-color:#47c1bf;
    animation:Polaris-ProgressBar--fillup 500ms cubic-bezier(0.64, 0, 0.35, 1);
    transition:width 500ms cubic-bezier(0.64, 0, 0.35, 1); }
    @media screen and (-ms-high-contrast: active){
      .Polaris-ProgressBar__Indicator{
        border:1.6em solid highlight; } }

  .Polaris-ProgressBar__Progress,
  .Polaris-ProgressBar__Label{
    position:absolute !important;
    top:0;
    clip:rect(1px, 1px, 1px, 1px) !important;
    overflow:hidden !important;
    height:1px !important;
    width:1px !important;
    padding:0 !important;
    border:0 !important; }


  .Polaris-RangeSlider-DualThumb__Wrapper{
    position:relative;
    width:100%;
    display:flex;
    align-items:center; }

  .Polaris-RangeSlider-DualThumb__TrackWrapper{
    position:relative;
    display:flex;
    align-items:center;
    width:100%;
    min-height:2.8em;
    cursor:pointer; }
    .Polaris-RangeSlider-DualThumb__TrackWrapper.Polaris-RangeSlider-DualThumb--disabled{
      opacity:0.8;
      cursor:not-allowed; }

  .Polaris-RangeSlider-DualThumb__Track{
    position:absolute;
    width:100%;
    height:0.4em;
    border-radius:2.4em;
    --unselected-range:#c4cdd5;
    --selected-range:#5c6ac4;
    --gradient-colors:var(--unselected-range, transparent) 0%,
      var(--unselected-range, transparent)
        var(--Polaris-RangeSlider-progress-lower),
      var(--selected-range, transparent) var(--Polaris-RangeSlider-progress-lower),
      var(--selected-range, transparent) var(--Polaris-RangeSlider-progress-upper),
      var(--unselected-range, transparent)
        var(--Polaris-RangeSlider-progress-upper),
      var(--unselected-range, transparent) 100%;
    background-image:linear-gradient(to right, var(--gradient-colors)); }
    .Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Track{
      --selected-range:#de3618;
      --gradient-colors:var(--unselected-range, transparent) 0%,
        var(--unselected-range, transparent)
          var(--Polaris-RangeSlider-progress-lower),
        var(--selected-range, transparent)
          var(--Polaris-RangeSlider-progress-lower),
        var(--selected-range, transparent)
          var(--Polaris-RangeSlider-progress-upper),
        var(--unselected-range, transparent)
          var(--Polaris-RangeSlider-progress-upper),
        var(--unselected-range, transparent) 100%;
      background-image:linear-gradient(to right, var(--gradient-colors)); }
    .Polaris-RangeSlider-DualThumb--disabled .Polaris-RangeSlider-DualThumb__Track{
      background-image:none;
      background:#c4cdd5; }

  .Polaris-RangeSlider-DualThumb__Thumbs{
    position:absolute;
    z-index:20;
    width:2.4em;
    height:2.4em;
    border-radius:50%;
    border:0.1em solid #f9fafb;
    box-shadow:0 0 0 0.1em rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(22, 29, 37, 0.05);
    background:linear-gradient(#f9fafb, #f4f6f8);
    -webkit-tap-highlight-color:transparent;
    cursor:-webkit-grab; }
    .Polaris-RangeSlider-DualThumb__Thumbs.Polaris-RangeSlider-DualThumb--disabled{
      cursor:not-allowed;
      border-color:#c4cdd5; }
    .Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Thumbs{
      border-color:#de3618;
      box-shadow:0 0 0 0.1em #de3618; }
      .Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Thumbs:hover, .Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Thumbs:focus{
        border-color:#de3618;
        box-shadow:0 0 0 0.1em #de3618; }
    .Polaris-RangeSlider-DualThumb__Thumbs:hover{
      background:linear-gradient(#f9fafb, #f4f6f8);
      box-shadow:0 0 0 0.1em rgba(0, 0, 0, 0.4), 0 1px 0 0 rgba(22, 29, 37, 0.05); }

  .Polaris-RangeSlider-DualThumb__Prefix{
    flex:0 0 auto;
    margin-right:0.8em; }

  .Polaris-RangeSlider-DualThumb__Suffix{
    flex:0 0 auto;
    margin-left:0.8em; }

  .Polaris-RangeSlider-DualThumb__Output{
    position:absolute;
    z-index:10;
    bottom:2.4em;
    opacity:0;
    visibility:hidden;
    pointer-events:none;
    transition-property:opacity, visibility;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1);
    transform:translateX(calc(-50% + 1.2em)); }
    .Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output,
    .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output,
    .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output{
      opacity:1;
      visibility:visible; }

  .Polaris-RangeSlider-DualThumb__OutputBubble{
    position:relative;
    display:flex;
    padding:0 0.8em;
    min-width:3.2em;
    height:3.2em;
    background-color:#212b36;
    border-radius:3px;
    transition-property:transform;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble,
    .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble,
    .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble{
      transform:translateY(-1.6em); }
      @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
        [data-has-navigation] .Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, [data-has-navigation]
        .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, [data-has-navigation]
        .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble{
          transform:translateY(-0.8em); } }
      @media (min-width: 46.5em){
        .Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble,
        .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble,
        .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble{
          transform:translateY(-0.8em); } }

  .Polaris-RangeSlider-DualThumb__OutputText{
    font-size:1.3em;
    font-weight:600;
    line-height:1.6em;
    text-transform:uppercase;
    display:block;
    flex:1 1 auto;
    margin:auto;
    text-align:center;
    color:white; }
    @media (min-width: 40em){
      .Polaris-RangeSlider-DualThumb__OutputText{
        font-size:1.2em; } }


  .Polaris-RangeSlider-SingleThumb{
    display:flex;
    align-items:center; }
    .Polaris-RangeSlider-SingleThumb.Polaris-RangeSlider-SingleThumb--disabled{
      opacity:0.8; }

  .Polaris-RangeSlider-SingleThumb__InputWrapper{
    position:relative;
    display:flex;
    align-items:center;
    flex:1 1 auto;
    height:2.4em; }
    .Polaris-RangeSlider-SingleThumb__InputWrapper input{
      padding:1.2em 0;
      background-color:transparent;
      cursor:pointer; }

  .Polaris-RangeSlider-SingleThumb--disabled input{
    cursor:not-allowed; }

  .Polaris-RangeSlider-SingleThumb__Prefix{
    flex:0 0 auto;
    margin-right:0.8em; }

  .Polaris-RangeSlider-SingleThumb__Suffix{
    flex:0 0 auto;
    margin-left:0.8em; }

  .Polaris-RangeSlider-SingleThumb__Input{
    --progress-lower:#5c6ac4;
    --progress-upper:#c4cdd5;
    --gradient-colors:var(--progress-lower, transparent) 0%,
      var(--progress-lower, transparent) var(--Polaris-RangeSlider-progress, 0%),
      var(--progress-upper, transparent) var(--Polaris-RangeSlider-progress, 100%),
      var(--progress-upper, transparent) 100%;
    margin:0;
    padding:0;
    width:100%;
    background-color:transparent;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    position:relative;
    z-index:20;
    flex:1 1 auto; }
    .Polaris-RangeSlider-SingleThumb__Input::-ms-tooltip{
      display:none; }
    .Polaris-RangeSlider-SingleThumb__Input:focus{
      outline:0; }
    .Polaris-RangeSlider-SingleThumb__Input::-moz-focus-outer{
      border:0; }
    .Polaris-RangeSlider-SingleThumb__Input::-ms-track{
      cursor:pointer;
      width:100%;
      height:0.4em;
      background-color:var(--progress-upper, #c4cdd5);
      background-image:linear-gradient(to right, var(--gradient-colors));
      border:none;
      border-radius:0.4em; }
    .Polaris-RangeSlider-SingleThumb__Input::-moz-range-track{
      cursor:pointer;
      width:100%;
      height:0.4em;
      background-color:var(--progress-upper, #c4cdd5);
      background-image:linear-gradient(to right, var(--gradient-colors));
      border:none;
      border-radius:0.4em; }
    .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track{
      cursor:pointer;
      width:100%;
      height:0.4em;
      background-color:var(--progress-upper, #c4cdd5);
      background-image:linear-gradient(to right, var(--gradient-colors));
      border:none;
      border-radius:0.4em; }
    .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb{
      cursor:-webkit-grab;
      width:2.4em;
      height:2.4em;
      border:0.1em solid #f9fafb;
      border-radius:50%;
      background:linear-gradient(white, #f9fafb);
      box-shadow:0 0 0 0.1em rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(22, 29, 37, 0.05);
      appearance:none;
      transition-property:border-color, box-shadow;
      transition-duration:200ms;
      transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
      .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb:hover{
        background:linear-gradient(#f9fafb, #f4f6f8);
        box-shadow:0 0 0 0.1em rgba(0, 0, 0, 0.4), 0 1px 0 0 rgba(22, 29, 37, 0.05); }
    .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb{
      cursor:-webkit-grab;
      width:2.4em;
      height:2.4em;
      border:0.1em solid #f9fafb;
      border-radius:50%;
      background:linear-gradient(white, #f9fafb);
      box-shadow:0 0 0 0.1em rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(22, 29, 37, 0.05);
      -moz-appearance:none;
           appearance:none;
      transition-property:border-color, box-shadow;
      transition-duration:200ms;
      transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
      .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb:hover{
        background:linear-gradient(#f9fafb, #f4f6f8);
        box-shadow:0 0 0 0.1em rgba(0, 0, 0, 0.4), 0 1px 0 0 rgba(22, 29, 37, 0.05); }
    .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb{
      cursor:-webkit-grab;
      width:2.4em;
      height:2.4em;
      border:0.1em solid #f9fafb;
      border-radius:50%;
      background:linear-gradient(white, #f9fafb);
      box-shadow:0 0 0 0.1em rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(22, 29, 37, 0.05);
      -webkit-appearance:none;
              appearance:none;
      transition-property:border-color, box-shadow;
      transition-duration:200ms;
      transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
      .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb:hover{
        background:linear-gradient(#f9fafb, #f4f6f8);
        box-shadow:0 0 0 0.1em rgba(0, 0, 0, 0.4), 0 1px 0 0 rgba(22, 29, 37, 0.05); }
    .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb{
      margin-top:0;
      transform:translateY(0.48em) scale(0.4); }
    .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb{
      margin-top:-1em; }
    .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input{
      --progress-lower:#de3618; }
      .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-ms-track{
        background-color:#fead9a; }
      .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-moz-range-track{
        background-color:#fead9a; }
      .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track{
        background-color:#fead9a; }
      .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb{
        border-color:#de3618;
        box-shadow:0 0 0 0.1em #de3618; }
      .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb{
        border-color:#de3618;
        box-shadow:0 0 0 0.1em #de3618; }
      .Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb{
        border-color:#de3618;
        box-shadow:0 0 0 0.1em #de3618; }
    .Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-ms-track{
      cursor:auto;
      background-image:none; }
    .Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-moz-range-track{
      cursor:auto;
      background-image:none; }
    .Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track{
      cursor:auto;
      background-image:none; }
    .Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb{
      cursor:not-allowed;
      border-color:#c4cdd5; }
    .Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb{
      cursor:not-allowed;
      border-color:#c4cdd5; }
    .Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb{
      cursor:not-allowed;
      border-color:#c4cdd5; }
    .Polaris-RangeSlider-SingleThumb__RangeSlider .Polaris-RangeSlider-SingleThumb__Input:focus{
      --progress-lower:#5c6ac4; }
      .Polaris-RangeSlider-SingleThumb__RangeSlider .Polaris-RangeSlider-SingleThumb__Input:focus::-ms-track{
        background-color:#c4cdd5; }
      .Polaris-RangeSlider-SingleThumb__RangeSlider .Polaris-RangeSlider-SingleThumb__Input:focus::-moz-range-track{
        background-color:#c4cdd5; }
      .Polaris-RangeSlider-SingleThumb__RangeSlider .Polaris-RangeSlider-SingleThumb__Input:focus::-webkit-slider-runnable-track{
        background-color:#c4cdd5; }
      .Polaris-RangeSlider-SingleThumb__RangeSlider .Polaris-RangeSlider-SingleThumb__Input:focus::-ms-thumb{
        background:linear-gradient(#f9fafb, #f4f6f8);
        border-color:#5c6ac4;
        box-shadow:0 0 0 0.1em #5c6ac4; }
      .Polaris-RangeSlider-SingleThumb__RangeSlider .Polaris-RangeSlider-SingleThumb__Input:focus::-moz-range-thumb{
        background:linear-gradient(#f9fafb, #f4f6f8);
        border-color:#5c6ac4;
        box-shadow:0 0 0 0.1em #5c6ac4; }
      .Polaris-RangeSlider-SingleThumb__RangeSlider .Polaris-RangeSlider-SingleThumb__Input:focus::-webkit-slider-thumb{
        background:linear-gradient(#f9fafb, #f4f6f8);
        border-color:#5c6ac4;
        box-shadow:0 0 0 0.1em #5c6ac4; }

  .Polaris-RangeSlider-SingleThumb__Output{
    position:absolute;
    z-index:10;
    bottom:2.4em;
    left:var(--Polaris-RangeSlider-progress, 50%);
    transform:translateX(calc(-50% + var(--Polaris-RangeSlider-output-factor, 0)*2.4em));
    opacity:0;
    visibility:hidden;
    pointer-events:none;
    transition-property:opacity, visibility;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output,
    .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output,
    .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output{
      opacity:1;
      visibility:visible; }

  .Polaris-RangeSlider-SingleThumb__OutputBubble{
    position:relative;
    display:flex;
    padding:0 0.8em;
    min-width:3.2em;
    height:3.2em;
    background-color:#212b36;
    border-radius:3px;
    transition-property:transform;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1); }
    .Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble,
    .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble,
    .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble{
      transform:translateY(-1.6em); }
      @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
        [data-has-navigation] .Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, [data-has-navigation]
        .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, [data-has-navigation]
        .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble{
          transform:translateY(-0.8em); } }
      @media (min-width: 46.5em){
        .Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble,
        .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble,
        .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble{
          transform:translateY(-0.8em); } }

  .Polaris-RangeSlider-SingleThumb__OutputText{
    font-size:1.3em;
    font-weight:600;
    line-height:1.6em;
    text-transform:uppercase;
    display:block;
    flex:1 1 auto;
    margin:auto;
    text-align:center;
    color:white; }
    @media (min-width: 40em){
      .Polaris-RangeSlider-SingleThumb__OutputText{
        font-size:1.2em; } }


  .Polaris-ResourceItem__CheckboxWrapper{
    display:flex; }

  .Polaris-ResourceItem{
    position:relative;
    outline:none;
    cursor:pointer; }
    .Polaris-ResourceItem:hover{
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
      .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions{
        clip:auto;
        overflow:visible;
        height:100%;
        right:1.6em; }
        @media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em){
          [data-has-navigation] .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions{
            display:none; } }
        @media (max-width: 46.5em){
          .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions{
            display:none; } }
    .Polaris-ResourceItem:active{
      background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }

  .Polaris-ResourceItem--selected{
    background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)); }
    .Polaris-ResourceItem--selected.Polaris-ResourceItem--focused{
      box-shadow:inset 0.2em 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
      .Polaris-ResourceItem--selected.Polaris-ResourceItem--focused:hover{
        box-shadow:inset 0.2em 0 0 #5c6ac4;
        background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ResourceItem--selected:hover{
      background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ResourceItem--selected:active{
      background-image:linear-gradient(rgba(179, 188, 245, 0.15), rgba(179, 188, 245, 0.15)), linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }

  .Polaris-ResourceItem--focused{
    box-shadow:inset 0.2em 0 0 #5c6ac4;
    background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ResourceItem--focused:hover{
      box-shadow:inset 0.2em 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-ResourceItem--focused:active{
      box-shadow:inset 0.2em 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }

  .Polaris-ResourceItem--focusedInner,
  .Polaris-ResourceItem--focusedInner.Polaris-ResourceItem--focused,
  .Polaris-ResourceItem--focusedInner.Polaris-ResourceItem--focused.Polaris-ResourceItem--selected{
    box-shadow:none; }

  .Polaris-ResourceItem__Link,
  .Polaris-ResourceItem__Button{
    position:absolute;
    z-index:1;
    top:0;
    left:0;
    height:100%;
    width:100%;
    opacity:0; }

  .Polaris-ResourceItem__Button{
    padding:0;
    border:none; }

  .Polaris-ResourceItem__Container{
    position:relative;
    z-index:2;
    padding:1.2em 1.6em;
    min-height:4.4em;
    display:flex;
    align-items:flex-start; }
    @media (min-width: 28.625em){
      .Polaris-ResourceItem__Container{
        padding:1.2em 2em; } }

  .Polaris-ResourceItem__Owned{
    display:flex;
    align-self:flex-start; }

  .Polaris-ResourceItem__Handle{
    width:4.8em;
    min-height:4.4em;
    justify-content:center;
    align-items:center;
    margin:-1.2em 0.4em -1.2em -1.2em;
    display:flex; }
    @media (max-width: 28.625em){
      .Polaris-ResourceItem__Handle{
        visibility:hidden; }
        .Polaris-ResourceItem--selectMode .Polaris-ResourceItem__Handle{
          visibility:visible; } }

  .Polaris-ResourceItem--selectable{
    width:calc(100% + 4em);
    transform:translateX(-4em);
    transition:transform cubic-bezier(0.64, 0, 0.35, 1) 200ms;
    margin-right:-4em; }
    .Polaris-ResourceItem--selectable.Polaris-ResourceItem--selectMode{
      transform:translateX(0); }
    @media (min-width: 28.625em){
      .Polaris-ResourceItem--selectable{
        width:100%;
        transform:translateX(0);
        margin-right:0; } }

  .Polaris-ResourceItem__Media{
    flex:0 0 auto;
    margin-right:2em;
    color:inherit;
    text-decoration:none; }

  .Polaris-ResourceItem__Content{
    min-width:0;
    max-width:100%;
    flex:1 1 auto;
    align-self:center; }

  .Polaris-ResourceItem__Actions{
    position:absolute;
    top:0;
    display:flex;
    pointer-events:initial;
    height:100%;
    max-height:5.6em;
    clip:rect(1px, 1px, 1px, 1px);
    overflow:hidden;
    height:1px; }
    .Polaris-ResourceItem--focused .Polaris-ResourceItem__Actions{
      clip:auto;
      overflow:visible;
      height:100%; }
    @media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em){
      [data-has-navigation] .Polaris-ResourceItem__Actions{
        display:none; } }
    @media (max-width: 46.5em){
      .Polaris-ResourceItem__Actions{
        display:none; } }

  .Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions{
    position:relative;
    display:flex;
    flex:0 0 auto;
    flex-basis:auto;
    align-items:center;
    margin-top:0;
    margin-left:1.6em;
    pointer-events:initial;
    height:100%; }
    @media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em){
      [data-has-navigation] .Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions{
        display:none; } }
    @media (max-width: 46.5em){
      .Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions{
        display:none; } }

  .Polaris-ResourceItem__Disclosure{
    position:relative;
    top:-1.2em;
    right:-1.6em;
    display:none;
    width:4.8em;
    min-height:4.4em;
    pointer-events:initial; }
    .Polaris-ResourceItem--selectMode .Polaris-ResourceItem__Disclosure{
      display:none; }
    @media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em){
      [data-has-navigation] .Polaris-ResourceItem__Disclosure{
        display:flex;
        justify-content:center;
        align-items:center; } }
    @media (max-width: 46.5em){
      .Polaris-ResourceItem__Disclosure{
        display:flex;
        justify-content:center;
        align-items:center; } }


  .Polaris-Select{
    position:relative; }
    .Polaris-Select select::-ms-expand{
      display:none; }

  .Polaris-Select--disabled .Polaris-Select__Content{
    color:#919eab; }

  .Polaris-Select--disabled .Polaris-Select__InlineLabel{
    color:inherit; }

  .Polaris-Select--disabled .Polaris-Select__Icon{
    opacity:0.4; }

  .Polaris-Select--disabled .Polaris-Select__Backdrop{
    background:#dfe3e8;
    box-shadow:none; }
    .Polaris-Select--disabled .Polaris-Select__Backdrop::after{
      background:#f9fafb; }
    @media (-ms-high-contrast: active){
      .Polaris-Select--disabled .Polaris-Select__Backdrop{
        color:grayText; } }

  .Polaris-Select--error .Polaris-Select__Backdrop{
    background:#bf0711;
    box-shadow:0 0 0 1px transparent; }
    .Polaris-Select--error .Polaris-Select__Backdrop::after{
      background:#fbeae5; }

  .Polaris-Select--error .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop{
    background:#5c6ac4;
    box-shadow:0 0 0 1px #5c6ac4; }
    .Polaris-Select--error .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop::after{
      background:#fbeae5; }

  .Polaris-Select--placeholder.Polaris-Select--error .Polaris-Select__Input{
    color:#9c9798; }

  .Polaris-Select--placeholder.Polaris-Select--error .Polaris-Select__Input:-moz-focusring{
    color:transparent;
    text-shadow:0 0 0 #212b36; }

  .Polaris-Select__Content{
    font-size:1.6em;
    font-weight:400;
    line-height:2.4em;
    text-transform:initial;
    letter-spacing:initial;
    position:relative;
    z-index:20;
    display:flex;
    align-items:center;
    width:100%;
    min-height:3.6em;
    padding:0.5em 0.8em 0.5em 1.2em; }
    @media (min-width: 40em){
      .Polaris-Select__Content{
        font-size:1.4em; } }

  .Polaris-Select__InlineLabel{
    color:#637381;
    margin-right:0.4em;
    white-space:nowrap;
    overflow:hidden; }

  .Polaris-Select__SelectedOption{
    flex:1 1;
    white-space:nowrap;
    overflow:hidden; }

  .Polaris-Select__Icon svg{
    fill:#637381; }

  .Polaris-Select__Backdrop{
    background-color:#c4cdd5;
    border:0.1em solid transparent;
    box-shadow:0 0 0 1px transparent, 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    transition-property:box-shadow, background-color;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1);
    position:absolute;
    z-index:10;
    top:0;
    bottom:0;
    left:0;
    right:0; }
    .Polaris-Select__Backdrop::after{
      content:'';
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      display:block;
      background:linear-gradient(to bottom, white, #f9fafb);
      border-radius:2px; }
    @media (-ms-high-contrast: active){
      .Polaris-Select__Backdrop{
        background:transparent;
        border:2px solid buttonText;
        transition:none;
        color:rgba(223, 227, 232, 0.3); }
        .Polaris-Select__Backdrop::after{
          top:-4px;
          right:-4px;
          bottom:-4px;
          left:-4px;
          background:none;
          border-radius:4px; } }

  .Polaris-Select__Input{
    font-size:1.6em;
    font-weight:400;
    line-height:2.4em;
    text-transform:initial;
    letter-spacing:initial;
    position:absolute;
    text-rendering:auto;
    top:0;
    left:0;
    z-index:30;
    width:100%;
    height:100%;
    margin:0;
    opacity:0.001;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none; }
    @media (min-width: 40em){
      .Polaris-Select__Input{
        font-size:1.4em; } }
    .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop{
      background:#5c6ac4;
      box-shadow:0 0 0 1px #5c6ac4, 0 0 0 0 transparent; }
      .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop::after{
        background:white; }
      @media (-ms-high-contrast: active){
        .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop::after{
          border:1px dashed buttonText; } }

  @media (-ms-high-contrast: active){
    .Polaris-Select__Content{
      color:windowText;
      -ms-high-contrast-adjust:none; }
    .Polaris-Select__InlineLabel{
      color:inherit; }
      .Polaris-Select__InlineLabel::after{
        content:':'; }
    .Polaris-Select__SelectedOption{
      color:inherit; }
    .Polaris-Select__Icon svg{
      fill:buttonText; }
    .Polaris-Select__Backdrop::after{
      display:none; }
    .Polaris-Select__Input:focus ~ .Polaris-Select__Content{
      color:highlightText; }
    .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop{
      background-color:highlight; }
    .Polaris-Select--disabled .Polaris-Select__Content{
      color:grayText; }
    .Polaris-Select--disabled .Polaris-Select__Icon{
      opacity:1; }
      .Polaris-Select--disabled .Polaris-Select__Icon svg{
        fill:grayText; } }


  .Polaris-ResourceList-CheckableButton{
    font-size:1.5em;
    font-weight:var(--p-button-font-weight, 400);
    line-height:1.6em;
    text-transform:initial;
    letter-spacing:initial;
    display:inline-flex;
    align-items:center;
    min-height:3.6em;
    min-width:3.6em;
    margin:0;
    padding:0.7em 1.6em;
    line-height:1;
    cursor:pointer;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
    text-decoration:none;
    text-align:left;
    background:var(--p-surface-foreground, white);
    border:0.1em solid #c4cdd5;
    border-radius:3px 0 0 3px;
    border-right-color:transparent; }
    @media (min-width: 40em){
      .Polaris-ResourceList-CheckableButton{
        font-size:1.4em; } }
    .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--measuring{
      font-size:1.5em;
      font-weight:700; }
      .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--measuring::before{
        content:'';
        display:inline-block;
        width:1.5em; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList-CheckableButton{
        flex:0 1 auto; }
        .Polaris-ResourceList-CheckableButton:only-child{
          border-radius:3px;
          border:0.1em solid #c4cdd5; } }
    .Polaris-ResourceList-CheckableButton:hover, .Polaris-ResourceList-CheckableButton:active{
      border-right-color:transparent; }
    .Polaris-ResourceList-CheckableButton:focus{
      outline:none; }
    .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--plain{
      border:0.1em solid transparent;
      border-radius:3px; }
    .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--selectMode{
      color:#637381;
      font-weight:600; }
    .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--selected{
      color:#5c6ac4; }
      @media (min-width: 28.625em){
        .Polaris-ResourceList-CheckableButton.Polaris-ResourceList-CheckableButton__CheckableButton--selected{
          border-color:#c4cdd5; } }

  .Polaris-ResourceList-CheckableButton__Checkbox{
    pointer-events:none;
    height:1.6em;
    width:1.6em;
    margin-left:-0.9em; }

  .Polaris-ResourceList-CheckableButton__Label{
    margin-left:2em;
    flex:1 1;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    padding:0.1em 0; }


  .Polaris-ResourceList-BulkActions__Button{
    font-size:1.5em;
    font-weight:var(--p-button-font-weight, 400);
    line-height:1.6em;
    text-transform:initial;
    letter-spacing:initial;
    position:relative;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    min-height:3.6em;
    min-width:3.6em;
    margin:0;
    padding:0.7em 1.6em;
    background:linear-gradient(to bottom, white, #f9fafb);
    border:0.1em solid #c4cdd5;
    box-shadow:0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius:3px;
    line-height:1;
    color:#212b36;
    text-align:center;
    cursor:pointer;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
    text-decoration:none;
    transition-property:background, border, box-shadow;
    transition-duration:200ms;
    transition-timing-function:cubic-bezier(0.64, 0, 0.35, 1);
    -webkit-tap-highlight-color:transparent; }
    @media (min-width: 40em){
      .Polaris-ResourceList-BulkActions__Button{
        font-size:1.4em; } }
    .Polaris-ResourceList-BulkActions__Button svg{
      fill:#637381; }
    .Polaris-ResourceList-BulkActions__Button:hover{
      background:linear-gradient(to bottom, #f9fafb, #f4f6f8);
      border-color:#c4cdd5; }
    .Polaris-ResourceList-BulkActions__Button:focus{
      border-color:#5c6ac4;
      outline:0;
      box-shadow:0 0 0 1px #5c6ac4; }
      @media (-ms-high-contrast: active){
        .Polaris-ResourceList-BulkActions__Button:focus{
          outline:2px dotted; } }
    .Polaris-ResourceList-BulkActions__Button:active{
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      border-color:#c4cdd5;
      box-shadow:0 0 0 0 transparent, inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2); }
    .Polaris-ResourceList-BulkActions__Button:not(:first-child){
      margin-left:-1px; }
    .Polaris-ResourceList-BulkActions__Group--measuring .Polaris-ResourceList-BulkActions__Button{
      font-size:1.5em; }
    .Polaris-ResourceList-BulkActions--disabled .Polaris-ResourceList-BulkActions__Button{
      transition:none;
      background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
      color:#919eab; }
      .Polaris-ResourceList-BulkActions--disabled .Polaris-ResourceList-BulkActions__Button svg{
        fill:#919eab; }

  .Polaris-ResourceList-BulkActions__Group{
    font-size:1.6em;
    font-weight:400;
    line-height:2.4em;
    text-transform:initial;
    letter-spacing:initial;
    width:100%;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity, display;
    transition:opacity cubic-bezier(0.64, 0, 0.35, 1) 200ms;
    display:none;
    align-items:center;
    flex-wrap:wrap;
    opacity:0; }
    @media (min-width: 40em){
      .Polaris-ResourceList-BulkActions__Group{
        font-size:1.4em; } }
    .Polaris-ResourceList-BulkActions__Group.Polaris-ResourceList-BulkActions__Group--measuring{
      transition:none;
      display:flex;
      opacity:0; }

  .Polaris-ResourceList-BulkActions__Group--entering,
  .Polaris-ResourceList-BulkActions__Group--exiting{
    opacity:0;
    display:flex; }

  .Polaris-ResourceList-BulkActions__Group--entered{
    opacity:1;
    display:flex; }

  .Polaris-ResourceList-BulkActions__Group--exited{
    opacity:0;
    display:none; }

  @media (min-width: 28.625em){
    .Polaris-ResourceList-BulkActions__Group--smallScreen{
      display:none; } }

  .Polaris-ResourceList-BulkActions__Group--largeScreen{
    display:none; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList-BulkActions__Group--largeScreen{
        display:flex; }
        .Polaris-ResourceList-BulkActions__Group--largeScreen.Polaris-ResourceList-BulkActions__Group--exiting{
          transition:none; }
        .Polaris-ResourceList-BulkActions__Group--largeScreen.Polaris-ResourceList-BulkActions__Group--exited{
          opacity:0;
          display:none; } }

  .Polaris-ResourceList-BulkActions__ButtonGroup{
    display:flex;
    align-items:center;
    width:100%;
    flex-wrap:nowrap;
    justify-content:flex-end;
    box-shadow:inset 0 0 0 1px #c4cdd5;
    border-radius:3px; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList-BulkActions__ButtonGroup{
        width:auto;
        justify-content:flex-start;
        margin-right:0.8em; } }
    .Polaris-ResourceList-BulkActions__Group--measuring .Polaris-ResourceList-BulkActions__ButtonGroup{
      position:absolute;
      width:auto; }
    .Polaris-ResourceList-BulkActions__ButtonGroup .Polaris-ResourceList-BulkActions__Button{
      border-radius:0;
      white-space:nowrap; }
      .Polaris-ResourceList-BulkActions__ButtonGroup .Polaris-ResourceList-BulkActions__Button:focus{
        z-index:20; }
      .Polaris-ResourceList-BulkActions__ButtonGroup .Polaris-ResourceList-BulkActions__Button:last-child:not(:first-child){
        border-radius:0 3px 3px 0; }
    .Polaris-ResourceList-BulkActions__ButtonGroup .Polaris-ResourceList-BulkActions__Button--cancel{
      z-index:0;
      flex:0 0 auto;
      margin-left:-1px;
      border-radius:0 3px 3px 0; }
      .Polaris-ResourceList-BulkActions__ButtonGroup .Polaris-ResourceList-BulkActions__Button--cancel:focus{
        z-index:1; }

  .Polaris-ResourceList-BulkActions__CheckableContainer{
    flex:1 1; }

  .Polaris-ResourceList-BulkActions__Popover{
    max-height:3.6em;
    margin-left:-1px; }
    .Polaris-ResourceList-BulkActions__Popover:last-child *{
      border-radius:0 3px 3px 0; }

  .Polaris-ResourceList-BulkActions__ActionContent{
    display:flex;
    align-items:center; }

  .Polaris-ResourceList-BulkActions__ActionIcon{
    display:inline-block; }
    .Polaris-ResourceList-BulkActions__ActionIcon svg{
      fill:#637381; }
    .Polaris-ResourceList-BulkActions__ActionIcon:first-child{
      margin-right:0.4em; }
    .Polaris-ResourceList-BulkActions__ActionIcon:last-child{
      margin-right:-0.8em; }
    .Polaris-ResourceList-BulkActions__ActionIcon.Polaris-ResourceList-BulkActions--disabled svg{
      fill:#919eab; }

  .Polaris-ResourceList-BulkActions--disabled{
    transition:none;
    background:linear-gradient(to bottom, #f4f6f8, #f4f6f8);
    color:#919eab;
    cursor:default;
    pointer-events:none; }
    .Polaris-ResourceList-BulkActions--disabled svg{
      fill:#919eab; }

  .Polaris-ResourceList-BulkActions__PaginatedSelectAll{
    padding:0.4em 0; }

  .Polaris-ResourceList-BulkActions__Slide{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:transform;
    transform:translateY(0);
    transition:transform cubic-bezier(0.64, 0, 0.35, 1) 200ms; }

  .Polaris-ResourceList-BulkActions__Slide--appear,
  .Polaris-ResourceList-BulkActions__Slide--enter,
  .Polaris-ResourceList-BulkActions__Slide--exit{
    transform:translateX(-4em); }

  .Polaris-ResourceList-BulkActions__Slide--appearing,
  .Polaris-ResourceList-BulkActions__Slide--entering{
    transform:translateY(0); }


  .Polaris-FilterControl-DateSelector__DateTextField{
    margin-top:0.8em; }

  .Polaris-FilterControl-DateSelector__DatePicker{
    margin-top:1.6em; }


  .Polaris-ResourceList-FilterControl__AppliedFilters{
    list-style-type:none;
    padding:0;
    margin:0;
    display:flex;
    flex-wrap:nowrap;
    margin-top:-1.2em;
    margin-left:-1.2em;
    margin-right:-1.2em;
    overflow-x:auto;
    overflow-y:hidden;
    -webkit-overflow-scrolling:touch; }
    .Polaris-ResourceList-FilterControl__AppliedFilters::after{
      content:'';
      flex:0 0 1.2em; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList-FilterControl__AppliedFilters{
        flex-wrap:wrap;
        margin-right:0;
        margin-left:-0.8em;
        margin-top:-1.6em; }
        .Polaris-ResourceList-FilterControl__AppliedFilters::after{
          content:none;
          flex:none; } }
    @media (max-width: 28.625em){
      .Polaris-ResourceList-FilterControl__AppliedFilters{
        padding-bottom:0.8em; } }

  .Polaris-ResourceList-FilterControl__AppliedFilter{
    flex:1 0 auto;
    margin-top:0.8em;
    margin-left:1.2em;
    max-width:calc(100% - 0.8em); }
    @media (min-width: 28.625em){
      .Polaris-ResourceList-FilterControl__AppliedFilter{
        flex:0 1 auto;
        margin-left:0.8em; } }


  .Polaris-ResourceList__FiltersWrapper{
    padding:1.2em 1.2em 1.6em; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__FiltersWrapper{
        padding:1.6em; } }
    .Polaris-ResourceList__FiltersWrapper + .Polaris-ResourceList{
      border-top:0.1em solid #dfe3e8; }

  .Polaris-ResourceList__HeaderOuterWrapper{
    position:relative;
    background-color:var(--p-surface-foreground, white);
    z-index:2; }
    .Polaris-ResourceList__HeaderOuterWrapper + .Polaris-ResourceList{
      border-top:0.1em solid #dfe3e8; }

  .Polaris-ResourceList__HeaderWrapper--disabled{
    pointer-events:none; }

  .Polaris-ResourceList__HeaderWrapper--overlay{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:4;
    background-color:rgba(255, 255, 255, 0.5); }

  .Polaris-ResourceList__FiltersWrapper + .Polaris-ResourceList__HeaderOuterWrapper{
    margin-top:-1.6em; }

  .Polaris-ResourceList__HeaderWrapper{
    position:relative;
    display:flex;
    align-items:flex-start;
    width:100%;
    min-height:5.6em;
    padding:1em 1.2em;
    background-color:var(--p-surface-foreground, white); }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__HeaderWrapper{
        flex-direction:row;
        padding:1em 1.6em; } }

  .Polaris-ResourceList__HeaderWrapper--isSticky{
    box-shadow:0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15); }

  .Polaris-ResourceList__HeaderContentWrapper{
    position:absolute;
    z-index:1;
    right:1.2em;
    left:1.2em;
    display:flex;
    min-height:3.6em;
    opacity:1;
    transition:opacity cubic-bezier(0.64, 0, 0.35, 1) 200ms; }
    .Polaris-ResourceList__HeaderWrapper--inSelectMode .Polaris-ResourceList__HeaderContentWrapper{
      opacity:0; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__HeaderContentWrapper{
        right:1.6em;
        left:1.6em;
        align-self:flex-start; } }

  .Polaris-ResourceList__SortWrapper,
  .Polaris-ResourceList__AlternateToolWrapper{
    position:relative;
    display:flex;
    flex:1 1;
    align-items:center; }
    .Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__HeaderWrapper--hasSelect
    .Polaris-ResourceList__AlternateToolWrapper{
      padding-right:0.8em; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__SortWrapper,
      .Polaris-ResourceList__AlternateToolWrapper{
        position:relative;
        left:auto;
        flex:0 1 auto;
        margin-left:1.6em; }
        .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__SortWrapper,
        .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect
        .Polaris-ResourceList__AlternateToolWrapper,
        .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect
        .Polaris-ResourceList__AlternateToolWrapper{
          padding-right:0; } }

  .Polaris-ResourceList__HeaderTitleWrapper{
    white-space:nowrap;
    text-overflow:ellipsis;
    flex:1 1;
    padding-left:0.4em;
    align-self:center; }
    .Polaris-ResourceList__HeaderWrapper--hasAlternateTool .Polaris-ResourceList__HeaderTitleWrapper,
    .Polaris-ResourceList__HeaderWrapper--hasSort .Polaris-ResourceList__HeaderTitleWrapper{
      display:none; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__HeaderTitleWrapper,
      .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__HeaderTitleWrapper,
      .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__HeaderTitleWrapper{
        display:none; }
      .Polaris-ResourceList__HeaderWrapper--hasAlternateTool .Polaris-ResourceList__HeaderTitleWrapper,
      .Polaris-ResourceList__HeaderWrapper--hasSort .Polaris-ResourceList__HeaderTitleWrapper{
        display:block; } }

  .Polaris-ResourceList__BulkActionsWrapper{
    position:relative;
    z-index:2;
    width:100%; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__BulkActionsWrapper{
        flex:0 1 auto;
        align-self:flex-start; } }

  .Polaris-ResourceList__CheckableButtonWrapper{
    display:none; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__CheckableButtonWrapper{
        flex:1 1;
        display:block; } }

  .Polaris-ResourceList__SelectButtonWrapper{
    position:relative;
    flex:none; }
    @media (min-width: 28.625em){
      .Polaris-ResourceList__SelectButtonWrapper{
        display:none; } }

  .Polaris-ResourceList__EmptySearchResultWrapper{
    padding-top:60px;
    padding-bottom:60px; }
    @media screen and (max-height: 600px){
      .Polaris-ResourceList__EmptySearchResultWrapper{
        padding-top:32px;
        padding-bottom:32px; } }

  .Polaris-ResourceList__ResourceListWrapper{
    position:relative; }

  .Polaris-ResourceList{
    position:relative;
    z-index:1;
    margin:0;
    padding:0;
    list-style:none; }

  .Polaris-ResourceList__ItemWrapper{
    position:relative;
    z-index:1;
    overflow:hidden;
    max-width:100%; }
    .Polaris-ResourceList__ItemWrapper + .Polaris-ResourceList__ItemWrapper{
      border-top:0.1em solid #dfe3e8; }

  .Polaris-ResourceList__ItemWrapper--isLoading{
    min-height:6.4em; }

  .Polaris-ResourceList__SpinnerContainer{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:3;
    display:flex;
    justify-content:center; }

  .Polaris-ResourceList__LoadingOverlay{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:2;
    display:flex;
    justify-content:center;
    background-color:rgba(255, 255, 255, 0.5); }

  .Polaris-ResourceList__DisabledPointerEvents{
    pointer-events:none; }

  .Polaris-ResourceList--disableTextSelection{
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none; }


  .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity; }
    .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
      animation:polaris-SkeletonShimmerAnimation 800ms linear infinite alternate; }

  .Polaris-SkeletonBodyText{
    height:0.8em;
    position:relative; }
    .Polaris-SkeletonBodyText::after{
      content:'';
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      display:block;
      background-color:#dfe3e8;
      border-radius:3px; }
      @media screen and (-ms-high-contrast: active){
        .Polaris-SkeletonBodyText::after{
          background-color:grayText; } }
    .Polaris-SkeletonBodyText:last-child:not(:first-child){
      width:80%; }
    .Polaris-SkeletonBodyText + .Polaris-SkeletonBodyText{
      margin-top:1.2em; }


  .Polaris-SkeletonDisplayText__DisplayText{
    max-width:12em;
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity;
    position:relative; }
    .Polaris-SkeletonDisplayText__DisplayText{
      animation:polaris-SkeletonShimmerAnimation 800ms linear infinite alternate; }
    .Polaris-SkeletonDisplayText__DisplayText::after{
      content:'';
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      display:block;
      background-color:#dfe3e8;
      border-radius:3px; }
      @media screen and (-ms-high-contrast: active){
        .Polaris-SkeletonDisplayText__DisplayText::after{
          background-color:grayText; } }

  .Polaris-SkeletonDisplayText--sizeSmall{
    height:2.4em; }
    @media (min-width: 40em){
      .Polaris-SkeletonDisplayText--sizeSmall{
        height:2.8em; } }

  .Polaris-SkeletonDisplayText--sizeMedium{
    height:2.8em; }
    @media (min-width: 40em){
      .Polaris-SkeletonDisplayText--sizeMedium{
        height:3.2em; } }

  .Polaris-SkeletonDisplayText--sizeLarge{
    height:2.8em; }
    @media (min-width: 40em){
      .Polaris-SkeletonDisplayText--sizeLarge{
        height:3.2em; } }

  .Polaris-SkeletonDisplayText--sizeExtraLarge{
    height:3.6em; }
    @media (min-width: 40em){
      .Polaris-SkeletonDisplayText--sizeExtraLarge{
        height:4.4em; } }


  .Polaris-SkeletonPage__Page{
    margin:0 auto;
    padding:0;
    max-width:99.8em; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-SkeletonPage__Page{
        padding:0 2em; } }
    @media (min-width: 30.625em){
      .Polaris-SkeletonPage__Page{
        padding:0 2em; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-SkeletonPage__Page{
        padding:0 3.2em; } }
    @media (min-width: 46.5em){
      .Polaris-SkeletonPage__Page{
        padding:0 3.2em; } }

  .Polaris-SkeletonPage--fullWidth{
    max-width:none; }

  .Polaris-SkeletonPage--narrowWidth{
    max-width:66.2em; }

  .Polaris-SkeletonPage__Content{
    margin:2em 0; }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-SkeletonPage__Content{
        margin-top:2em; } }
    @media (min-width: 46.5em){
      .Polaris-SkeletonPage__Content{
        margin-top:2em; } }

  .Polaris-SkeletonPage__Header{
    padding:2em 2em 0;
    padding-bottom:0.8em; }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-SkeletonPage__Header{
        padding-left:0;
        padding-right:0; } }
    @media (min-width: 30.625em){
      .Polaris-SkeletonPage__Header{
        padding-left:0;
        padding-right:0; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-SkeletonPage__Header{
        padding-top:3.2em; } }
    @media (min-width: 46.5em){
      .Polaris-SkeletonPage__Header{
        padding-top:3.2em; } }

  .Polaris-SkeletonPage__Header--hasSecondaryActions{
    padding-top:2.4em;
    margin-top:0; }

  .Polaris-SkeletonPage__BreadcrumbAction{
    padding-top:1.6em;
    padding-bottom:1.6em;
    margin-top:-0.4em;
    margin-bottom:-0.4em; }

  .Polaris-SkeletonPage__TitleAndPrimaryAction{
    display:flex; }
    @media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em){
      [data-has-navigation] .Polaris-SkeletonPage__TitleAndPrimaryAction{
        display:block; } }
    @media (max-width: 46.5em){
      .Polaris-SkeletonPage__TitleAndPrimaryAction{
        display:block; } }

  .Polaris-SkeletonPage__Title{
    flex:1 1; }

  .Polaris-SkeletonPage__PrimaryAction{
    align-self:stretch; }
    .Polaris-SkeletonPage__PrimaryAction > *{
      height:3.6em;
      min-width:10em; }
    @media (max-width: 48.0625em), (min-width: 48.0625em) and (max-width: 65em){
      [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction{
        margin-top:1.6em;
        margin-bottom:-0.8em; } }
    @media (max-width: 50em){
      .Polaris-SkeletonPage__PrimaryAction{
        margin-top:1.6em;
        margin-bottom:-0.8em; } }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction{
        margin-top:0.8em;
        margin-bottom:-0.8em; } }
    @media (min-width: 30.625em){
      .Polaris-SkeletonPage__PrimaryAction{
        margin-top:0.8em;
        margin-bottom:-0.8em; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction{
        margin-top:0; } }
    @media (min-width: 46.5em){
      .Polaris-SkeletonPage__PrimaryAction{
        margin-top:0; } }
    @media (max-width: 48.0625em) and (min-width: 50em), (min-width: 65em){
      [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction{
        margin-top:0; } }
    @media (min-width: 50em){
      .Polaris-SkeletonPage__PrimaryAction{
        margin-top:0; } }

  .Polaris-SkeletonPage__Actions{
    margin-top:0.8em;
    display:flex;
    flex-direction:row-reverse;
    justify-content:flex-end;
    align-items:center; }

  .Polaris-SkeletonPage__Action{
    display:flex;
    flex-direction:column;
    justify-content:center;
    min-height:2.8em;
    padding-right:2.4em;
    margin-top:-0.4em;
    margin-bottom:-0.4em;
    padding-top:1.6em; }
    .Polaris-SkeletonPage__Action:first-child{
      padding-right:0; }
    @media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em){
      [data-has-navigation] .Polaris-SkeletonPage__Action:not(:last-child){
        display:none; } }
    @media (max-width: 46.5em){
      .Polaris-SkeletonPage__Action:not(:last-child){
        display:none; } }


  .Polaris-SkeletonThumbnail{
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:opacity;
    position:relative; }
    .Polaris-SkeletonThumbnail{
      animation:polaris-SkeletonShimmerAnimation 800ms linear infinite alternate; }
    .Polaris-SkeletonThumbnail::after{
      content:'';
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      display:block;
      background-color:#dfe3e8;
      border-radius:3px; }
      @media screen and (-ms-high-contrast: active){
        .Polaris-SkeletonThumbnail::after{
          background-color:grayText; } }

  .Polaris-SkeletonThumbnail--sizeSmall{
    height:4em;
    width:4em; }

  .Polaris-SkeletonThumbnail--sizeMedium{
    height:6em;
    width:6em; }

  .Polaris-SkeletonThumbnail--sizeLarge{
    height:8em;
    width:8em; }


  .Polaris-Tabs{
    display:flex;
    flex-wrap:wrap;
    margin:0;
    padding:0;
    border-bottom:0.1em solid #dfe3e8;
    list-style:none; }

  .Polaris-Tabs--fitted{
    flex-wrap:nowrap; }
    .Polaris-Tabs--fitted .Polaris-Tabs__TabContainer{
      flex:1 1 100%; }
    .Polaris-Tabs--fitted .Polaris-Tabs__Tab{
      padding:0; }
    .Polaris-Tabs--fitted .Polaris-Tabs__Title{
      width:100%;
      padding:0.8em 1.6em; }

  .Polaris-Tabs--fillSpace .Polaris-Tabs__TabContainer{
    flex:1 1 auto; }

  .Polaris-Tabs__TabContainer{
    display:flex;
    margin:0;
    padding:0; }

  .Polaris-Tabs__Tab{
    color:inherit;
    text-decoration:none;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    font-size:1.5em;
    font-weight:400;
    line-height:2em;
    text-transform:initial;
    letter-spacing:initial;
    color:#637381;
    position:relative;
    justify-content:center;
    width:100%;
    min-width:100%;
    margin-top:1px;
    margin-bottom:-1px;
    padding:0 1.6em;
    outline:none;
    text-align:center;
    white-space:nowrap;
    text-decoration:none;
    cursor:pointer; }
    .Polaris-Tabs__Tab:visited{
      color:inherit; }
    .Polaris-Tabs__Tab:focus{
      outline:none; }
    @media (min-width: 40em){
      .Polaris-Tabs__Tab{
        font-size:1.4em; } }
    .Polaris-Tabs__Tab:hover .Polaris-Tabs__Title{
      font-weight:400;
      color:var(--p-text-on-surface, #212b36);
      text-decoration:none;
      border-bottom:0.3em solid #dfe3e8; }
      @media (-ms-high-contrast: active){
        .Polaris-Tabs__Tab:hover .Polaris-Tabs__Title{
          border-bottom-color:highlight; } }
    .Polaris-Tabs__Tab:focus{
      box-shadow:inset 0 0 2px 0 rgba(92, 106, 196, 0.8), 0 0 2px 0 rgba(92, 106, 196, 0.8); }
      .Polaris-Tabs__Tab:focus .Polaris-Tabs__Title{
        font-weight:400;
        color:var(--p-text-on-surface, #212b36);
        border-bottom:0.3em solid #b3bcf5; }
    .Polaris-Tabs__Tab:visited{
      color:#637381; }
    .Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title{
      border-bottom:0.3em solid #5c6ac4; }
      @media (-ms-high-contrast: active){
        .Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title{
          background:highlight;
          border-bottom-color:highlight; } }

  .Polaris-Tabs__Tab--selected{
    font-weight:400;
    color:var(--p-text-on-surface, #212b36); }

  .Polaris-Tabs__Title{
    display:block;
    padding:1.6em 0 1.3em 0;
    border-bottom:0.3em solid transparent;
    min-width:5em; }

  .Polaris-Tabs__Panel{
    display:block; }
    .Polaris-Tabs__Panel:focus{
      outline:none; }

  .Polaris-Tabs__Panel--hidden{
    display:none; }

  .Polaris-Tabs__List{
    list-style:none;
    margin:0;
    padding:0.8em 0; }

  .Polaris-Tabs__Item{
    color:inherit;
    text-decoration:none;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    display:block;
    width:100%;
    min-height:1.6em;
    padding:0.8em 1.6em;
    text-align:left;
    cursor:pointer; }
    .Polaris-Tabs__Item:visited{
      color:inherit; }
    .Polaris-Tabs__Item:focus{
      outline:none; }
    .Polaris-Tabs__Item:active{
      background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)); }
      .Polaris-Tabs__Item:active:hover{
        background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
        .Polaris-Tabs__Item:active:hover:focus{
          box-shadow:inset 0.2em 0 0 #5c6ac4;
          background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-Tabs__Item:hover{
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
      .Polaris-Tabs__Item:hover:focus{
        box-shadow:inset 0.2em 0 0 #5c6ac4;
        background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-Tabs__Item:focus{
      box-shadow:inset 0.2em 0 0 #5c6ac4;
      background-image:linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3)); }
    .Polaris-Tabs__Item::-moz-focus-inner{
      border:none; }

  .Polaris-Tabs__DisclosureTab{
    display:none; }

  .Polaris-Tabs__DisclosureTab--visible{
    display:flex; }

  .Polaris-Tabs__DisclosureActivator{
    position:relative;
    display:block;
    justify-content:center;
    height:100%;
    margin:1px 1px -1px 0;
    padding:0 1.6em;
    background-color:transparent;
    cursor:pointer;
    border:none;
    outline:none;
    text-align:center; }
    .Polaris-Tabs__DisclosureActivator svg{
      fill:#637381; }
    .Polaris-Tabs__DisclosureActivator:hover .Polaris-Tabs__Title{
      border-bottom:0.3em solid #dfe3e8; }
      .Polaris-Tabs__DisclosureActivator:hover .Polaris-Tabs__Title svg{
        fill:#212b36; }
      @media (-ms-high-contrast: active){
        .Polaris-Tabs__DisclosureActivator:hover .Polaris-Tabs__Title{
          background-image:linear-gradient(rgba(179, 188, 245, 0.1), rgba(179, 188, 245, 0.1)), linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3));
          border-bottom-color:highlight; }
          .Polaris-Tabs__DisclosureActivator:hover .Polaris-Tabs__Title svg{
            fill:black; } }
    .Polaris-Tabs__DisclosureActivator:focus{
      box-shadow:inset 0 0 2px 0 rgba(92, 106, 196, 0.8), 0 0 2px 0 rgba(92, 106, 196, 0.8); }
      .Polaris-Tabs__DisclosureActivator:focus .Polaris-Tabs__Title{
        border-bottom:0.3em solid #b3bcf5; }

  .Polaris-Tabs__TabMeasurer{
    display:flex;
    visibility:hidden;
    height:0; }


  .Polaris-Thumbnail{
    position:relative;
    display:block;
    overflow:hidden;
    background:white;
    min-width:4em;
    max-width:100%;
    border-radius:3px; }
    .Polaris-Thumbnail::after{
      content:'';
      position:relative;
      z-index:20;
      display:block;
      padding-bottom:100%;
      box-shadow:inset 0 0 0 1px rgba(33, 43, 54, 0.1);
      border-radius:3px; }

  .Polaris-Thumbnail--sizeSmall{
    width:4em; }

  .Polaris-Thumbnail--sizeMedium{
    width:6em; }

  .Polaris-Thumbnail--sizeLarge{
    width:8em; }

  .Polaris-Thumbnail__Image{
    position:absolute;
    z-index:10;
    top:0;
    right:0;
    bottom:0;
    left:0;
    margin:auto;
    max-width:100%;
    max-height:100%; }


  .Polaris-TopBar-Search{
    position:absolute;
    z-index:20;
    left:0;
    right:0;
    top:5.6em;
    height:calc(100vh - 5.6em);
    visibility:hidden;
    pointer-events:none;
    padding:0;
    width:100%; }
    @media (min-width: 42.5em){
      .Polaris-TopBar-Search{
        top:4em;
        left:auto;
        right:auto; } }

  .Polaris-TopBar-Search--visible{
    visibility:initial;
    pointer-events:all; }

  .Polaris-TopBar-Search__Overlay{
    position:absolute;
    left:0;
    right:0;
    display:flex;
    flex-direction:column;
    align-items:stretch;
    width:100%;
    max-width:69.4em;
    min-width:45em;
    max-height:100%; }
    .Polaris-TopBar-Search__Overlay > *{
      flex:1 1 auto;
      width:100%; }
    @media (min-width: 42.5em){
      .Polaris-TopBar-Search__Overlay{
        position:relative; } }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-TopBar-Search__Overlay{
        max-height:60vh; } }
    @media (min-width: 30.625em){
      .Polaris-TopBar-Search__Overlay{
        max-height:60vh; } }
    @media (max-width: 30.625em), (min-width: 48.0625em) and (max-width: 47.125em){
      [data-has-navigation] .Polaris-TopBar-Search__Overlay{
        min-width:100%; } }
    @media (max-width: 30.625em){
      .Polaris-TopBar-Search__Overlay{
        min-width:100%; } }


  .Polaris-TopBar-SearchField{
    position:relative;
    display:flex;
    flex:1 1 auto;
    align-items:center;
    border:1px solid transparent;
    width:100%;
    max-width:69.4em; }

  .Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input,
  .Polaris-TopBar-SearchField__Input:focus{
    border:none;
    color:var(--p-text-on-surface, #212b36); }
    .Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input::-webkit-input-placeholder,
    .Polaris-TopBar-SearchField__Input:focus::-webkit-input-placeholder{
      color:var(--p-text-subdued-on-surface, #919eab); }
    .Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input::-ms-input-placeholder,
    .Polaris-TopBar-SearchField__Input:focus::-ms-input-placeholder{
      color:var(--p-text-subdued-on-surface, #919eab); }
    .Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input::placeholder,
    .Polaris-TopBar-SearchField__Input:focus::placeholder{
      color:var(--p-text-subdued-on-surface, #919eab); }

  .Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Backdrop{
    background-color:var(--p-surface-foreground, white); }

  .Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Icon svg{
    fill:var(--p-icon-subdued-on-surface, #919eab); }

  .Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Backdrop{
    background-color:var(--p-surface-foreground, white); }

  .Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Icon svg{
    fill:var(--p-icon-subdued-on-surface, #919eab); }

  .Polaris-TopBar-SearchField__Input{
    font-size:1.6em;
    font-weight:400;
    line-height:2.4em;
    text-transform:initial;
    letter-spacing:initial;
    z-index:2;
    width:calc(100% - 3.6em);
    height:3.4em;
    padding:0 0 0 3.6em;
    border:none;
    background-color:transparent;
    outline:none;
    color:var(--p-text-on-surface, var(--top-bar-color));
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:fill, color;
    transition:fill 200ms cubic-bezier(0.64, 0, 0.35, 1), color 200ms cubic-bezier(0.64, 0, 0.35, 1);
    -webkit-appearance:textfield;
       -moz-appearance:textfield;
            appearance:textfield; }
    @media (min-width: 40em){
      .Polaris-TopBar-SearchField__Input{
        font-size:1.4em; } }
    .Polaris-TopBar-SearchField__Input::-webkit-input-placeholder{
      color:var(--p-text-on-surface, var(--top-bar-color)); }
    .Polaris-TopBar-SearchField__Input::-ms-input-placeholder{
      color:var(--p-text-on-surface, var(--top-bar-color)); }
    .Polaris-TopBar-SearchField__Input::placeholder{
      color:var(--p-text-on-surface, var(--top-bar-color)); }
    .Polaris-TopBar-SearchField__Input::-webkit-search-decoration, .Polaris-TopBar-SearchField__Input::-webkit-search-cancel-button{
      -webkit-appearance:none;
              appearance:none; }

  .Polaris-TopBar-SearchField__Icon{
    position:absolute;
    z-index:3;
    top:50%;
    left:0.8em;
    display:flex;
    height:2em;
    pointer-events:none;
    transform:translateY(-50%); }
    .Polaris-TopBar-SearchField__Icon svg{
      fill:var(--p-icon-on-surface, var(--top-bar-color)); }

  .Polaris-TopBar-SearchField__Clear{
    position:relative;
    z-index:3;
    align-self:stretch;
    border:none;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    background:transparent;
    width:3.6em; }
    .Polaris-TopBar-SearchField__Clear svg{
      fill:var(--p-neutral-action, #919eab); }
    .Polaris-TopBar-SearchField__Clear:focus, .Polaris-TopBar-SearchField__Clear:hover{
      outline:none; }
      .Polaris-TopBar-SearchField__Clear:focus svg, .Polaris-TopBar-SearchField__Clear:hover svg{
        fill:var(--p-neutral-action-hovered, #637381); }

  .Polaris-TopBar-SearchField__Backdrop{
    position:absolute;
    z-index:1;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:var(--p-neutral-action, var(--top-bar-background-lighter));
    -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
    will-change:background-color;
    transition:background-color 200ms cubic-bezier(0.64, 0, 0.35, 1);
    border-radius:3px;
    animation:Polaris-TopBar-SearchField--toLightBackground 0.01ms; }

  @keyframes Polaris-TopBar-SearchField--toLightBackground{
    to{
      background-color:var(--p-neutral-action, var(--top-bar-background-lighter)); } }


  .Polaris-MessageIndicator__MessageIndicatorWrapper{
    position:relative; }

  .Polaris-MessageIndicator{
    position:absolute;
    z-index:1;
    top:0;
    right:0;
    width:0.8em;
    height:0.8em;
    border-radius:100%;
    background-color:#47c1bf; }


  .Polaris-Menu-Message__Section{
    max-width:32.5em;
    margin-top:0.8em;
    padding-top:0.8em;
    border-top:0.1em solid #dfe3e8; }


  .Polaris-TopBar-Menu__ActivatorWrapper{
    height:5.6em;
    display:flex;
    align-items:center;
    margin-right:0.8em; }
    @media (max-width: 48.0525em){
      .Polaris-TopBar-Menu__ActivatorWrapper{
        margin:0 0.4em; } }

  .Polaris-TopBar-Menu__Activator{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    color:var(--p-text-on-surface, var(--top-bar-color));
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
    min-width:3.6em;
    padding:0.6em 0.8em;
    border:0;
    cursor:pointer;
    transition:background-color 0.1s;
    border-radius:3px; }
    .Polaris-TopBar-Menu__Activator:focus{
      outline:none; }
    .Polaris-TopBar-Menu__Activator:focus{
      background-color:rgba(255, 255, 255, 0.16);
      outline:none; }
    .Polaris-TopBar-Menu__Activator:hover{
      background-color:rgba(255, 255, 255, 0.08); }
    .Polaris-TopBar-Menu__Activator:active, .Polaris-TopBar-Menu__Activator[aria-expanded='true']{
      background-color:rgba(0, 0, 0, 0.28);
      outline:none;
      transition:none; }
    @media (max-width: 48.0525em){
      .Polaris-TopBar-Menu__Activator:focus, .Polaris-TopBar-Menu__Activator:hover, .Polaris-TopBar-Menu__Activator:active, .Polaris-TopBar-Menu__Activator[aria-expanded='true']{
        background-color:transparent;
        opacity:0.85; } }

  .Polaris-TopBar-Menu__Section{
    margin-top:0.8em;
    padding-top:0.8em;
    border-top:0.1em solid #dfe3e8; }


  .Polaris-TopBar-UserMenu__Details{
    max-width:16em;
    margin-left:0.8em; }
    @media (max-width: 48.0525em){
      .Polaris-TopBar-UserMenu__Details{
        display:none; } }

  .Polaris-TopBar-UserMenu__Name{
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    font-size:1.5em;
    font-weight:400;
    line-height:2em;
    text-transform:initial;
    letter-spacing:initial;
    font-weight:500;
    line-height:1.6em;
    color:var(--p-text-on-surface, var(--top-bar-color));
    text-align:left; }
    @media (min-width: 40em){
      .Polaris-TopBar-UserMenu__Name{
        font-size:1.4em; } }

  .Polaris-TopBar-UserMenu__Detail{
    font-size:1.3em;
    font-weight:400;
    line-height:2em;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    opacity:0.7;
    color:var(--p-text-on-surface, var(--top-bar-color));
    text-align:left; }
    @media (min-width: 40em){
      .Polaris-TopBar-UserMenu__Detail{
        font-size:1.2em;
        line-height:1.6em; } }


  .Polaris-TopBar{
    position:relative;
    display:flex;
    height:5.6em;
    background-color:var(--p-surface-background, var(--top-bar-background)); }
    .Polaris-TopBar::after{
      content:'';
      position:absolute;
      bottom:0;
      width:100%;
      border-bottom:1px solid transparent; }

  .Polaris-TopBar__LogoContainer{
    display:none; }
    @media (min-width: 48.0625em){
      .Polaris-TopBar__LogoContainer{
        display:flex;
        flex:0 0 24em;
        align-items:center;
        height:100%;
        padding:0 0.8em 0 1.6em;
        flex-basis:24em;
        flex-basis:calc(24em + constant(safe-area-inset-left));
        flex-basis:calc(24em + env(safe-area-inset-left));
        padding-left:1.6em;
        padding-left:calc(1.6em + constant(safe-area-inset-left));
        padding-left:calc(1.6em + env(safe-area-inset-left)); } }

  .Polaris-TopBar__ContextControl{
    display:none; }
    @media (min-width: 48.0625em){
      .Polaris-TopBar__ContextControl{
        width:24em;
        display:block; } }
    @media (min-width: 87.5em){
      .Polaris-TopBar__ContextControl{
        width:28em; } }

  .Polaris-TopBar__Logo,
  .Polaris-TopBar__LogoLink{
    display:block; }

  .Polaris-TopBar__NavigationIcon{
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
    margin:0;
    padding:0;
    background:none;
    border:none;
    font-size:inherit;
    line-height:inherit;
    cursor:pointer;
    position:relative;
    align-self:center;
    margin-left:1em;
    margin-right:0.8em;
    padding:0.8em;
    border-radius:3px; }
    .Polaris-TopBar__NavigationIcon:focus{
      outline:none; }
    .Polaris-TopBar__NavigationIcon.Polaris-TopBar--focused{
      background-color:rgba(255, 255, 255, 0.16); }
    .Polaris-TopBar__NavigationIcon.Polaris-TopBar--focused:active{
      background-color:rgba(0, 0, 0, 0.28); }
    .Polaris-TopBar__NavigationIcon:hover{
      background-color:rgba(255, 255, 255, 0.08); }
    .Polaris-TopBar__NavigationIcon::after{
      content:'';
      position:absolute;
      top:-0.8em;
      left:-0.8em;
      width:calc(100% + 2em);
      height:calc(100% + 2em); }
    @media (min-width: 48.0625em){
      .Polaris-TopBar__NavigationIcon{
        display:none; } }

  .Polaris-TopBar__Contents{
    z-index:10;
    display:flex;
    flex:1 1 auto;
    align-items:center;
    justify-content:flex-end;
    height:100%; }
    @media (min-width: 42.5em){
      .Polaris-TopBar__Contents{
        position:relative; } }

  .Polaris-TopBar__SearchField{
    margin:0 auto;
    padding:0;
    max-width:99.8em;
    width:100%;
    margin:0;
    max-width:none;
    margin-left:calc((100% - 99.8em)/2); }
    @media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em){
      [data-has-navigation] .Polaris-TopBar__SearchField{
        padding:0 2em; } }
    @media (min-width: 30.625em){
      .Polaris-TopBar__SearchField{
        padding:0 2em; } }
    @media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em){
      [data-has-navigation] .Polaris-TopBar__SearchField{
        padding:0 3.2em; } }
    @media (min-width: 46.5em){
      .Polaris-TopBar__SearchField{
        padding:0 3.2em; } }
    @media (min-width: 87.5em){
      .Polaris-TopBar__SearchField{
        margin-left:calc((100% - 103.8em)/2); } }
    @media (max-width: 1268px){
      .Polaris-TopBar__SearchField{
        margin-left:0; } }
    @media (min-width: 42.5em){
      .Polaris-TopBar__SearchField{
        position:relative; } }

  @media (max-width: 48.0525em){
    .Polaris-TopBar__SecondaryMenu{
      display:none; } }
}
